//@ts-check
import AppLoading from 'expo-app-loading';
import { Asset } from 'expo-asset';
import Constants from 'expo-constants';
import * as SplashScreen from 'expo-splash-screen';
// import * as Updates from 'expo-updates';
import React, { useEffect, useRef, useState } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import * as songActions from '../redux_store/actions/songActions';
import * as appActions from '../redux_store/actions/appActions';

import {
  Animated,
  Button,
  Platform,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import * as Font from 'expo-font';
import * as myUtils from '../utils/myUtils';

import Colors from '../constants/colors';

// Instruct SplashScreen not to hide yet, we want to do this manually
SplashScreen.preventAutoHideAsync().catch(() => {
  /* reloading the app might trigger some race conditions, ignore them */
});

let AnimatedAppLoader;
export default AnimatedAppLoader = ({
  children,
  image,
  onSetInitialScreen,
}) => {
  const [isInitialized, setIsInitialized] = useState(false);

  const dispatch = useDispatch();

  // const onFinish = React.useMemo(() => setSplashReady(true), []);
  const onFinish = async () => {
    // await myUtils.delay(1);
    await myUtils.delay(!__DEV__ ? 0 : 10);

    // await getUrlParameters(dispatch).then((defaultParams) => {
    //   console.log('getUrlParameters defaultParams', defaultParams);
    //   if (defaultParams?.eventCode?.length > 0) onSetInitialScreen('Username');
    //   else onSetInitialScreen('EventCode');
    // });

    // console.log('onFinish 2');
    console.log('ENDED LOADING APP!!!');
    setIsInitialized(true);
  };

  console.log('AnimatedAppLoader isInitialized', isInitialized);

  // if (!isInitialized) {
  //   return (
  //     <AppLoading
  //       // Instruct SplashScreen not to hide yet, we want to do this manually
  //       autoHideSplash={false}
  //       startAsync={initializeApp}
  //       onError={console.error}
  //       onFinish={onFinish}
  //     />
  //   );
  // }

  return <AnimatedSplashScreen image={image}>{children}</AnimatedSplashScreen>;
};

function AnimatedSplashScreen({ children, image }) {
  console.log('AnimatedSplashScreen');

  const [isAppReady, setAppReady] = React.useState(false);
  const [isSplashAnimationComplete, setAnimationComplete] =
    React.useState(false);

  const animation = React.useMemo(() => new Animated.Value(1), []);
  React.useEffect(() => {
    if (isAppReady) {
      Animated.timing(animation, {
        toValue: 0,
        duration: 1500,
        useNativeDriver:
          Platform.OS === 'ios' || Platform.OS === 'web' ? false : true,
      }).start(() => setAnimationComplete(true));
    }
  }, [isAppReady]);

  //@ts-ignore
  const onImageLoaded = React.useMemo(async () => {
    try {
      await SplashScreen.hideAsync();
      // Load stuff
      await Promise.all([]);
    } catch (e) {
      // handle errors
    } finally {
      setAppReady(true);
    }
  });

  return (
    <View style={{ flex: 1 }}>
      {isAppReady && children}
      {!isSplashAnimationComplete && (
        <Animated.View
          pointerEvents='none'
          style={[
            StyleSheet.absoluteFill,
            {
              // backgroundColor: Constants.manifest.splash.backgroundColor,
              backgroundColor: Colors.defBackgroundColor,
              opacity: animation,
            },
          ]}
        >
          <Animated.Image
            style={{
              width: '100%',
              height: '100%',
              resizeMode: Constants.manifest.splash.resizeMode || 'contain',
              transform: [
                {
                  scale: animation,
                },
              ],
            }}
            source={image}
            // onLoadEnd={onImageLoaded}
            fadeDuration={0}
          />
        </Animated.View>
      )}
    </View>
  );
}
