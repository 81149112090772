// @ts-check
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  SafeAreaView,
  Text,
  View,
  Image,
  StyleSheet,
  InteractionManager,
  ActivityIndicator,
  FlatList,
  TouchableOpacity,
  TouchableHighlight,
  Dimensions,
  Platform,
  KeyboardAvoidingView,
} from 'react-native';
import {
  Ionicons,
  Foundation,
  MaterialIcons,
  MaterialCommunityIcons,
} from '@expo/vector-icons';
// import { Button, TextInput, useTheme } from 'react-native-paper';
import Constants from 'expo-constants';
// import * as Device from 'expo-device';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { useForm, Controller } from 'react-hook-form';

import Screen from '../components/Screen';
import AppInputBox from '../components/AppInputBox';
import AppText from '../components/AppText';
import AppButton from '../components/AppButton';

import * as myUtils from '../utils/myUtils';
import defaultStyles from '../config/styles';
import { LinearGradient } from 'expo-linear-gradient';
import { Asset as ExpoAsset } from 'expo-asset';
// import { readableRandomString } from '../components/utils/readableRandomString';
// import Swiper from '../components/UI/ViewSwiper';

// import styles from '../styles/inputCode';
import { globalStyles } from '../styles/global';
import colors from '../constants/colors';
import Colors from '../config/colors';

import * as songActions from '../redux_store/actions/songActions';
import * as appActions from '../redux_store/actions/appActions';

const MAX_NAME_CHANGED_COUNT = 3;
// import * as myModule from '../utils/myModule';

const UsernameScreen = (props) => {
  // const { colors } = useTheme();
  console.log('UsernameScreen props', props);
  const defaultParams = props.route.params;

  const dispatch = useDispatch();
  const eventInfo = useRef(null);
  // const [userName, setUserName] = useState('');
  const historyState = useSelector((state) => {
    console.log('state::', state);
    //@ts-ignore
    return state?.appReducer?.history;
  });
  const localAppSettingsState = useSelector((state) => {
    //@ts-ignore
    return state?.appReducer?.appSettings;
  });
  const nameChangedCountState = useSelector((state) => {
    //@ts-ignore
    return state?.appReducer?.nameChangedCount;
  });

  const [localAppSettings, setLocalAppSettings] = useState(
    localAppSettingsState
  );

  const [history, setHistory] = useState(historyState);

  const [isNameAlreadyTaken, setIsNameAlreadyTaken] = useState(false);
  const [isMaxNameChangedCountReached, setIsMaxNameChangedCountReached] =
    useState(nameChangedCountState >= MAX_NAME_CHANGED_COUNT);

  useEffect(() => {
    console.log('historyState', historyState);
    setHistory(historyState);
  }, [historyState]);

  useEffect(() => {
    //This is related to SplashScreen --> //Get event info from AWS if user name exists
    if (!eventInfo.current) {
      dispatch(
        appActions.getEventInfo(
          defaultParams.eventCode.toUpperCase(),
          defaultParams.eventName
        )
      ).then((event) => {
        console.log('EventCodeScreen event', event);
        eventInfo.current = event;
        if (event?.eventCode?.length > 0) {
          console.log('event', event);
          myUtils.configureDataStore(event).then(() => {
            // console.log('setSongsForEvent+ 4');
            // dispatch(songActions.setSongsForEvent(true, event?.id));
          });
        } else {
          console.log('getEventInfo IS NULL');
          return;
        }
      });
    }

    console.log('useEffect []', localAppSettings?.userName);
    setValue('userName', localAppSettings?.userName);
    // getEventInfoFromAWS();
  }, []);

  useEffect(() => {
    console.log(
      'localAppSettingsState',
      localAppSettingsState.nameChangedCount,
      MAX_NAME_CHANGED_COUNT,
      localAppSettingsState.nameChangedCount >= MAX_NAME_CHANGED_COUNT
    );
    setLocalAppSettings(localAppSettings);
  }, [localAppSettingsState]);

  useEffect(() => {
    console.log(
      'useEffect [props]',
      props.route.params,
      localAppSettingsState?.userName
    );
    eventInfo.current = props.route.params?.currentEvent;
    setIsNameAlreadyTaken(props.route.params?.isNameAlreadyTaken);
  }, [props]);

  useEffect(() => {
    console.log(
      'useEffect [localAppSettingsState]',
      localAppSettingsState?.userName
    );
    if (nameChangedCountState >= MAX_NAME_CHANGED_COUNT) {
      setValue('userName', localAppSettingsState?.userName);
    }
  }, [localAppSettingsState]);

  useEffect(() => {
    setIsMaxNameChangedCountReached(
      nameChangedCountState >= MAX_NAME_CHANGED_COUNT
    );

    if (nameChangedCountState >= MAX_NAME_CHANGED_COUNT) {
      setValue('userName', nameChangedCountState?.userName);
    }
  }, [nameChangedCountState]);

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      userName: '', //localAppSettings?.userName,
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    control,
    formState, //: { isDirty, isValid, isSubmitting, errors, submitCount },
  } = form;

  // useEffect(() => {
  //   console.log(
  //     'localAppSettingsState?.windowSize---->>>2',
  //     localAppSettingsState?.windowSize,
  //     global.windowSize.height
  //   );

  // }, [localAppSettingsState?.windowSize]);

  const onSubmit = (data) => {
    InteractionManager.runAfterInteractions(async () => {
      // dispatch(appActions.saveSettings({ userName: data.userName }));
      dispatch(appActions.saveCurrentGuest({ userName: data.userName })).then(
        (isSuccessful) => {
          if (isSuccessful) {
            dispatch(songActions.updateQueueAndSetUserFavSongs());
            setIsNameAlreadyTaken(false);
          } else {
            props.navigation.navigate({
              name: 'Username',
              params: {
                currentEvent: localAppSettings?.currentEvent,
                eventCode: localAppSettings?.currentEvent?.eventCode,
                companyCode: localAppSettings?.currentEvent?.companyCode,
                eventName: localAppSettings?.currentEvent?.eventName,
                apiVersion: localAppSettings?.currentEvent?.apiVersion,
                isNameAlreadyTaken: true,
              },
              merge: true,
            });
            return;
          }
        }
      );

      // if (
      //   props.route.params.defaultScreen &&
      //   props.route.params.defaultScreen === 'Main'
      // ) {
      //   props.navigation.navigate({
      //     name: 'Main',
      //     params: { userName: userName },
      //     merge: true,
      //   });
      // } else {
      if (eventInfo.current)
        //localAppSettingsState?.currentEvent.id)
        props.navigation.push('Main', {
          ...props.route.params,
          userName: data.userName?.length > 0 ? data.userName : '',
        });
      else
        props.navigation.push('EventCode', {
          ...props.route.params,
          userName: data.userName?.length > 0 ? data.userName : '',
        });
      // }
    });
  };

  return (
    <KeyboardAvoidingView
      keyboardVerticalOffset={120}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={styles.container}
    >
      <Screen>
        {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss}> */}
        <View style={styles.inner}>
          <View
            style={{
              flex: 1,
              width: '100%',
              justifyContent: localAppSettings?.currentEvent?.imageUrl
                ? 'flex-end'
                : 'center',
              // paddingHorizontal: 15,
              // backgroundColor: 'red',
              paddingBottom: localAppSettings?.currentEvent?.imageUrl ? 50 : 0,
            }}
          >
            <Image
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                zIndex: -999,
              }}
              resizeMode={
                localAppSettings?.currentEvent?.imageUrl ? 'cover' : 'stretch'
              }
              source={
                // image ? { uri: image } : require('../assets/defEventImage4.jpg')
                {
                  uri: localAppSettings?.currentEvent?.imageUrl
                    ? 'https://files.songrequest.party/public/' +
                      localAppSettings?.currentEvent?.imageUrl
                    : null,
                }
              }
            />
            <LinearGradient
              locations={[0, 1]}
              // colors={['transparent', colors.OxfordBlue]}
              colors={['transparent', Colors.defBackgroundColor]}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: -10,
                // height: '100%',
                zIndex: -999,
              }}
            />
            {defaultParams?.eventName ||
            localAppSettings?.currentEvent?.eventName ? (
              <Text selectable={false} style={globalStyles.headlineText}>
                {'Welcome to\n' +
                  (localAppSettings?.currentEvent?.eventName
                    ? localAppSettings?.currentEvent?.eventName
                    : defaultParams?.eventName) +
                  '!'}
              </Text>
            ) : (
              <Text selectable={false} style={globalStyles.headlineText}>
                {'Welcome!'}
              </Text>
            )}
          </View>

          <View
            style={[
              defaultStyles.controlContainer,
              {
                flex: 1,
                justifyContent: 'center',
                // backgroundColor: 'yellow',
              },
            ]}
          >
            <AppText controlStyle='title'>What's your nickname?</AppText>

            <AppInputBox
              name='userName'
              errors={formState.errors}
              placeholder='Enter your name here.'
              form={form}
              controlType='inputText'
              required={true}
              errMessage='Required'
              disabled={isMaxNameChangedCountReached ? true : false}
              editable={isMaxNameChangedCountReached ? false : true}
              selectTextOnFocus={isMaxNameChangedCountReached ? false : true}
              autoFocus={isMaxNameChangedCountReached ? false : true}
              iconLeft={'balloon'}
              iconRight={
                formState.errors?.userName?.type === 'required' ? null : 'close'
              }
              onSubmitEditing={handleSubmit(onSubmit)}
              onRightIconClicked={() => {
                console.log('onRightIconClicked');
                reset();
              }}
              returnKeyType='done'
            />

            {/* <AppInputBox
              name='userName'
              errors={formState.errors}
              placeholder='Enter your name here.'
              form={form}
              controlType='inputText'
              required={true}
              errMessage='Required'
              iconRight={'close-circle'}
              iconLeft={'balloon'}
              // onSubmitEditing={() => onSearchSubmit()}
              onRightIconClicked={() => {
                console.log('onRightIconClicked');
                reset();
              }}
            /> */}
            {/* <AppText>e.g. 'My Birthday', 'BBQ Party', 'Homecoming'</AppText> */}
            {console.log(
              'isMaxNameChangedCountReached',
              isMaxNameChangedCountReached
            )}
            <View style={{ height: 60 }}>
              {isMaxNameChangedCountReached ? (
                <Text selectable={false} style={globalStyles.errorText}>
                  You have reached the maximum limit for changing your name. You
                  may ask the host to change your name.
                </Text>
              ) : (
                isNameAlreadyTaken && (
                  <Text selectable={false} style={globalStyles.errorText}>
                    This name is already taken. Please choose another name.
                  </Text>
                )
              )}
            </View>
          </View>

          <View
            style={[
              defaultStyles.controlContainer,
              { flex: 1, justifyContent: 'flex-end' },
            ]}
          >
            <AppButton
              // disabled={!currentUser}
              title='Continue'
              onPress={handleSubmit(onSubmit)}
            />
          </View>
        </View>

        {/* </TouchableWithoutFeedback> */}
      </Screen>
    </KeyboardAvoidingView>
  );

  return (
    <SafeAreaView
      style={[
        {
          position: 'absolute',
          height: '100%',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: colors.defBackgroundColor,
        },
      ]}
    >
      <View
        style={{
          flex: 1,
          width: '100%',
          justifyContent: 'center',
          padding: 15,
        }}
      >
        <Text selectable={false}>
          {'Welcome to\n' + props.route.params.eventName + '!'}
        </Text>
      </View>

      <View
        style={{
          // marginLeft: 40,
          flex: 1,
          width: '100%',
          padding: 15,
          // backgroundColor: 'red',
        }}
      >
        <View
          style={{
            // marginLeft: 40,
            width: '100%',
            // backgroundColor: 'red',
          }}
        >
          <Text selectable={false} style={globalStyles.titleText}>
            What's your nickname?
          </Text>
        </View>
        <View
          style={{
            top: 0,
            width: '100%',
            paddingBottom: 10,
          }}
        >
          {/* <TextInput
            mode='outlined'
            autoCapitalize={'words'}
            // disabled={true}
            placeholder='Type your name here.'
            left={<TextInput.Icon name='face-profile' size={30} />}
            // autoFocus={true}
            value={userName}
            onChangeText={(text) => {
              // setSearchBtnDisabled(false);
              setUserName(text);
            }}
            onSubmitEditing={onNextBtnClicked}
          /> */}
        </View>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    // backgroundColor: 'red',
    // backgroundColor: colors.background,
  },
  inner: {
    // padding: 24,
    flex: 1,
    // justifyContent: 'space-around',
    justifyContent: 'flex-end',
  },
  header: {
    fontSize: 36,
    marginBottom: 48,
  },
  textInput: {
    height: 40,
    borderColor: '#000000',
    borderBottomWidth: 1,
    marginBottom: 36,
  },
  btnContainer: {
    backgroundColor: 'white',
    marginTop: 12,
  },
});

export const screenOptions = (navData) => {
  return {
    headerShown: false,
    headerTitle: 'Nickname',
  };
};

export default UsernameScreen;
