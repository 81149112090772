import React from 'react';
import { View, Text, TextInput, Switch, StyleSheet } from 'react-native';
// import { TextInput } from 'react-native-paper';
import { useForm, Controller } from 'react-hook-form';
import defaultStyles from '../config/styles';
// import { color, t } from 'react-native-tailwindcss';
import Colors from '../constants/colors';
import { Picker } from '@react-native-picker/picker';
import AppTextInput from '../components/AppTextInput';
import colors from '../config/colors';

export default function AppInputBox(props) {
  // console.log('AppInputBox props', props);
  const control = props.form?.control;
  const errors = props.errors;
  // const { handleSubmit, control, errors } = useForm();
  const inputRef = props.inputRef ? props.inputRef : React.useRef();
  // console.log('errors-errors++', errors);
  // console.log('errors[props.name]', errors[props.name]);

  if (!control)
    return (
      <AppTextInput
        {...props}
        // mode='outlined'
        // value={value}
        // onChangeText={onChange}
      />
    );

  let pickerItems = <></>;

  if (props.picker_items)
    pickerItems = props.picker_items.map((item, index) => {
      return <Picker.Item key={index} value={item.value} label={item.label} />;
    });

  // console.log('props.onSubmit', props.onSubmitHandle);
  return (
    <View style={styles.container}>
      {/* <Controller
        name='searchValue'
        control={control}
        defaultValue=''
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            {...props}
            autoCapitalize='none'
            autoCompleteType='password'
            autoCorrect={false}
            onBlur={onBlur}
            onChangeText={onChange}
            onSubmitEditing={props.onSubmitHandle}
            secureTextEntry
            style={styles.textInput}
            textContentType='password'
            value={value}
            returnKeyType='search'
          />
        )}
      /> */}
      <Controller
        name={props.name}
        control={control}
        rules={{
          required: props.required,
          pattern: props.pattern ? props.pattern : null,
          maxLength: props.maxLength
            ? {
                value: props.maxLength,
                message: 'This input exceeds maximum character limit.',
              }
            : null,
        }}
        defaultValue=''
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <View style={{ width: '100%', flexGrow: 1 }}>
            {props.controlType === 'inputText' && (
              <AppTextInput
                {...props}
                // ref={ref}
                inputRef={inputRef}
                onBlur={onBlur}
                mode='outlined'
                value={value}
                onChangeText={onChange}
                // onSubmitEditing={props.onSubmitHandle}
              />
            )}
            {props.is_picker === 'true' && (
              <Picker
                {...props}
                // ref={ref}
                style={styles.pickerField}
                selectedValue={value}
                onValueChange={onChange}
              >
                {pickerItems}
              </Picker>
            )}
            {props.isSwitch && (
              <Switch
                {...props}
                // trackColor={{ false: Colors.lavenderWeb, true: Colors.main }}
                activeThumbColor={Colors.main}
                activeTrackColor={Colors.lavenderWeb}
                thumbColor={'#fafafa'}
                // ref={ref}
                ios_backgroundColor={'#939393'}
                value={value}
                onValueChange={onChange}
              />
            )}

            {errors[props.name] && (
              <View
                style={{
                  alignSelf: 'flex-end',
                  alignItems: 'flex-end',
                  justifyContent: 'center',
                  position: 'absolute',
                  // alignContent: 'center',
                  // backgroundColor: 'yellow',
                  padding: 16,
                  flexGrow: 1,
                }}
              >
                <Text
                  style={[
                    defaultStyles.errorText,
                    {
                      zIndex: 9999,
                    },
                  ]}
                >
                  {props.errMessage}
                </Text>
              </View>
            )}
          </View>
        )}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    // height: 200,
    marginVertical: 8,
    // marginHorizontal: 16,
    // justifyContent: 'center',
    alignItems: 'center',
    // flex: 1,
    // backgroundColor: 'blue',
  },
  pickerField: {
    marginTop: 10,
    // marginBottom: 20,
    width: '100%',
    fontSize: 16,
    height: 56,
    borderWidth: 1,
    backgroundColor: 'rgb(246, 246, 246)',
    padding: 10,
    borderColor: 'rgba(0, 0, 0, 0.54)',
    borderRadius: 5,
  },
});

// const styles = {

// wrapper: [t.selfStretch, t.mB5],
// input: [
//   t.h11,
//   t.border,
//   t.selfStretch,
//   t.p2,
//   t.borderGray500,
//   t.rounded,
//   t.textBase,
//   t.textGray700,
// ],
// errorText: [t.mT1, t.textRed500, t.absolute, t._bottom40],
// };
