import React from 'react';
import {
  TouchableOpacity,
  Text,
  StyleSheet,
  View,
  ActivityIndicator,
} from 'react-native';
import colors from '../config/colors';
import AppText from './AppText';
let AppButton;
export default AppButton = ({
  title,
  color = 'main',
  text = 'background',
  onPress,
  width = '100%',
  children = null,
  customStyle = null,
  textStyle = null,
  ...otherProps
}) => {
  // console.log('otherProps', otherProps);
  return (
    <TouchableOpacity
      disabled={otherProps.disabled}
      onPress={onPress}
      style={[
        styles.container,
        {
          backgroundColor: otherProps.disabled
            ? colors.disabledButtonColor
            : colors[color],
          width: width,
          marginBottom: 10,
        },
        customStyle,
      ]}
    >
      {children}

      <Text style={[styles.text, { color: colors[text] }, textStyle]}>
        {title}
      </Text>
      {otherProps.disabled && (
        <View style={{ position: 'absolute', left: 20 }}>
          <ActivityIndicator size='large' color={colors.background} />
        </View>
      )}
    </TouchableOpacity>
  );
};
const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,

    // borderColor: colors.main,
    // borderWidth: 1,
    flexDirection: 'row',
    height: 60,
    borderRadius: 30,
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    marginTop: 16,
  },
  text: {
    // marginLeft: 20,
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'poppins-semibold',
  },
});
