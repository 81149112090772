import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  createStackNavigator,
  CardStyleInterpolators,
  TransitionPresets,
  TransitionSpecs,
} from '@react-navigation/stack';
import { View, TouchableOpacity } from 'react-native';
import { getFocusedRouteNameFromRoute } from '@react-navigation/native';
// import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { MaterialCommunityIcons, Entypo } from '@expo/vector-icons';

import Colors from '../config/colors';
// import colors from '../config/colors';

// import PostEdit from '../screens/PostEdit';
import EventCodeScreen from '../screens/EventCodeScreen';
import UsernameScreen from '../screens/UsernameScreen';
import MainScreen from '../screens/MainScreen';
import SearchScreen from '../screens/SearchScreen';
import HistoryScreen from '../screens/HistoryScreen';

// const historyScreenPromise = import('../screens/HistoryScreen');
// const HistoryScreen = React.lazy(() => historyScreenPromise);

// import MainScreen from '../screens/MainScreen';
// import NameScreen from '../screens/NameScreen';
// import SearchScreen from '../screens/SearchScreen';
// import AddSongScreen from '../screens/AddSongScreen';
// import MenuScreen from '../screens/MenuScreen';
// import QRCodeScreen from '../screens/QRCodeScreen';

import { screenOptions as eventsCodeScreenOptions } from '../screens/EventCodeScreen';
import { screenOptions as usernameScreenOptions } from '../screens/UsernameScreen';
import { screenOptions as mainScreenOptions } from '../screens/MainScreen';
import { screenOptions as searchScreenOptions } from '../screens/SearchScreen';
import { screenOptions as historyScreenOptions } from '../screens/HistoryScreen';

// import { screenOptions as queueScreenOptions } from '../screens/QueueScreen';
// import { screenOptions as mainScreenOptions } from '../screens/MainScreen';
// import { screenOptions as searchScreenOptions } from '../screens/SearchScreen';
// import { screenOptions as addSongScreenOptions } from '../screens/AddSongScreen';
// import { screenOptions as menuScreenOptions } from '../screens/MenuScreen';
// import { screenOptions as qrCodeScreenOptions } from '../screens/QRCodeScreen';
// import { screenOptions as signInScreenOptions } from '../screens/SignIn';

// const Tab = createBottomTabNavigator();

const Stack = createStackNavigator();

// function getHeaderTitle(route) {
//   // If the focused route is not found, we need to assume it's the initial screen
//   // This can happen during if there hasn't been any navigation inside the screen
//   // In our case, it's "Feed" as that's the first screen inside the navigator
//   const routeName = getFocusedRouteNameFromRoute(route) ?? 'Queue';
//   console.log('getHeaderTitle', routeName);
//   switch (routeName) {
//     case 'Queue':
//       return 'Queue List';
//     case 'History':
//       return 'Activity History';

//     case 'Guest':
//       return 'Guest List';
//     case 'Menu':
//       return 'Menu';
//   }
// }

const AppNavigator = (props) => {
  const initialScreen = props.defaultParams.initialScreen;
  const horizontalAnimation = {
    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
    animationEnabled: true,
    // gestureDirection: 'horizontal',
    // cardStyleInterpolator: ({ current, layouts }) => {
    //   return {
    //     cardStyle: {
    //       transform: [
    //         {
    //           translateX: current.progress.interpolate({
    //             inputRange: [0, 1],
    //             outputRange: [layouts.screen.width, 0],
    //           }),
    //         },
    //       ],
    //     },
    //   };
    // },
  };

  console.log('AppNavigator props-->', initialScreen);
  return (
    <Stack.Navigator
      initialRouteName={initialScreen}
      // screenOptions={horizontalAnimation}

      screenOptions={{
        ...horizontalAnimation,
        headerStyle: {
          backgroundColor: Colors.background,
          shadowColor: 'transparent', // this covers iOS
          elevation: 0, // this covers Android
        },
        headerTintColor: Colors.main,
        headerTitleStyle: {
          fontWeight: 'bold',
          color: Colors.main,
        },
        headerBackTitleStyle: {
          // fontWeight: 'bold',
          color: Colors.main,
        },
        headerBackTitle: 'Back',
      }}
    >
      {/* Note: By default, React Navigation applies optimizations to screen
      components to prevent unnecessary renders. Using a render callback removes
      those optimizations. So if you use a render callback, you'll need to
      ensure that you use React.memo or React.PureComponent for your screen
      components to avoid performance issues. */}

      <Stack.Screen
        name='EventCode'
        component={EventCodeScreen}
        options={eventsCodeScreenOptions}
        // options={{ headerShown: false }}
        // initialParams={}
      />

      <Stack.Screen
        name='Username'
        component={UsernameScreen}
        // options={({ route }) => {
        //   console.log('route', route);
        //   return {
        //     headerTitle: getHeaderTitle(route),
        //   };
        // }}
        options={usernameScreenOptions}
        initialParams={props.defaultParams}
      />

      <Stack.Screen
        name='Main'
        component={MainScreen}
        options={mainScreenOptions}
        // options={{ headerShown: false }}
        // initialParams={}
      />
      <Stack.Screen
        name='Search'
        component={SearchScreen}
        options={searchScreenOptions}
        // options={{ headerShown: false }}
        // initialParams={}
      />
      <Stack.Screen
        name='History'
        component={HistoryScreen}
        options={historyScreenOptions}
        // options={{ headerShown: false }}
        // initialParams={}
      />
    </Stack.Navigator>
  );
};

export default AppNavigator;
