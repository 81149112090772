import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { View } from 'react-native';
import { Modal, Center, Box, Button, useDisclose } from 'native-base';
import colors from '../config/colors';
import QRCode from 'react-native-qrcode-svg';

import * as appActions from '../redux_store/actions/appActions';

const QRCodeModal = (props) => {
  console.log();
  const dispatch = useDispatch();

  return (
    <View>
      {/* <QRCode size={200} value='http://awesome.link.qr' /> */}
      <Modal
        isOpen={props.isVisible}
        onClose={() => {
          dispatch(appActions.setQrCodeVisibility(false));
        }}
      >
        <Modal.Content w={'90%'}>
          <Modal.CloseButton />
          <Modal.Header>Contact Us</Modal.Header>
          <Modal.Body>Test 123</Modal.Body>
          {/* <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant='ghost'
                colorScheme='blueGray'
                onPress={() => {
                  setShowModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                onPress={() => {
                  setShowModal(false);
                }}
              >
                Save
              </Button>
            </Button.Group>
          </Modal.Footer> */}
        </Modal.Content>
      </Modal>
    </View>
  );
};

export default QRCodeModal;
