//https://smart-swatch.netlify.app/#0B132B

const colors = {
  primary: {
    50: '#dbfffc',
    100: '#affff3',
    200: '#80ffec',
    300: '#51ffe4',
    400: '#2efedc',
    500: '#1fe5c2',
    600: '#0fb397',
    700: '#00806c',
    800: '#004d41',
    900: '#001c16',
  },
  primary2: {
    50: '#E3F2F9',
    100: '#C5E4F3',
    200: '#A2D4EC',
    300: '#7AC1E4',
    400: '#47A9DA',
    500: '#0088CC',
    600: '#007AB8',
    700: '#006BA1',
    800: '#005885',
    900: '#003F5E',
  },
  rose: {
    50: '#ffe1f5',
    100: '#ffb1da',
    200: '#ff7ec0',
    300: '#ff4ca5',
    400: '#ff1a8c',
    500: '#e60072',
    600: '#b40059',
    700: '#82003f',
    800: '#500026',
    900: '#20000f',
  },
  oxford: {
    50: '#e9eeff',
    100: '#c2cdef',
    200: '#9aace2',
    300: '#738bd7',
    400: '#4d6acb',
    500: '#3551b3',
    600: '#283f8b',
    700: '#1c2d64',
    800: '#101b3d',
    900: '#030918',
  },
  amber: {
    50: '#fff2db',
    100: '#ffdab0',
    200: '#fdc480',
    300: '#fbac50',
    400: '#f99420',
    500: '#df7a06',
    600: '#ae5f02',
    700: '#7d4400',
    800: '#4c2800',
    900: '#1e0c00',
  },
  // primary: '#6FFFE9',
  secondary: '#FAE3E3',
  tertiary: '#870058',
  black: '#1E212B',
  white: 'white',
  // background: '#688ed6', //'#3A506B',
  background: '#1C2541',

  medium: '#cfcfcf',
  dark: '#0c0c0c',
  light: '#F3F3F3',
  white: '#FFFF',
  danger: '#FF7F11',
  apple: '#a6b1b7',
  facebook: '#4285f4',
  brightBlue: '#0096FF',
  notifColor: '#7189FF',
  google: '#DB4437',
  spotify: '#1db954',

  OxfordBlue: '#0B132B',
  SpaceCadet: '#1C2541',
  Independence: '#3A506B',
  MaxBlueGreen: '#5BC0BE',
  TurquiseBlue: '#6FFFE9',

  defBackgroundColor: '#1C2541', //'#3A506B', //'#424242',
  dark_background: '#011627',
  main: '#6FFFE9', // "#3d7886"
  beauBlue: '#CEE5F2',
  beauBlue2: '#ACCBE1',
  airSupeiorityBlue: '#7C98B3',
  darkElectricBlue: '#637081',
  //======
  // primary: '#6FFFE9', //"#3d7886",
  lavenderWeb: '#D9DBF1',

  lavenderGray: '#D0CDD7',
  cadetBlueCrayola: '#ACB0BD',
  midnightGreen: '#0B3948',
  //======
  deepSquidInk: '#152939',
  linkUnderlayColor: '#FFF',
  errorIconColor: '#DD3F5B',
  error: '#FF007F',
  textInputColor: '#000000',
  textInputBorderColor: '#C4C4C4',
  placeholderColor: '#C7C7CD',
  buttonColor: '#6FFFE9', //"#3d7886",
  disabledButtonColor: '#65737e',
  //=============
  hanPurple: '#6200EE',
  lavenderWeb: '#D8DCFF',
  bluePurple: '#AEADF0',
  puce: '#C38D94',
  roseDust: '#A76571',
  richBlack: '#0E1116',
  battenPink: '#9E7B9B',
  brightLilac: '#CB9CF2',
  mistyRose: '#F9E0D9',

  //=======
  OxfordBlue: '#0B132B',
  SpaceCadet: '#1C2541',
  Independence: '#3A506B',
  MaxBlueGreen: '#5BC0BE',
  TurquiseBlue: '#6FFFE9',

  //=====
  flickrPink: '#F72585',
  byzantine: '#B5179E',
  purpleLight: '#7209B7',
  purpleDark: '#560BAD',
  trypanBlueLight: '#480CA8',
  trypanBlueDark: '#3A0CA3',
  persianBlue: '#3F37C9',
  marineBlue: '#4361EE',
  dodgeBlue: '#4895EF',
  vividSkyBlue: '#4CC9F0',
};

export default colors;
