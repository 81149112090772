import React, { useEffect, useRef, useState, AppContext } from 'react';
import { View, Image, StyleSheet, Platform } from 'react-native';
import Screen from '../components/Screen';
import * as Font from 'expo-font';
import { Provider, useDispatch, useSelector } from 'react-redux';
import * as songActions from '../redux_store/actions/songActions';
import * as appActions from '../redux_store/actions/appActions';
import * as myUtils from '../utils/myUtils';
import { v4 as uuid } from 'uuid';

let defaultParams = {};

function getParameterByName(name) {
  var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}
const getUrlParameters = async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    if (Platform.OS === 'web') {
      defaultParams.eventCode = getParameterByName('code'); // pcs
      defaultParams.eventName = getParameterByName('event'); // pcs
      //   defaultParams.companyCode = getParameterByName('event');
      defaultParams.version = getParameterByName('ver');

      const hostName = window.location.host;
      console.log(
        'getUrlParameters hostName',
        hostName,
        defaultParams?.eventCode?.length
      );
      // if (hostName.includes('blessed18')) {
      //   defaultParams.eventCode = 'blessed18';
      //   defaultParams.companyCode = 'hlorenzo';
      //   defaultParams.version = 'v1';
      // }
    }

    console.log('getUrlParameters', defaultParams);

    resolve(defaultParams);
  });
};

const SplashScreen = (props) => {
  const dispatch = useDispatch();

  const localAppSettingsState = useSelector((state) => {
    console.log('state', state);
    //@ts-ignore
    return state?.appReducer?.appSettings;
  });

  const initializeApp = async () => {
    console.log('STARTED LOADING APP!!!');

    // return new Promise(async (resolve, reject) => {
    await Font.loadAsync({
      // 'poppins-extralight': require('./assets/fonts/Poppins-ExtraLight.ttf'),
      'poppins-extralight': require('../assets/fonts/Poppins-ExtraLight.ttf'),
      'poppins-light': require('../assets/fonts/Poppins-Light.ttf'),
      'poppins-medium': require('../assets/fonts/Poppins-Medium.ttf'),
      'poppins-regular': require('../assets/fonts/Poppins-Regular.ttf'),
      'poppins-semibold': require('../assets/fonts/Poppins-SemiBold.ttf'),
      'poppins-semibold': require('../assets/fonts/Poppins-SemiBold.ttf'),
      // 'my-custom-icon-set': require('../assets/fonts/MyCustomIconSet.ttf'),
    }).then(() => {
      console.log('FONTS LOADED!!!');
    });

    //Set Installtion ID
    let installationId = localAppSettingsState?.installationId;
    if (!installationId || installationId?.length === 0) {
      installationId = uuid();
      dispatch(
        appActions.saveSettings({
          installationId: installationId,
        })
      );
      console.log('installationId 1:', installationId);
    }
    console.log('installationId 2:', installationId);
    //   await myUtils.delay(!__DEV__ ? 0 : 5);

    getUrlParameters(dispatch).then(async (defaultParams) => {
      console.log(
        'getUrlParameters2 defaultParams',
        defaultParams,
        localAppSettingsState?.userName,
        defaultParams?.eventCode?.length === 6,

        localAppSettingsState?.userName?.length,
        localAppSettingsState?.userName?.length > 0
      );

      console.time('Execution Time');
      //Get event info from AWS if user name exists
      //This will make initial app load much faster
      //This will make Main screen default/initial screen
      if (
        defaultParams?.eventCode?.length === 6 &&
        localAppSettingsState?.userName &&
        localAppSettingsState?.userName.length > 0
      ) {
        const event = await dispatch(
          appActions.getEventInfo(
            defaultParams.eventCode.toUpperCase(),
            defaultParams.eventName
          )
        );

        console.log('EventCodeScreen event', event);
        if (event?.eventCode?.length > 0) {
          await myUtils.configureDataStore(event);
          console.log('event', event);
          // console.log('setSongsForEvent+ 3');
          // dispatch(songActions.setSongsForEvent(true, event?.id));
        } else {
          console.log('getEventInfo IS NULL');
          defaultParams.eventCode = '';
          defaultParams.initialScreen = 'EventCode';
          props?.onSetDefaultParams(defaultParams);
          return;
        }
      }

      console.timeEnd('Execution Time');
      console.log('=-=-=-=-=-=-', defaultParams?.eventCode);
      if (defaultParams?.eventCode?.length === 6) {
        if (
          !localAppSettingsState?.userName ||
          localAppSettingsState?.userName.length == 0
        ) {
          //   props?.onSetInitialScreen('Username');
          defaultParams.initialScreen = 'Username';
        } else {
          //   props?.onSetInitialScreen('Main');
          defaultParams.initialScreen = 'Main';
        }
      } else {
        //   props?.onSetInitialScreen('EventCode');
        defaultParams.initialScreen = 'EventCode';
        myUtils.clearLocaleDataStore();
        dispatch(appActions.deleteEventInfo());
      }

      props?.onSetDefaultParams(defaultParams);
    });

    //   resolve();
    // });
  };

  useEffect(() => {
    initializeApp();
  }, []);

  return (
    <Screen>
      <Image style={styles.image} source={require('../assets/splash.png')} />
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: 50,
  },
  image: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // marginVertical: 20,
    resizeMode: 'cover',
  },
});

export default SplashScreen;
