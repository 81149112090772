import { Platform } from 'react-native';

import colors from './colors';

const defTextColor = 'white'; //colors.midnightGreen;
export default {
  colors,
  controlContainer: {
    marginHorizontal: 16,
    // flex: 1,
    // // width: '100%',
    // backgroundColor: 'red',
  },
  title: {
    color: defTextColor,
    fontSize: 22,
    textAlign: 'left',
    // fontFamily: Platform.OS === 'ios' ? 'Helvetica' : 'Roboto',
    fontFamily: 'poppins-semibold',
  },

  tinyText: {
    fontSize: 14,
    color: defTextColor,
    // textAlign: 'center',
    fontFamily: 'poppins-light',
  },
  text: {
    color: defTextColor,
    fontSize: 14,
    fontFamily: 'poppins-light',
  },
  errorText: {
    fontSize: 16,
    fontFamily: 'poppins-semibold',
    color: colors.rose,
    // flex: 1,
    // justifyContent: 'center',
  },

  boldText: {
    fontFamily: 'poppins-semibold',
    textAlign: 'left',
    fontSize: 13,
    color: defTextColor,
  },
  smallText: {
    fontFamily: 'poppins-light',
    textAlign: 'left',
    fontSize: 12,
    color: defTextColor,
  },
  subTitleText: {
    fontFamily: 'poppins-semibold',
    textAlign: 'left',
    fontSize: 14,
    color: defTextColor,
  },
  titleText: {
    fontFamily: 'poppins-semibold',
    textAlign: 'left',
    fontSize: 26,
    color: 'white',
  },
  textInput: {
    flex: 1,
    // backgroundColor: 'red',
    padding: 15,
  },
};
