// import Asset from "../../models/assets";
// import { API, graphqlOperation } from "aws-amplify";

// import { listAssets } from "../../src/graphql/queries";
// import {
//   addAsset as addAsset_AWS,
//   deleteAsset as deleteAsset_AWS,
//   updateAsset as updateAsset_AWS,
// } from "../../src/graphql/mutations";
// import { AsyncStorage } from "react-native";

import { getCompany } from '../../src/graphql/queries';
import * as spotifyUtils from '../../utils/spotifyUtils';
import moment, { now } from 'moment';
import * as Network from 'expo-network';
import { firstBy } from 'thenby';

const clone = require('rfdc')();

import { Event, RS, History, Guest } from '../../src/models';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';

import * as myUtils from '../../utils/myUtils';

// import * as customQueries from '../../src/graphql/myCustomQueries';
// export const SHOW_CONFIRM_DLG = 'SHOW_CONFIRM_DLG';
// export const HIDE_CONFIRM_DLG = 'HIDE_CONFIRM_DLG';
export const SAVE_APP_SETTINGS = 'SAVE_APP_SETTINGS';
export const SAVE_USER = 'SAVE_USER';
export const DELETE_USER = 'DELETE_USER';
export const SET_AUTH_STATUS = 'SET_AUTH_STATUS';
export const UPDATE_TOAST = 'UPDATE_TOAST';
export const SET_QRCODE_MODAL = 'SET_QRCODE_MODAL';
export const SAVE_EVENT = 'SAVE_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const SET_EVENTS = 'SET_EVENTS';
export const DELETE_EVENT = 'DELETE_EVENT';
export const DELETE_CURRENT_EVENT = 'DELETE_CURRENT_EVENT';
export const SET_ISLOADING_EVENTS = 'SET_ISLOADING_EVENTS';
export const SET_IS_SYNC_PENING = 'SET_IS_SYNC_PENING';
export const SET_NETWORK_STATUS = 'SET_NETWORK_STATUS';
export const ADD_HISTORY = 'ADD_HISTORY';
export const SET_HISTORY = 'SET_HISTORY';
export const INC_NAME_CHANGED_COUNT = 'INC_NAME_CHANGED_COUNT';
// export const SET_SUSPENSION = 'SET_SUSPENSION';

const wait = (timeout) => {
  return new Promise((resolve) => setTimeout(resolve, timeout));
};

export const incNameChangedCount = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: INC_NAME_CHANGED_COUNT,
    });
  };
};
export const setHistoryForEvent = (updateReduxStore = true, eventId) => {
  return async (dispatch, getState) => {
    if (!eventId) {
      const appSettings = getState().appReducer?.appSettings;
      eventId = appSettings?.currentEvent?.id;
    }

    if (!eventId) return;

    DataStore.query(History, (hist) => hist.eventHistoryId('eq', eventId), {
      sort: (s) =>
        s?.updatedAt
          ? s.updatedAt(SortDirection.DESCENDING)
          : s.postedAt(SortDirection.DESCENDING),
    }).then((items) => {
      console.log('setHistoryForEvent', eventId, items, updateReduxStore);
      if (updateReduxStore)
        dispatch({
          type: SET_HISTORY,
          items: items,
          // isLoadingQueues: false,
          // nowPlaying: null,
        });
    });
  };
};

export const setHistory = (items) => {
  return async (dispatch, getState) => {
    // if (items[0]?.updatedAt) {
    console.log('setHistory updatedAt', items[0]?.updatedAt);
    //   items.sort(firstBy('updatedAt', 'desc'));
    // } else {
    //   items.sort(firstBy('postedAt', 'desc'));
    // }

    dispatch({
      type: SET_HISTORY,
      items: items,
      // isLoadingQueues: false,
      // nowPlaying: null,
    });
  };
};

//  installationId: String
// type: String
// subject: String
// verb: String
// object: String
// punctuation: String
export const addActivityHistory = (
  type,
  subject = '',
  verb = '',
  object = '',
  punctuation = '',
  description = '',
  itemId = null,
  isPrivate = false
) => {
  return async (dispatch, getState) => {
    let appSettings = getState().appReducer?.appSettings;

    console.log(
      'addActivityHistory subject, verb, object',
      subject,
      verb,
      object
    );

    console.log('addActivityHistory itemId', itemId);

    try {
      let newHistory = await DataStore.save(
        //@ts-ignore
        new History({
          eventId: appSettings?.currentEvent.id,
          eventHistoryId: appSettings?.currentEvent.id,
          userId: appSettings?.user.id,
          userHistoryId: appSettings?.user.id,
          guestId: appSettings?.user.id,
          guestHistoryId: appSettings?.user.id,
          songId: itemId,

          postedAt: moment().utc().format(),

          installationId: appSettings.installationId,
          type: type,
          subject: subject.trim(),
          verb: verb,
          object: object,
          punctuation: punctuation,
          description: description,
        })
      );
      console.log('addHistory newHistory', newHistory);
      dispatch({
        type: ADD_HISTORY,
        history: newHistory,
      });
    } catch (error) {
      console.log('addHistory error', error);
    }
  };
};

export const addActivityHistory2 = ({
  type,
  subject = '',
  verb = '',
  object = '',
  punctuation = '',
  description = '',
  itemId = null,
  isPrivate = false,
}) => {
  return async (dispatch, getState) => {
    let appSettings = getState().appReducer?.appSettings;

    console.log(
      'addActivityHistory2 subject, verb, object',
      subject,
      verb,
      object
    );

    console.log('addActivityHistory2 itemId', itemId);

    try {
      let newHistory = await DataStore.save(
        //@ts-ignore
        new History({
          eventId: appSettings?.currentEvent.id,
          eventHistoryId: appSettings?.currentEvent.id,
          userId: appSettings?.user.id,
          userHistoryId: appSettings?.user.id,
          guestId: appSettings?.user.id,
          guestHistoryId: appSettings?.user.id,
          songId: itemId,

          postedAt: moment().utc().format(),

          installationId: appSettings.installationId,
          type: type,
          subject: subject.trim(),
          verb: verb,
          object: object,
          punctuation: punctuation,
          description: description,
        })
      );
      console.log('addHistory2 newHistory', newHistory);
      dispatch({
        type: ADD_HISTORY,
        history: newHistory,
      });
    } catch (error) {
      console.log('addHistory2 error', error);
    }
  };
};

// export const addHistory = (guest, actionType = 'track', title, description) => {
//   //Type: track, playlist, playback, guest, like
//   return async (dispatch, getState) => {
//     let appSettings = getState().appReducer?.appSettings;
//     console.log('addHistory', appSettings, guest, title, description);

//     try {
//       let newHistory = await DataStore.save(
//         //@ts-ignore
//         new History({
//           eventHistoryId: appSettings?.currentEvent.id,
//           userHistoryId: appSettings?.user.id,
//           guestHistoryId: appSettings?.user.id,
//           eventId: appSettings?.currentEvent.id,
//           userId: appSettings?.user.id,
//           postedAt: moment().utc().format(),
//           guestId: appSettings?.user.id,
//           title: title,
//           description: description,
//           actionType: actionType,
//         })
//       );
//       console.log('addHistory newHistory', newHistory);
//       dispatch({
//         type: ADD_HISTORY,
//         history: newHistory,
//       });
//     } catch (error) {
//       console.log('addHistory error', error);
//     }
//   };
// };

export const clearLocalDataStore = () => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      DataStore.clear()
        .then(() => {
          console.log('LOCAL DataStore cleared!');
          resolve();
        })
        .catch(() => {
          resolve();
        });
    });
  };
};

export const startDataStoreSync = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        await DataStore.stop();
        await DataStore.start();
        resolve();
      } catch (error) {
        reject();
      }
    });
  };
};
export const deleteEvent = (event) => {
  console.log('deleteEvent', event);
  return async (dispatch, getState) => {
    dispatch({
      type: DELETE_EVENT,
      event: event,
    });

    const deletedEvent = DataStore.delete(Event, (item) =>
      item.id('eq', event.id)
    );
    console.log('deletedEvent', deletedEvent);

    setBackgroundAutoPlay(false);
  };
};
export const getEvents = (updateReduxStore = true) => {
  return async (dispatch, getState) => {
    // let userEmail = getState().appReducer?.user.email;
    console.log('eventsDS-eventsDS-eventsDS');
    dispatch({
      type: SET_ISLOADING_EVENTS,
      isLoadingEvents: true,
    });
    try {
      const eventsDS = await DataStore.query(Event, Predicates.ALL, {
        sort: (s) => s.updatedAt(SortDirection.DESCENDING),
      });
      console.log('DataStore.query(Event)', eventsDS);
      if (updateReduxStore)
        dispatch({
          type: SET_EVENTS,
          events: eventsDS,
          isLoadingEvents: false,
        });
    } catch (err) {
      console.error('getEvents error ', err);
    }
  };
};
export const setQrCodeVisibility = (isVisible) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_QRCODE_MODAL,
      isQrCodeVisible: isVisible,
    });
  };
};

export const showToast = (lastSpotifyError, type) => {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_TOAST,
      toastObj: { infoObj: lastSpotifyError, type: type },
    });
  };
};

export const isSyncPending = (isSyncPending) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_IS_SYNC_PENING,
      isSyncPending: isSyncPending,
    });
  };
};
export const setNetworkStatus = (status) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_NETWORK_STATUS,
      isWithInternet: status,
    });
  };
};
export const setToastAlert = (lastSpotifyError, type) => {
  return async (dispatch, getState) => {
    console.log('setToastAlert', lastSpotifyError);
    let lastErrorObj = lastSpotifyError;
    if (lastSpotifyError?.status)
      lastErrorObj = spotifyUtils.setErrorMsg(lastSpotifyError);

    dispatch({
      type: UPDATE_TOAST,
      toastObj: lastSpotifyError ? { infoObj: lastErrorObj, type: type } : null,
    });
  };
};
//sr++++++
export const getSpotifyAccessToken = (eventId) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        const currentEvent = getState().appReducer?.appSettings?.currentEvent;
        const spotifySession = await spotifyUtils.getSpotifyAccessToken(
          eventId
        );
        console.log('spotifySession', spotifySession);
        // spotifySession.expiresAt = moment()
        //   .add(spotifySession.expiresIn, 'seconds')
        //   .utc()
        //   .format();
        currentEvent.spotifySession = spotifySession;
        // dispatch(saveSettings({ spotify: spotifySession }));
        dispatch(saveSettings({ currentEvent: currentEvent }));

        resolve(spotifySession);
      } catch (error) {
        console.log('getSpotifyAccessToken ERROR', error);
        reject(error);
      }
    });
  };
};

//sr++++++
export const updateAuthState = (authStat) => {
  return async (dispatch, getState) => {
    console.log('updateAuthState-->>', authStat);
    // if (authStat === 'loggedOut') myUtils.removeUserData();
    dispatch({
      type: SET_AUTH_STATUS,
      status: authStat,
    });
    if (authStat === 'loggedOut') {
      myUtils.clearLocaleDataStore();
      dispatch({
        type: DELETE_USER,
      });
    }
  };
};

export const getRemainingSuspTime = (guest) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      const url =
        'https://xo615nbxgj.execute-api.us-east-1.amazonaws.com/v1/songrequest';

      const ApiParams = {
        msg: 'GET_REMAINING_SUSPENSION_TIME',
        data: {
          isSuspended: guest.isSuspended,
          suspDurationMin: guest.suspDurationMin,
          suspEndedAt: guest.suspEndedAt,
        },
      };
      const params = {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(ApiParams),
        method: 'POST',
      };
      console.log('getRemainingSuspTime ApiParams', ApiParams);

      try {
        await fetch(url, params).then((apiResponse) => {
          if (apiResponse && apiResponse.ok) {
            apiResponse.json().then((json) => {
              console.log('getRemainingSuspTime fetch response ----> ', json);
              resolve(json);
            });
          } else {
            apiResponse.json().then((json) => {
              console.log('getRemainingSuspTime ERROR response ----> ', json);
              console.log('response NOT OK', apiResponse);
              reject();
            });
          }
        });
      } catch (error) {
        console.log('response error', error);
        reject();
      }
    });
  };
};

export const setBackgroundAutoPlay = (isEnabled) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let isPlaying = getState().songsReducer?.nowPlaying?.isPlaying;
      console.log(
        'setBackgroundAutoPlay->',
        isEnabled,
        isPlaying,
        getState().songsReducer
      );
      if (!isPlaying || getState().appReducer?.appSettings === null) {
        console.log('appSetting is NULL');
        reject();
        return;
      }

      let appSettings = getState().appReducer?.appSettings;
      let currentUser = getState().appReducer?.user;

      const { spotify, currentEvent } = appSettings;
      console.log('appSetting -> spotify, currentEvent', spotify, currentEvent);
      if (!spotify || !currentEvent) {
        reject();
        return;
      }

      const url =
        'https://xo615nbxgj.execute-api.us-east-1.amazonaws.com/v1/songrequest';
      const dataObj = {
        isEnabled: isEnabled,
        eventId: currentEvent.id,
        // userEmail: currentUser.email,
        // eventCode: currentEvent.eventCode,
      };

      console.log('setBackgroundAutoPlay dataObj', dataObj);

      const ApiParams = {
        msg: 'AUTO_PLAY',
        data: dataObj,
      };

      console.log('setBackgroundAutoPlay ApiParams', ApiParams);
      const params = {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(ApiParams),
        method: 'POST',
        // mode: 'no-cors',
      };
      console.log('setBackgroundAutoPlay params', params);
      try {
        await fetch(url, params).then((apiResponse) => {
          if (apiResponse && apiResponse.ok) {
            apiResponse.json().then((json) => {
              console.log('setBackgroundAutoPlay response ----> ', json);
            });
          } else {
            console.log('setBackgroundAutoPlay response NOT OK', apiResponse);
          }
          resolve();
        });
      } catch (error) {
        console.log('setBackgroundAutoPlay response error', error);
        reject();
      }
    });
  };
};

export const deleteUser = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: DELETE_USER,
    });
  };
};

//sr++++++
export const saveUser = (user) => {
  return async (dispatch, getState) => {
    console.log('Redux saveUser', user);
    // dispatch({
    //   type: SAVE_USER,
    //   user: user,
    // });

    const userCopy = clone(user);
    myUtils.removeEmptyOrNull(userCopy);
    console.log('saveUser removeEmptyOrNull', userCopy);

    dispatch({
      type: SAVE_APP_SETTINGS,
      appSettings: {
        user: userCopy,
        userName: userCopy?.userName,
      },
    });

    //Check if suspension has changed
    // if (
    //   !myUtils.checkIfPropertiesAreEqual(
    //     { isSuspended: userCopy.isSuspended },
    //     getState().appReducer?.user
    //   )
    // ) {
    //   dispatch({
    //     type: SET_SUSPENSION,
    //     item: {
    //       isSuspended: userCopy.isSuspended,
    //       suspDurationMin: userCopy.suspDurationMin,
    //       suspReasonMsg: userCopy.suspReasonMsg,
    //       suspEndedStr: userCopy.suspEndedStr,
    //       suspStartedAt: userCopy.suspStartedAt,
    //     },
    //   });
    // }
  };
};

export const recreatePlaylist = (event) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        let currentUser = getState().appReducer?.user;

        await checkSpotifyTokenSaveAppSettings(dispatch, getState);
        const playlist = await createPlaylist(currentUser, event);
        // console.log('recreatePlaylist playlist', playlist, event);

        const existingEvent = await DataStore.query(Event, event.id);
        // console.log('existingEvent', existingEvent);

        const updatedEvent = await DataStore.save(
          Event.copyOf(existingEvent, (item) => {
            item.playlistId = playlist.id;
          })
        );

        // console.log('recreatePlaylist updatedEvent', updatedEvent);
        dispatch({
          type: SAVE_EVENT,
          event: updatedEvent,
        });

        dispatch({
          type: SAVE_APP_SETTINGS,
          appSettings: { currentEvent: updatedEvent },
        });

        resolve(updatedEvent);
      } catch (error) {
        console.log('recreatePlaylist ERROR', error);
        reject(error);
      }
    });
  };
};

export const createPlaylist = (currentUser, event) => {
  return new Promise(async (resolve, reject) => {
    // console.log('createPlaylist currentUser', currentUser);
    spotifyUtils
      .createSpotifyPlaylist(currentUser.spotifyId, event)
      .then(async (newPlaylist) => {
        // console.log(
        //   'createEvent spotifyUtils.createSpotifyPlaylist',
        //   newPlaylist
        // );
        resolve(newPlaylist);
      })
      .catch((err) => {
        console.log('createSpotifyPlaylist retVal Error', err);
        reject(err);
      });
  });
};

export const deleteSpotifyPlaylist = (event) => {
  // console.log('deleteSpotifyPlaylist', event);
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await checkSpotifyTokenSaveAppSettings(dispatch, getState);

      spotifyUtils
        .deletePlaylist(event.playlistId)
        .then(() => {
          // console.log('Successfully deleted playlist', event.playlistId);
          resolve();
        })
        .catch((err) => {
          console.log('deleteSpotifyPlaylist retVal Error', err);
          reject(err);
        });
    });
  };
};
export const updateEvent = (event) => {
  return async (dispatch, getState) => {
    let appSettings = getState().appReducer?.appSettings;
    const { currentEvent } = appSettings;

    const existingEvent = await DataStore.query(Event, currentEvent.id);
    const updatedEvent = await DataStore.save(
      Event.copyOf(existingEvent, (item) => {
        item = {
          ...existingEvent,
          ...event,
        };
      })
    );
  };
};
export const createEvent = (partyName) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        let currentUser = getState().appReducer?.user;
        let spotifySession = getState().appReducer?.appSettings?.spotify;
        const eventCode = myUtils.generateCode(6);

        console.log('createEvent', getState().appReducer);
        // await checkSpotifyTokenSaveAppSettings(dispatch, getState);
        // const playlist = await createPlaylist(currentUser, {
        //   eventName: partyName,
        //   eventCode: eventCode,
        // });

        let newEvent = await DataStore.save(
          //@ts-ignore
          new Event({
            userId: currentUser.id,
            userEventId: currentUser.id,
            eventName: partyName,
            eventCode: eventCode,
            companyCode: currentUser.email,
            spotifySession: JSON.parse(JSON.stringify(spotifySession)),
            // playlistId: playlist.id,
          })
        );

        console.log('Redux createEvent', newEvent);
        dispatch({
          type: SAVE_EVENT,
          event: newEvent,
        });

        resolve(newEvent);
      } catch (error) {
        console.log('createEvent ERROR', error);
        reject(error);
      }
    });
  };
};

export const updateCurrentGuest = (guestInfo) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      // if (
      //   myUtils.checkIfPropertiesAreEqual(
      //     guestInfo,
      //     getState().appReducer?.user
      //   )
      // ) {
      //   resolve();
      //   console.log('NOT MODIFIED!');
      //   return;
      // }
      console.log('MODIFIED!');
      try {
        const existingGuest = await DataStore.query(
          Guest,
          getState().appReducer?.appSettings?.user.id
        );

        console.log('updateCurrentGuest existingGuest', existingGuest);
        const updatedGuest = await DataStore.save(
          Guest.copyOf(existingGuest, (item) => {
            Object.keys(guestInfo).forEach((key) => {
              if (item.hasOwnProperty(key)) item[key] = guestInfo[key];
            });
          })
        );
      } catch (error) {}
    });
  };
};
export const saveCurrentGuest = (currentGuest) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      // const existingGuest = await DataStore.query(Guest, currentGuest.installationId);
      console.log('saveCurrentGuest', currentGuest);
      const appSettings = getState().appReducer?.appSettings;
      const installationId = appSettings.installationId;
      const currentEvent = appSettings.currentEvent;

      let ipAddress = '0.0.0.0';
      try {
        ipAddress = await Network.getIpAddressAsync();
      } catch (err) {
        // console.error('getIpAddressAsync err ', err);
      }

      const existingGuests = await DataStore.query(Guest, (guest) =>
        guest.installationId('eq', installationId)
      );

      console.log('existingGuests', installationId, existingGuests);
      let guestInfoObj = null;
      try {
        //Existing guest
        if (installationId && existingGuests.length > 0) {
          guestInfoObj = existingGuests[0];
          console.log('Guest already exists!', existingGuests);
          if (
            // existingGuests[0].installationId !== installationId ||
            existingGuests[0].userName !== currentGuest.userName
          ) {
            console.log(
              'Guest already exists AND info has changed!',
              existingGuests,
              currentGuest
            );

            const retVal = await dispatch(
              checkIfNameIsTaken(currentGuest.userName)
            );

            if (retVal.isExists) {
              console.log('checkIfNameIsTaken1 isExists', retVal.isExists);
              resolve(false);
              return;
            }

            guestInfoObj = await DataStore.save(
              Guest.copyOf(existingGuests[0], (item) => {
                item.lastIpAddress = ipAddress;
                item.userName = currentGuest.userName;
                (item.userName_lowerCase = currentGuest.userName.toLowerCase()),
                  (item.eventId = currentEvent.id);
              })
            );

            dispatch({
              type: SAVE_APP_SETTINGS,
              appSettings: {
                userName: currentGuest.userName,
                user: guestInfoObj,
              },
            });

            if (existingGuests[0].userName !== currentGuest.userName) {
              dispatch(
                addActivityHistory(
                  'guest',
                  existingGuests[0].userName,
                  ' changed name to ',
                  currentGuest.userName,
                  '.'
                )
              );
              dispatch(incNameChangedCount());
            }
          }
        }
        //New guest
        else {
          const newGuest = {
            lastIpAddress: ipAddress,
            installationId: installationId,
            userName: currentGuest.userName,
            userName_lowerCase: currentGuest.userName.toLowerCase(),
            eventId: currentEvent.id,
            eventGuestId: currentEvent.id,
          };

          console.log('Guest is NEW!', newGuest, currentGuest);

          const retVal = await dispatch(
            checkIfNameIsTaken(currentGuest.userName)
          );

          if (retVal.isExists) {
            console.log('checkIfNameIsTaken2 isExists', retVal.isExists);
            resolve(false);
            return;
          }

          guestInfoObj = await DataStore.save(new Guest(newGuest));
          console.log('guestInfoObj', guestInfoObj);

          dispatch({
            type: SAVE_APP_SETTINGS,
            appSettings: {
              userName: currentGuest.userName,
              user: guestInfoObj,
            },
          });

          dispatch(
            addActivityHistory(
              'guest',
              guestInfoObj.userName.trim(),
              ' has joined the party!',
              '',
              ''
            )
          );
        }

        // dispatch(
        //   addHistory(
        //     null,
        //     'guest',
        //     guestInfoObj.userName + ' has joined the party!'
        //   )
        // );

        resolve(true);
      } catch (error) {
        console.log('DataStore.save saveCurrentGuest ERROR', error);
        reject();
      }
    });
  };
};

//sr++++++
export const saveSettings = (appSettings) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log('appSettings-->1', appSettings);

        dispatch({
          type: SAVE_APP_SETTINGS,
          appSettings: appSettings,
        });
        resolve();
      } catch (err) {
        console.log(err);
        console.log('error in saveSettings');
        reject(err);
      }
    });
  };
};

//sr++++++
export const getPlaybackDevices = () => {
  return async (dispatch, getState) => {
    try {
      console.log('getPlaybackDevices');

      checkSpotifyTokenSaveAppSettings(dispatch, getState, {
        playbackDevices: devices,
      });
      const devices = await spotifyUtils.getMyDevices();

      return devices;
    } catch (error) {
      console.log('getPlaybackDevices ERROR', error);
      return [];
    }
  };
};

//sr++++++
export const connectToSpotifyDevice = (item) => {
  return async (dispatch, getState) => {
    checkSpotifyTokenSaveAppSettings(dispatch, getState, {
      playbackDevice: item,
    });
    spotifyUtils.connectToSpeaker(item.id);
  };
};

export const checkSpotifyTokenSaveAppSettings = async (
  dispatch,
  getState,
  objToSave = null
) => {
  let appSettings = getState().appReducer?.appSettings;
  // let currentUser = getState().appReducer?.user;

  const currentEvent = getState().appReducer?.appSettings?.currentEvent;
  const spotifySession = await spotifyUtils.getSpotifyAccessToken(
    currentEvent.id
  );
  console.log('spotifySession', spotifySession);
  // spotifySession.expiresAt = moment()
  //   .add(spotifySession.expiresIn, 'seconds')
  //   .utc()
  //   .format();

  console.log('checkSpotifyTokenSaveAppSettings');

  const newSpotifySession = await spotifyUtils.checkSpotifyToken(appSettings);

  if (
    newSpotifySession &&
    newSpotifySession?.token !== currentEvent?.spotifySession?.token
  ) {
    currentEvent.spotifySession = spotifySession;
    // dispatch(saveSettings({ spotify: spotifySession }));
    dispatch(saveSettings({ currentEvent: currentEvent }));

    const existingEvent = await DataStore.query(Event, currentEvent.id);
    try {
      const updatedEvent = await DataStore.save(
        Event.copyOf(existingEvent, (item) => {
          item.spotifySession = newSpotifySession;
        })
      );
      console.log(
        'checkSpotifyTokenSaveAppSettings updatedEvent',
        updatedEvent
      );
    } catch (error) {
      console.log('DataStore.save spotifySession ERROR', error);
    }

    if (objToSave)
      dispatch(saveSettings({ currentEvent: currentEvent, ...objToSave }));
    else dispatch(saveSettings({ currentEvent: currentEvent }));
  } else if (objToSave) {
    dispatch(saveSettings(objToSave));
  }
};

export const addToSpotifyPlaybackQueue = (song) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      console.log('addToSpotifyPlaybackQueue song', song);

      if (!song) {
        if (getState().songsReducer?.queue?.length > 0)
          song = getState().songsReducer?.queue[0];
        console.log(
          'addToSpotifyPlaybackQueue getState().songsReducer?.queue[0]!',
          song
        );
      }

      let nextToPlayUri = getState().songsReducer?.nextToPlayUri;
      let nextToPlayPostedAt = getState().songsReducer?.nextToPlayPostedAt;
      const nextToPlayPostedAtInSec = moment().diff(
        moment(nextToPlayPostedAt),
        'seconds'
      );
      // const nextToPlayPostedAtInSec = moment(nextToPlayPostedAt).diff(
      //   moment(),
      //   'seconds'
      // );
      console.log(
        'addToSpotifyPlaybackQueue nextToPlayPostedAt',

        nextToPlayPostedAt

        // nextToPlayPostedAtInSec
      );
      if (song && nextToPlayUri === song.uri) {
        console.log(
          'addToSpotifyPlaybackQueue ALREADY IN QUEUE!',
          song.name,
          nextToPlayPostedAt,
          nextToPlayPostedAtInSec,
          song.duration_ms / 1000
        );

        //Check if nextToPlay has been waiting for so long in the queue
        if (
          nextToPlayPostedAt &&
          nextToPlayPostedAtInSec < song.duration_ms / 1000
        ) {
          resolve(null);
          return;
        }
      } else if (!song) {
        resolve(null);
        return;
      }

      const existingEvent = await DataStore.query(Event, song.eventSongId);
      if (existingEvent.nextToPlayUri === song.uri) {
        console.log(
          'addToSpotifyPlaybackQueue is already set as nextToPlayUri!',
          song.name
        );

        //Check if nextToPlay has been waiting for so long in the queue
        if (
          nextToPlayPostedAt &&
          nextToPlayPostedAtInSec < song.duration_ms / 1000
        ) {
          resolve(null);
          return;
        }
      }

      await checkSpotifyTokenSaveAppSettings(dispatch, getState);
      console.log('addToSpotifyPlaybackQueue->', song);

      try {
        if (song) {
          try {
            console.log('BEFORE addToSpotifyQueue->', song);

            await spotifyUtils.addToSpotifyQueue(song);
            console.log('AFTER addToSpotifyQueue->', song);

            //Update event with nextToPlayUri
            const updatedEvent = await DataStore.save(
              Event.copyOf(existingEvent, (item) => {
                item.nextToPlayUri = song.uri;
              })
            );
            console.log('Update event with nextToPlayUri', updatedEvent);

            //Update event in array of events
            dispatch({
              type: SAVE_EVENT,
              event: updatedEvent,
            });
            //Update current event
            // dispatch({
            //   type: UPDATE_EVENT,
            //   event: updatedEvent,
            // });

            resolve(song);
          } catch (error) {
            dispatch(setToastAlert(error, 'error'));
            console.log('addToSpotifyPlaybackQueue ERROR', error);
            reject(error);
          }
        } else {
          resolve();
        }
      } catch (error) {
        console.log('playSong retVal Error', error);
        dispatch(setToastAlert(error, 'error'));
        reject(error);
      }
    });
  };
};
export const playSong = (song) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await checkSpotifyTokenSaveAppSettings(dispatch, getState);
      console.log('playSong->', song);
      try {
        if (song) {
          await spotifyUtils.playTrack(song);
          resolve(song);
          return;
        } else {
          try {
            await spotifyUtils.playTrack();
          } catch (error) {}

          await wait(1000).then(async () => {
            const currentState = await dispatch(getSpotifyPlaybackState());
            console.log('currentState 1', currentState);
            if (currentState === '' || !currentState?.item) {
              dispatch(setToastAlert({ status: 100 }, 'error'));
              reject();
            } else {
              resolve(currentState);
            }
          });
        }
      } catch (error) {
        console.log('playSong retVal Error', error);
        dispatch(setToastAlert(error, 'error'));
        reject(error);
      }

      // .then(() => {
      //   console.log('spotifyUtils.playTrack retVal');
      //   resolve();
      // })
      // .catch(async (err) => {
      //   try {
      //     const currentState = await dispatch(getSpotifyPlaybackState());
      //     console.log('currentState 1', currentState);
      //     if (currentState === '' || !currentState?.item) {
      //       dispatch(setToastAlert({ status: 100 }, 'error'));
      //       reject();
      //     } else {
      //       resolve(currentState);
      //     }
      //   } catch (error) {
      //     console.log('playSong retVal Error', error);
      //     dispatch(setToastAlert(error, 'error'));
      //     reject(error);
      //   }
      // });
    });
  };
};

export const getSpotifyPlaybackState = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await checkSpotifyTokenSaveAppSettings(dispatch, getState);
      await wait(1000).then(() => {});
      spotifyUtils
        .getCurrentSpotifyPlaybackState()
        .then((state) => {
          console.log('getSpotifyPlaybackState track::', state);
          // if (state && state?.item) {
          // const nowPlaying = {
          //   ...state.item,
          //   progress_ms: state.progress_ms,
          //   repeat_state: state.repeat_state,
          //   shuffle_state: state.shuffle_state,
          //   is_playing: state.is_playing,
          //   device: state.device,
          // };
          // dispatch(songActions.setNowPlayingSong(nowPlaying));
          resolve(state);
          // } else resolve(state);
        })
        .catch((err) => {
          console.log('getSpotifyPlaybackState ERROR', err);

          // reject(err);
          resolve();
        });
    });
  };
};

export const playSpotifyPlayList = (song) => {
  console.log('playSpotifyPlayList->', song);
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      const appSettings = getState().appReducer?.appSettings;

      await checkSpotifyTokenSaveAppSettings(dispatch, getState);

      spotifyUtils
        .playPlaylist(appSettings?.currentEvent.playlistId, song)
        .then(() => {
          console.log('playSpotifyPlayList');
          resolve();
        })
        .catch((err) => {
          console.log('playSpotifyPlayList', err);
          dispatch(setToastAlert(err, 'error'));
          reject(err);
        });
    });
  };
};

export const pauseSong = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await checkSpotifyTokenSaveAppSettings(dispatch, getState);
      console.log('pauseSong->');
      try {
        try {
          await spotifyUtils.pauseTrack();
        } catch (error) {}

        // let nowPlaying = getState().songsReducer?.nowPlaying;

        const currentState = await dispatch(getSpotifyPlaybackState());
        console.log('currentState 2', currentState);
        if (currentState === '') {
          dispatch(setToastAlert({ status: 100 }, 'error'));
          reject();
        } else {
          resolve(currentState);
        }
      } catch (error) {
        console.log('pauseTrack retVal Error', error);
        dispatch(setToastAlert(error, 'error'));
        reject(error);
      }

      // .then(() => {
      //   console.log('spotifyUtils.pauseTrack retVal');
      //   resolve();
      // })
      // .catch(async (err) => {
      //   try {
      //     const currentState = await dispatch(getSpotifyPlaybackState());
      //     console.log('currentState 2', currentState);
      //     if (currentState === '') {
      //       dispatch(setToastAlert({ status: 100 }, 'error'));
      //       reject();
      //     } else {
      //       resolve(currentState);
      //     }
      //   } catch (error) {
      //     console.log('pauseTrack retVal Error', error);
      //     dispatch(setToastAlert(error, 'error'));
      //     reject(error);
      //   }
      //   // console.log('pauseTrack retVal Error', err);
      //   // reject(err);
      // });
    });
  };
};
export const deleteEventInfo = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: DELETE_CURRENT_EVENT,
    });
  };
};
export const getEventInfo = (eventCode, eventName) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      const url =
        'https://xo615nbxgj.execute-api.us-east-1.amazonaws.com/v1/songrequest';
      const ApiParams = {
        msg: 'GET_EVENT_INFO',
        eventCode: eventCode,
        eventName: eventName,
      };
      const params = {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(ApiParams),
        method: 'POST',
      };

      console.log('getEventInfo params', params);
      try {
        await fetch(url, params).then(async (apiResponse) => {
          if (apiResponse && apiResponse.ok) {
            apiResponse.json().then(async (json) => {
              console.log('getEventInfo fetch response ----> ', json);

              if (json && json.length > 0) {
                const retValObj = json[0];
                // const eventInfoObj = {
                //   id: retValObj.id,
                //   eventCode: retValObj.eventCode,
                //   companyCode: retValObj.companyCode,
                //   apiVersion: retValObj.apiVersion,
                //   djName: retValObj.djName,
                //   hostName: retValObj?.hostName,
                //   pinCode: retValObj.pinCode,
                //   eventName: retValObj.eventName,
                //   // companyName: retValObj.companyName,
                //   // address: retValObj.address,
                //   eventSettings: retValObj.eventSettings,
                //   installationId: retValObj?.installationId,
                //   userId: retValObj?.userId,
                //   createdAt: retValObj?.createdAt,
                //   updatedAt: retValObj?.updatedAt,
                // };
                console.log('eventInfoObj::1', retValObj);
                myUtils.removeEmptyOrNull(retValObj);
                retValObj.spotifySession = JSON.parse(retValObj.spotifySession);
                console.log('eventInfoObj::2', retValObj);
                // console.log(
                //   'jsonObjObjStr',
                //   JSON.parse(retValObj.spotifySession)
                // );
                // const jsonObj = JSON.parse(JSON.stringify(retValObj));
                // console.log('jsonObj', jsonObj);
                dispatch({
                  type: SAVE_APP_SETTINGS,
                  appSettings: { currentEvent: retValObj },
                });
                // await saveSettings(eventInfoObj);
                // dispatch(appActions.saveSettings(eventInfoObj));
                resolve(retValObj);
              } else resolve();
            });
          } else {
            apiResponse.json().then((json) => {
              console.log('sr response ----> ', json);
              console.log('response NOT OK', apiResponse);
              reject();
            });
          }
        });
      } catch (error) {
        console.log('response error', error);
        reject();
      }
    });
  };
};

export const checkIfNameIsTaken = (userName) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      const url =
        'https://xo615nbxgj.execute-api.us-east-1.amazonaws.com/v1/songrequest';
      const ApiParams = {
        msg: 'CHECK_IF_NAME_IS_TAKEN',
        userName: userName,
      };
      const params = {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(ApiParams),
        method: 'POST',
      };

      console.log('checkIfNameIsTaken params', params);
      try {
        await fetch(url, params).then(async (apiResponse) => {
          if (apiResponse && apiResponse.ok) {
            apiResponse.json().then(async (json) => {
              console.log('checkIfNameIsTaken fetch response ----> ', json);

              resolve(json);
            });
          } else {
            apiResponse.json().then((json) => {
              console.log('sr response ----> ', json);
              console.log('response NOT OK', apiResponse);
              reject();
            });
          }
        });
      } catch (error) {
        console.log('response error', error);
        reject();
      }
    });
  };
};

export const getAppSettingsLocally = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log(
          'getState().appReducer?.appSettings',
          getState().appReducer?.appSettings
        );
        let appSettings = getState().appReducer?.appSettings;
        resolve(appSettings);
      } catch (err) {
        console.log(err);
        console.log('error fetching appsetiings');
        reject(err);
      }
    });
  };
};

export const getAppSettings = (eventCode) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log('getAppSettings', eventCode);
        // const getCompanyFromGraphQl = await API.graphql(
        //   graphqlOperation(customQueries.getCompany, {
        //     id: eventCode,
        //     authMode: 'AWS_IAM', //"AMAZON_COGNITO_USER_POOLS",
        //   })
        // );
        // console.log('getCompanyFromGraphQl---', getCompanyFromGraphQl);
        // const result = getCompanyFromGraphQl?.data?.getCompany;
        // console.log('appSettings_', result?.appSettings);

        // dispatch({
        //   type: SAVE_APP_SETTINGS,
        //   appSettings: result.appSettings ? result.appSettings : {},
        // });

        // resolve(result.appSettings);
      } catch (err) {
        console.log(err);
        console.log('error fetching appsetiings');
        reject(err);
      }
    });
  };
};
