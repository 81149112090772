import AsyncStorage from '@react-native-async-storage/async-storage';
import * as myUtils from '../../utils/myUtils';
import {
  // SHOW_CONFIRM_DLG,
  // HIDE_CONFIRM_DLG,
  SAVE_APP_SETTINGS,
  SAVE_USER,
  DELETE_USER,
  SET_AUTH_STATUS,
  UPDATE_TOAST,
  SET_QRCODE_MODAL,
  SAVE_EVENT,
  UPDATE_EVENT,
  SET_EVENTS,
  DELETE_EVENT,
  DELETE_CURRENT_EVENT,
  SET_ISLOADING_EVENTS,
  SET_IS_SYNC_PENING,
  SET_NETWORK_STATUS,
  ADD_HISTORY,
  SET_HISTORY,
  INC_NAME_CHANGED_COUNT,
} from '../actions/appActions';

const clone = require('rfdc')();

const initialAppState = {
  user: null,
  events: [],
  guests: [],
  history: [],
  // currentGuest: null,
  authStatus: null,
  appSettings: null,
  toastData: null,
  isQrCodeVisible: false,
  isLoadingEvents: false,
  isSyncPending: false,
  isWithInternet: false,
  nameChangedCount: 0,
};

export default (state = initialAppState, action) => {
  let eventsCopy = clone(state.events);
  let historyCopy = clone(state.history);

  let _appSetting = null;
  let eventIndex;
  switch (action.type) {
    // case SHOW_CONFIRM_DLG:
    //   console.log(SHOW_CONFIRM_DLG);
    //   return {
    //     isConfirmDlgShowing: true,
    //   };
    // case HIDE_CONFIRM_DLG:
    //   return {
    //     isConfirmDlgShowing: false,
    //   };
    case INC_NAME_CHANGED_COUNT:
      console.log('SET_NAME_CHANGED_COUNT', state.nameChangedCount);
      return {
        ...state,
        nameChangedCount: isNaN(state.nameChangedCount)
          ? 1
          : state.nameChangedCount + 1,
      };

    case SET_HISTORY:
      console.log('SET_HISTORY', action.items);
      return {
        ...state,
        history: action.items,
      };

    case ADD_HISTORY:
      historyCopy.unshift(action.history);

      console.log('ADD_HISTORY', historyCopy);
      return {
        ...state,
        history: historyCopy,
      };

    case SET_AUTH_STATUS:
      console.log('SET_AUTH_STATUS', action?.status);
      return {
        ...state,
        authStatus: action?.status,
      };

    case SAVE_USER:
      const userCopy = clone(action?.user);
      myUtils.removeEmptyOrNull(userCopy);
      console.log('SAVE_USER removeEmptyOrNull', userCopy);
      return {
        ...state,
        user: { ...state.user, ...userCopy },
        // user: { ...state.user, ...action?.user },
      };

    case DELETE_USER:
      return {
        ...state,
        user: null,
      };

    case DELETE_CURRENT_EVENT:
      //Update current event
      _appSetting = { currentEvent: null };

      return {
        ...state,
        events: eventsCopy,
        appSettings: { ...state.appSettings, ..._appSetting },
      };

    case SAVE_EVENT:
      // eventsCopy.push(action?.event);
      eventIndex = eventsCopy?.findIndex((item) => item.id === action.event.id);
      // console.log('SAVE_EVENT', eventIndex, eventsCopy);
      if (eventIndex > -1) {
        eventsCopy.splice(eventIndex, 1);
      }

      let newEventCopy = clone(action?.event);
      myUtils.removeEmptyOrNull(newEventCopy);

      //Update event in array of events
      eventsCopy = [newEventCopy, ...eventsCopy];

      //Update current event
      _appSetting = { currentEvent: action.event };

      return {
        ...state,
        events: eventsCopy,
        appSettings: { ...state.appSettings, ..._appSetting },
      };

    case UPDATE_EVENT:
      _appSetting = { currentEvent: action.event };
      return {
        ...state,
        appSettings: { ...state.appSettings, ..._appSetting },
      };

    case SAVE_APP_SETTINGS:
      // let evalData = JSON.stringify(action?.appSettings);

      console.log('SAVE_APP_SETTINGS', state, action?.appSettings);
      // console.log(state);

      let settingsCopy = clone(action?.appSettings);
      myUtils.removeEmptyOrNull(settingsCopy);

      return {
        ...state,
        appSettings: { ...state.appSettings, ...settingsCopy },
      };

    case UPDATE_TOAST:
      // let evalData = JSON.stringify(action?.appSettings);

      // console.log('UPDATE_TOAST', state, action?.toastObj);
      // console.log(state);
      return {
        ...state,
        appSettings: { ...state.appSettings, ...action?.appSettings },
        toastData: action?.toastObj,
      };

    case SET_QRCODE_MODAL:
      // let evalData = JSON.stringify(action?.appSettings);

      // console.log('SET_QRCODE_MODAL', state, action?.isQrCodeVisible);
      // console.log(state);
      return {
        ...state,
        isQrCodeVisible: action?.isQrCodeVisible,
      };

    case SET_EVENTS:
      // console.log('SET_EVENTS', action);
      return {
        ...state,
        events: action?.events,
        isLoadingEvents: action?.isLoadingEvents,
      };

    case DELETE_EVENT:
      eventIndex = eventsCopy?.findIndex((item) => item.id === action.event.id);
      // console.log('DELETE_EVENT', eventIndex, eventsCopy);
      if (eventIndex > -1) {
        eventsCopy.splice(eventIndex, 1);
      }

      _appSetting = { currentEvent: null };
      return {
        ...state,
        events: eventsCopy,
        appSettings: { ...state.appSettings, ..._appSetting },
      };

    case SET_ISLOADING_EVENTS:
      // console.log('SET_ISLOADING_EVENTS', action);
      return {
        ...state,
        isLoadingEvents: action?.isLoadingEvents,
      };

    case SET_IS_SYNC_PENING:
      // console.log('SET_IS_SYNC_PENING', action?.isSyncPending);
      return {
        ...state,
        isSyncPending: action?.isSyncPending,
      };
    case SET_NETWORK_STATUS:
      // console.log('SET_IS_SYNC_PENING', action?.isSyncPending);
      return {
        ...state,
        isWithInternet: action?.isWithInternet,
      };
  }
  return state;
};
