import { StyleSheet, Platform } from 'react-native';
// import { DefaultTheme } from 'react-native-paper';
import Colors from '../constants/colors';
import colors from '../config/colors';
import Constants from 'expo-constants';
import * as myUtils from '../utils/myUtils';
// import {
//   scale,
//   verticalScale,
//   moderateScale,
// } from 'react-native-size-matters/extend';

const globalStyles = {
  subTitleText: {
    fontFamily: 'poppins-semibold',
    textAlign: 'left',
    fontSize: 18,
    color: 'white',
  },
  headlineText: {
    fontFamily: 'poppins-extralight',
    textAlign: 'left',
    fontSize: 36,
    color: 'white',
    textAlign: 'center',
  },
  titleText: {
    fontFamily: 'poppins-semibold',
    textAlign: 'left',
    fontSize: 26,
    color: 'white',
  },
  modalContainer: {
    position: 'absolute',
    top: -1,
    left: 0,
    height: '100%',
    width: '100%',
    backgroundColor: myUtils.applyAlpha(colors.defBackgroundColor, 0.9),
    justifyContent: 'center',
    zIndex: 99,
  },
  tagContainer: (params) => ({
    // height: 'auto',
    paddingHorizontal: 8,
    borderRadius: 25,
    marginRight: 8,
    backgroundColor: params.backgroundColor,
  }),
  tagText: {
    fontSize: 12,
    color: 'black',
    fontFamily: 'poppins-semibold',
  },
  text1: {
    fontSize: 14,
    // textAlign: 'center',
    color: 'white',
    fontFamily: 'poppins-semibold',
  },
  text2: {
    fontSize: 13,
    color: 'white',
    // textAlign: 'center',
    fontFamily: 'poppins-light',
  },
  flatListItemSemiRound: {
    // flex: 1,
    // // backgroundColor: 'white',
    // // backgroundColor: Colors.defBackgroundColor,
    // marginHorizontal: 20,
    // marginVertical: 10,
    // padding: 20,
    // // borderRadius: 10,
    // flexDirection: 'row',
    // overflow: 'hidden',

    alignItems: 'center',

    flex: 1,
    flexDirection: 'row',
    // justifyContent: 'space-between',
    // paddingLeft: 15,
  },
  flatListItemRound: {
    backgroundColor: 'white',
    marginHorizontal: 20,
    marginVertical: 10,
    padding: 20,

    height: 240, //verticalScale(240),
    borderRadius: 60,
    flexDirection: 'row',
    alignSelf: 'center',
  },
  buttonFullWidth: {
    // flex: 1,
    width: '100%',
    // height: '100%',
    backgroundColor: 'red',
    // zIndex: 9999,
  },
  errorText: {
    fontSize: 18,
    // textAlign: 'center',
    color: '#FF007F',
    fontFamily: 'poppins-regular',
  },
};
const globalStyles_ = StyleSheet.create({
  row1: {
    // flex: 1,
    // borderRightWidth: 1,
    // borderBottomWidth: 1,
    // borderColor: '#C4C4C4',
    // padding: 5,
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  row2: {
    // flex: 1,
    // borderRightWidth: 1,
    // borderBottomWidth: 1,
    // borderColor: '#C4C4C4',
    // padding: 5,
    marginRight: 10,
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  searchTextBold: {
    fontSize: 18,
    // textAlign: 'center',
    color: '#616161',
    fontFamily: 'poppins-semibold',
  },

  text1: {
    fontSize: 18,
    // textAlign: 'center',
    color: 'white',
    fontFamily: 'poppins-regular',
  },
  text2: {
    fontSize: 16,
    color: 'white',
    // textAlign: 'center',
    fontFamily: 'poppins-light',
  },
  largeText: {
    fontSize: 22,
    color: 'rgba(255, 255, 255, 0.7)',
    textAlign: 'right',
    fontFamily: 'poppins-regular',
    zIndex: 999,
  },
  screen: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    // backgroundColor: Colors.defBackgroundColor,
  },
  container: {
    // flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    // backgroundColor: "blue",
    width: 600,
    maxWidth: '80%',
    // padding: 140,
  },
  formContainer: {
    // flex: 1,
    justifyContent: 'center',
    // alignItems: 'flex-start',
    // height: 800,
    width: '60%',
    maxWidth: 800,
    marginTop: 20,
    // marginBottom: 100,
    // backgroundColor: 'red',
  },
  containerWithTopMargin: {
    flex: 1,
    marginTop: Constants.statusBarHeight,
    justifyContent: 'center',
  },
  containerCenter: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 300,
    // backgroundCefolor: "red",
  },
  image: {
    height: 300,
    width: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // marginVertical: 20,
    resizeMode: 'contain',
    //resizeMethod: "auto",
  },
  switchContainer: {
    // backgroundColor: "red",
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 12,
    // backgroundColor: "red",
    width: 110,
  },
  centeredText: {
    alignSelf: 'center',
    marginVertical: 10,
  },
  subTitleText: {
    fontFamily: 'poppins-semibold',
    textAlign: 'left',
    fontSize: 18,
    color: 'white',
  },
  titleText: {
    fontFamily: 'poppins-semibold',
    textAlign: 'left',
    fontSize: 26,
    color: 'white',
  },
  headlineText: {
    fontFamily: 'poppins-extralight',
    textAlign: 'left',
    fontSize: 36,
    color: 'white',
    textAlign: 'center',
  },
  inputField: {
    // borderWidth: 1,
    // borderColor: "#ddd",
    // padding: 10,
    // fontSize: 18,
    // ...Platform.select({
    //   ios: {
    //     //outlineWidth: 0,
    //   },
    //   android: {
    //     //outlineWidth: 0,
    //   },
    //   web: {
    //     // other platforms, web for example
    //     // outlineColor: "rgb(30,212,40)",
    //     outlineWidth: 10,
    //     outlineOffset: 100,
    //     // padding: 100,
    //     outlineColor: "blue",
    //     colors: { primary: "green", underlineColor: "transparent" },
    //   },
    // }),
    // margin: 50,
    // borderRadius: 6,
    // height: 20,
    // verticalMargin: 10,
    // margintop: 0,
    // paddingBottom: 5,
    // backgroundColor: 'red',
    // verticalPadding: 100,
  },
  inputLabel: {
    padding: 24,
  },
  pickerField: {
    marginTop: 10,
    // marginBottom: 20,
    width: '100%',
    fontSize: 16,
    height: 56,
    borderWidth: 1,
    backgroundColor: 'rgb(246, 246, 246)',
    padding: 10,
    borderColor: 'rgba(0, 0, 0, 0.54)',
    borderRadius: 5,
  },
  buttonText: {
    color: Colors.defBackgroundColor,
  },
  roundButton: {
    // flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    // margin: 10,
    // width: 50,
    // height: 50,
    borderRadius: 25,
    paddingBottom: 15,
    // fontFamily: "poppins-light",
    // radius: 8,

    //height: 32,
  },
  button: {
    margin: 10,
    // fontFamily: "poppins-light",
    //maxWidth: "80%",
    //height: 32,
  },
  medUndelinedText: {
    fontSize: 16,
    fontFamily: 'poppins-regular',
    textDecorationLine: 'underline',
    fontWeight: 'bold',
    // marginBottom: -2,
    // marginRight: 10,
    color: '#6200EE',
    textAlign: 'center',
  },
  buttonContent: {
    height: 60,
    width: 300,
  },
  buttonFullWidth: {
    // width: '100%',
    // width: null,
    // padding: 15,
    // height: '100%',
    // borderRadius: 40,
    // alignSelf: 'center',
    // justifyContent: 'center',
    // alignItems: 'center',
  },

  buttonFullWidth2: {
    // margin: 10,
    borderRadius: 140,
    width: '100%',
    marginVertical: 40,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    // fontFamily: "poppins-Helvetica",
    // maxWidth: "80%",
    height: 70,
  },
  buttonContentStyle: {
    flex: 1,
    color: 'red',
    alignSelf: 'stretch',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'poppins-light',
  },

  niceButton: {
    marginTop: 30,
    borderRadius: 60,
    height: 60,
    backgroundColor: '#3557b7',
    justifyContent: 'center',
    minWidth: 300,
    maxWidth: 450,
    marginBottom: 100,
  },
  niceButtonText: {
    textAlign: 'center',
    fontSize: 20,
    color: '#fff',
    fontWeight: '700',
  },

  selectionList: {
    // backgroundColor: 'white',
    // marginHorizontal: 20,
    // marginVertical: 1,
    // padding: 30,
    // width: '100%',
    // height: verticalScale(100),
    // borderRadius: 60,
    // flexDirection: 'row',
    // textAlign: 'center',
    // alignSelf: 'center',
  },
});

// const themePaper = {
//   ...DefaultTheme,
//   mode: 'exact',
//   // flex: 1,
//   // flexDirection: "column",
//   // alignItems: "center",
//   // justifyContent: "center",
//   roundness: 5,
//   // animation: {
//   //   scale: 0.5,
//   // },
//   // roundness: 2,
//   fonts: {
//     regular: 'poppins-regular',
//     medium: 'poppins-medium',
//     light: 'poppins-light',
//     thin: 'poppins-extralight',
//   },
//   colors: {
//     ...DefaultTheme.colors,
//     primary: Colors.main, //'#1db954', //'#4D4DFF', //'#0450E7', //'#004EFF', //'#195EFF',
//     accent: '#b3b3b3',
//     backdrop: '#212121',
//     background: 'white',
//     disabled: '#535353',
//     error: '#B00020',
//     notification: '#f50057',
//     onBackground: '#000000',
//     onSurface: '#000000',
//     placeholder: 'rgba(0, 0, 0, 0.54)',
//     surface: '#ffffff',
//     text: '#000000',
//   },
//   animation: {
//     scale: 1.0,
//   },
// };

export { globalStyles };
