// @ts-check
import React, { useEffect, useRef, useState } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';

import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  InteractionManager,
} from 'react-native';
import Screen from '../components/Screen';
// import TopNavigation from '../navigation/TopNavigation';
import Timeline from 'react-native-timeline-flatlist';
import moment, { now } from 'moment';
import AppText from '../components/AppText';
import defaultStyles from '../config/styles';
import { globalStyles } from '../styles/global';
import colors from '../config/colors';
import { color } from 'react-native-reanimated';
import {
  Button,
  FormControl,
  Input,
  Center,
  // Text,
  NativeBaseProvider,
  Box,
  Modal as NBModal,
  VStack,
  HStack,
  Heading,
  Container,
  Icon,
  Circle,
} from 'native-base';
import {
  MaterialCommunityIcons,
  MaterialIcons,
  FontAwesome,
  Ionicons,
} from '@expo/vector-icons';

const HistoryScreen = (props) => {
  const historyState = useSelector(
    //@ts-ignore
    (state) => state?.appReducer?.history
  );
  const [history, setHistory] = useState(historyState);
  useEffect(() => {
    setHistory(historyState);
  }, [historyState]);

  const renderCircle = (rowData, sectionID, rowID) => {
    //Type: (addded)track, (addded)playlist, playback, (joined)guest, (sent)request, upvote
    var type = rowData.type;
    if (type == 'playlist')
      type = (
        <Icon
          size={6}
          mt={1}
          as={MaterialCommunityIcons}
          name='playlist-music'
          _dark={{
            color: colors.main,
          }}
        />
      );
    else if (type == 'guest')
      type = (
        <Icon
          size={6}
          as={MaterialIcons}
          name='person-add-alt-1'
          _dark={{
            color: colors.main,
          }}
        />
      );
    else if (type == 'playback')
      type = (
        <Icon
          size={6}
          as={Ionicons}
          name='play-sharp'
          _dark={{
            color: colors.main,
          }}
        />
      );
    else if (type == 'upvote')
      type = (
        <Icon
          size={6}
          as={MaterialCommunityIcons}
          name='account-music'
          _dark={{
            color: colors.main,
          }}
        />
      );
    //if (type == 'request')
    else
      type = (
        <Icon
          size={6}
          as={MaterialCommunityIcons}
          name='music-note-plus'
          _dark={{
            color: colors.main,
          }}
        />
      );
    //if (type == 'track')
    // else
    //   type = (
    //     <Icon
    //       size={6}
    //       as={Ionicons}
    //       // name='ios-musical-note'
    //       name='musical-note-outline'
    //       _dark={{
    //         color: colors.main,
    //       }}
    //     />
    //   );

    return (
      <View
        style={{
          position: 'absolute',
          alignSelf: 'flex-start',
          left: 5,
          paddingVertical: 10,
          backgroundColor: colors.defBackgroundColor,
        }}
      >
        {type}
      </View>
    );
  };

  const renderDetail = (rowData, sectionID, rowID) => {
    // console.log('renderDetail rowData', rowData);
    const time = moment(
      rowData.updatedAt ? rowData.updatedAt : rowData.postedAt
    ).fromNow();

    let timePosted = (
      <View style={styles.timeContainer}>
        <Text style={[styles.time]}>{time}</Text>
      </View>
    );
    let title = (
      <Text style={[styles.title]}>
        {rowData.subject + rowData.verb + rowData.object + rowData.punctuation}
      </Text>
    );
    var desc = null;
    if (rowData.description)
      // && rowData.imageUrl)
      desc = (
        <View style={styles.descriptionContainer}>
          {/* <Image source={{ uri: rowData.imageUrl }} style={styles.image} /> */}
          <Text style={[styles.description]}>{rowData.description}</Text>
        </View>
      );

    return (
      <View style={{ flex: 1 }}>
        {timePosted}
        {title}
        {desc}
      </View>
    );
  };

  return (
    <Screen>
      <View
        style={[
          defaultStyles.controlContainer,
          { flexDirection: 'row', alignItems: 'center', marginBottom: 20 },
        ]}
      >
        <TouchableOpacity
          onPress={() => {
            InteractionManager.runAfterInteractions(() => {
              props.navigation.pop();
            });
          }}
        >
          <MaterialCommunityIcons
            name='keyboard-backspace'
            size={28}
            color='white'
          />
        </TouchableOpacity>
        <AppText
          selectable={false}
          customStyle={{
            paddingTop: 10,
            paddingLeft: 20,
          }}
          controlStyle='title'
        >
          Activities
        </AppText>
      </View>
      {/* <Box alignItems='center' mb={5} pl={10} pr={2}>
        <Input mx='3' placeholder='Input' w='100%' />
      </Box> */}
      <Timeline
        // style={styles.list}
        data={history}
        // circleSize={20}
        // circleColor='rgb(45,156,219)'
        innerCircle={'dot'}
        lineWidth={0}
        lineColor={colors.main}
        // circleColor={colors.main}
        dotColor={colors.OxfordBlue}
        // timeContainerStyle={{ minWidth: 52, marginTop: 0 }}
        // timeStyle={{
        //   textAlign: 'center',
        //   backgroundColor: '#ff9797',
        //   color: 'white',
        //   padding: 5,
        //   borderRadius: 13,
        // }}
        titleStyle={styles.title}
        // descriptionStyle={{ color: 'gray' }}
        showTime={false}
        // renderFullLine={true}
        // rowContainerStyle={{ backgroundColor: 'red' }}
        // options={{
        //   style: { paddingTop: 5 },
        // }}
        eventContainerStyle={
          {
            // backgroundColor: 'red',
            // marginTop: -15,
            // marginBottom: 20,
          }
        }
        isUsingFlatlist={true}
        renderDetail={renderDetail}
        renderCircle={renderCircle}
        // renderTime={renderTime}
        detailContainerStyle={{
          marginBottom: 20,
          marginRight: 10,
          paddingLeft: 15,
          paddingRight: 5,
          backgroundColor: colors.Independence,
          borderRadius: 4,
        }}
      />
    </Screen>
  );
};

export const screenOptions = (navData) => {
  return {
    headerShown: false,
    headerTitle: 'Activities',
  };
};

const styles = StyleSheet.create({
  timeContainer: {
    // justifyContent: 'center',
    // alignItems: 'center',
    alignSelf: 'flex-start',
    // padding: 5,
    // paddingHorizontal: 15,
    // borderRadius: 50,
    // backgroundColor: 'grey',
  },
  container: {
    flex: 1,
    padding: 20,
    paddingTop: 65,
    backgroundColor: 'white',
  },
  list: {
    flex: 1,
    marginTop: 20,
    // backgroundColor: 'red',
  },
  time: {
    fontSize: 12,
    fontFamily: 'poppins-light',
    color: colors.medium,
  },
  title: {
    fontSize: 14,
    fontFamily: 'poppins-medium',
    fontWeight: 'bold',
    color: 'white',
  },

  description: {
    fontSize: 12,
    fontFamily: 'poppins-light',
    color: 'white',
  },
  descriptionContainer: {
    flexDirection: 'row',
    paddingRight: 50,
  },
  image: {
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  textDescription: {
    marginLeft: 10,
    color: 'gray',
  },
});

export default HistoryScreen;
