import moment, { now } from 'moment';
var Spotify = require('spotify-web-api-js');
var spotifyApi = new Spotify();

export const createSpotifyPlaylist = (userId, event) => {
  return new Promise(async (resolve, reject) => {
    // console.log('createSpotifyPlaylist', userId, event);
    spotifyApi
      .createPlaylist(userId, {
        name: 'SongRequest: ' + event.eventName + ' (' + event.eventCode + ')',
        public: true,
        // collaborative: true,
        description: 'WARNING: DO NOT MODIFY THIS PLAYLIST',
      })
      .then(
        async function (ret) {
          // const playbackState1 = await getMyCurrentPlaybackState();
          // console.log('createSpotifyPlaylist', ret);
          resolve(ret);
        },
        function (err) {
          //if the user making the request is non-premium, a 403 FORBIDDEN response code will be returned
          const errObj = JSON.parse(err._response);
          console.log(
            'Something went wrong in pauseTrack command!!!!',
            err?.status,
            errObj.error
          );

          reject(errObj.error);
        }
      );
  });
};
export const addTracksToSpotifyPlaylist = (playlistId, uris) => {
  console.log('addTracksToSpotifyPlaylist', uris);
  return new Promise(async (resolve, reject) => {
    spotifyApi.addTracksToPlaylist(playlistId, uris).then(
      async function (snapshot) {
        console.log('Successfully added tracks to playlist', snapshot);

        // const retTracks = await getSpotifyPlaylistTracks(playlistId);
        // console.log('getSpotifyPlaylistTracks-->', retTracks);
        resolve(snapshot.snapshot_id);
      },
      function (err) {
        //if the user making the request is non-premium, a 403 FORBIDDEN response code will be returned
        const errObj = JSON.parse(err._response);
        console.log(
          'Something went wrong in addTracksToSpotifyPlaylist command!!!!',
          err.status,
          errObj.error
        );

        reject(errObj.error);
      }
    );
  });
};

export const removeTracksFromSpotifyPlaylist = (
  playlistId,
  uris,
  snapshot_id
) => {
  return new Promise(async (resolve, reject) => {
    console.log('removeTracksFromSpotifyPlaylist snapshot_id', snapshot_id);
    if (snapshot_id) {
      spotifyApi
        .removeTracksFromPlaylistWithSnapshotId(playlistId, uris, snapshot_id)
        .then(
          async function (ret) {
            console.log(
              'Successfully removed tracks to playlist with snapshot_id',
              ret
            );

            // const retTracks = await getSpotifyPlaylistTracks(playlistId);
            // console.log('getSpotifyPlaylistTracks-->', retTracks);
            resolve();
          },
          function (err) {
            //if the user making the request is non-premium, a 403 FORBIDDEN response code will be returned
            const errObj = JSON.parse(err._response);
            console.log(
              'Something went wrong in removeTracksFromPlaylistWithSnapshotId command!',
              err.status,
              errObj.error
            );

            reject(errObj.error);
          }
        );
    } else {
      spotifyApi.removeTracksFromPlaylist(playlistId, uris).then(
        async function (ret) {
          console.log('Successfully removed tracks to playlist', ret);

          // const retTracks = await getSpotifyPlaylistTracks(playlistId);
          // console.log('getSpotifyPlaylistTracks-->', retTracks);
          resolve();
        },
        function (err) {
          //if the user making the request is non-premium, a 403 FORBIDDEN response code will be returned
          const errObj = JSON.parse(err._response);
          console.log(
            'Something went wrong in removeTracksFromPlaylist command!',
            err.status,
            errObj.error
          );

          reject(errObj.error);
        }
      );
    }
  });
};

// getter for the playlist tracks
const getPlaylistTracks = (
  playlistID,
  limit = 100,
  offset = 0,
  fields = 'tracks(offset,total,items(track(uri,name)))'
) => {
  return new Promise(async (resolve, reject) => {
    // console.log('getPlaylistTracks->', playlistID);
    spotifyApi
      .getPlaylistTracks(playlistID, {
        offset,
        limit,
        fields,
      })
      .then((data) => {
        console.log('getPlaylistTracks', data);
        resolve(data);
      })
      .catch((err) => {
        console.log('[getPlaylistTracks] Something went wrong!', err);
        reject(err);
      });
  });
};

// getter for the playlist tracks
const getPlaylistTracksAllPages = async (
  playlistID,
  limit = 100,
  offset = 0,
  fields = 'limit,offset,total,items(track(uri,name))'
) => {
  let firstPage = await getPlaylistTracks(playlistID, limit, offset, fields);
  console.log('getPlaylistTracksAllPages', firstPage?.total, firstPage);
  let totalPages = Math.ceil((firstPage.total || 1) / limit);

  let allPagesPromise = Array.from(
    new Array(totalPages - 1),
    (_, index) => index + 1
  ).map((pageNumber) =>
    getPlaylistTracks(playlistID, limit, limit * pageNumber, fields).catch(
      (err) => console.error('[getPlaylistAllPages] paged request failed.', err)
    )
  );

  return await Promise.all(allPagesPromise)
    .then((playlists) => {
      playlists.forEach((playlist, idx) => {
        firstPage.items.push(...playlist.items);
      });

      return firstPage;
    })
    .catch((err) => {
      // there was an error
      console.error('[getPlaylistAllPages] Global fetch failed', err);
    });
};

const _extractUriFromTracks = (tracks) => {
  let output = {};

  tracks.items.forEach((item, idx) => {
    output[item.track.uri] = {
      name: item.track.name,
      position: idx,
    };
  });

  return output;
};

const findTrackInPlaylist = async (trackID, playlistID) => {
  let playlistTracks = await getPlaylistTracksAllPages(playlistID);
  let uriTracks = _extractUriFromTracks(playlistTracks);

  return undefined !== uriTracks[trackID] ? uriTracks[trackID] : -1;
};

export const reorderTracksInSpotifyPlaylist = async (
  song,
  playlistID,
  rangeLength = 1,
  rangeStart = 0, //From
  insertBefore = 0 //To
) => {
  return new Promise(async (resolve, reject) => {
    let options = {
      range_length: rangeLength,
    };
    console.log(
      'reorderTracksInSpotifyPlaylist song, playlistID, rangeLength, rangeStart, insertBefore',
      song,
      playlistID,
      rangeLength,
      rangeStart,
      insertBefore
    );

    if (insertBefore > rangeStart) insertBefore += 1;

    // let trackFromPlaylist = await findTrackInPlaylist(song.uri, playlistID);
    console.log(
      'trackFromPlaylist from position to',

      rangeStart,
      // trackFromPlaylist.position, //rangeStart / From
      insertBefore //To
    );
    // if (trackFromPlaylist.position != -1) {
    spotifyApi
      .reorderTracksInPlaylist(
        playlistID,
        rangeStart, //
        // trackFromPlaylist.position, //rangeStart / From
        insertBefore, //insertBefore / To
        options
      )
      .then((data) => {
        console.log('reorderTracksInPlaylist', data);
        resolve();
      })
      .catch((err) => {
        const errObj = JSON.parse(err._response);
        console.log(
          'Something went wrong in reorderTracksInPlaylist command!',
          err.status,
          errObj.error
        );

        reject(errObj.error);
      });
    // }
  });
};

export const deletePlaylist = (playlistId) => {
  return new Promise(async (resolve, reject) => {
    spotifyApi.unfollowPlaylist(playlistId).then(
      async function () {
        console.log('Successfully removed playlist');
        resolve();
      },
      function (err) {
        //if the user making the request is non-premium, a 403 FORBIDDEN response code will be returned
        const errObj = JSON.parse(err._response);
        console.log(
          'Something went wrong in pauseTrack command!!!!',
          err.status,
          errObj.error
        );

        reject(errObj.error);
      }
    );
  });
};
export const pauseTrack = () => {
  return new Promise(async (resolve, reject) => {
    console.log('pauseTrack');
    spotifyApi.pause().then(
      async function (ret) {
        // const playbackState1 = await getMyCurrentPlaybackState();
        console.log('Paused started', ret);
        resolve(ret);
      },
      function (err) {
        //if the user making the request is non-premium, a 403 FORBIDDEN response code will be returned
        const errObj = JSON.parse(err._response);
        console.log(
          'Something went wrong in pauseTrack command!!!!',
          err.status,
          errObj.error
        );

        reject(errObj.error);
      }
    );
  });
};

export const playPlaylist = (playlistId, song) => {
  return new Promise(async (resolve, reject) => {
    const contextUri = 'spotify:playlist:' + playlistId;
    const offset = song ? { uri: song?.uri } : null;
    console.log('playPlaylist', playlistId, contextUri, offset);
    spotifyApi
      .play({
        context_uri: contextUri,
        offset: offset,
      })
      .then(
        async function (ret) {
          // const playbackState1 = await getMyCurrentPlaybackState();
          console.log('Playback started', ret);
          resolve();
        },
        function (err) {
          //if the user making the request is non-premium, a 403 FORBIDDEN response code will be returned
          const errObj = JSON.parse(err._response);
          console.log('Something went wrong!!!!1', err.status, errObj.error);

          reject(errObj.error);
        }
      );
  });
};

export const skipToNext = () => {
  console.log('next');
  return new Promise(async (resolve, reject) => {
    spotifyApi.skipToNext().then(
      async function () {
        console.log('Skip to next ');
        // const playbackState1 = await getCurrentSpotifyPlaybackState();
        console.log('Playback started');
        resolve();
      },
      function (err) {
        //if the user making the request is non-premium, a 403 FORBIDDEN response code will be returned
        console.log('Something went wrong in skipToNext command!', err);
        reject(err);
      }
    );
  });
};
export const playTrack = (song) => {
  return new Promise(async (resolve, reject) => {
    const _uris = song ? { uris: [song.uri] } : null;
    console.log('playTrack _uris', _uris);
    spotifyApi.play(_uris).then(
      async function () {
        // const playbackState1 = await getMyCurrentPlaybackState();
        console.log('Playback started');
        resolve();
      },
      function (err) {
        //if the user making the request is non-premium, a 403 FORBIDDEN response code will be returned
        const errObj = JSON.parse(err._response);
        console.log('Something went wrong!!!!2', err.status, errObj.error);

        reject(errObj.error);
      }
    );
  });
  // spotifyApi
  //   .getMyCurrentPlaybackState()
  //   .then((response) => {
  //     console.log('playTrack getMyCurrentPlaybackState->', response);
  //     return response.device.id;
  //   })
  //   .then((id) => {
  //     console.log(id);
  //     spotifyApi.play({ uri: songId }, id);
  //   });
};

export const addToSpotifyQueue = (song) => {
  return new Promise(async (resolve, reject) => {
    console.log('addToSpotifyQueue song', song.name);
    spotifyApi.queue(song.uri).then(
      async function () {
        // const playbackState1 = await getMyCurrentPlaybackState();
        console.log('Added to queue!');
        resolve(song);
      },
      function (err) {
        //if the user making the request is non-premium, a 403 FORBIDDEN response code will be returned
        const errObj = JSON.parse(err._response);
        console.log('Something went wrong!!!!3', err.status, errObj.error);

        reject(errObj.error);
      }
    );
  });
};
export const setErrorMsg = (errorObj) => {
  let lastSpotifyError;
  if (errorObj.status === 404) {
    lastSpotifyError = {
      title: 'No playback device found',
      description: 'Make sure Spotify is playing some music, then try again.',
    };
  } else if (errorObj.status === 429) {
    lastSpotifyError = {
      title: 'Server is busy',
      description: 'Unable to process request. Please try again later.',
    };
  } else if (errorObj.status === 401) {
    lastSpotifyError = {
      title: 'Session Expired',
      description:
        'Please try again later. If this failed, try signing out, then sign back in.',
    };
  } else if (errorObj.status === 100) {
    lastSpotifyError = {
      title: 'Spotify not playing anything',
      description: 'Make sure Spotify is playing some music, then try again.',
    };
  } else {
    lastSpotifyError = {
      title: 'Unknown Error',
      description:
        'Please try again later. If this failed, try signing out, then sign back in.',
    };
  }
  return lastSpotifyError;
};

const currentPlayingTrack = () => {
  console.log('currentPlayingTrack');
  return new Promise((resolve, reject) => {
    spotifyApi.getMyCurrentPlayingTrack().then(
      function (data) {
        if (data && data.body && data.body.item) {
          console.log('Now playing: ' + data.body.item.name);
          resolve({
            track: data.body.item.name,
            album: data.body.item.album.name,
            artist: data.body.item.artists[0].name,
          });
        }
      },
      function (err) {
        console.log('Something went wrong!', err);
        reject(err);
      }
    );
  });
};

export const getSpotifyPlaylists = () => {
  //  Retrieve featured playlists

  return new Promise((resolve, reject) => {
    spotifyApi
      .getUserPlaylists({
        limit: 50,
        offset: 0,
        country: 'US',
        locale: 'en_US',
      })
      .then(
        function (data) {
          console.log('getFeaturedPlaylists', data);
          const playlists = data.items.map((item) => {
            return {
              id: item.id,
              name: item.name,
              description: item.description,
              uri: item.uri,
              image:
                Array.isArray(item.images) && item.images.length > 0
                  ? item.images[0].url
                  : item?.image?.url,
            };
          });
          resolve(playlists);
        },
        function (err) {
          console.log('getFeaturedPlaylists: Something went wrong!', err);
          reject(err);
        }
      );
  });
};

export const getSpotifyPlaylistTracks = (playlistId, params) => {
  return new Promise((resolve, reject) => {
    spotifyApi.getPlaylistTracks(playlistId, params).then(
      function (data) {
        console.log('getPlaylistTracks 222', data);
        const tracks = data.items.map((item) => {
          return {
            ...item.track,
            // description: item.description,
            // uri: item.uri,
            // image:
            //   Array.isArray(item.images) && item.images.length > 0
            //     ? item.images[0].url
            //     : item?.image?.url,
          };
        });
        resolve(tracks);
      },
      function (err) {
        console.log('getFeaturedPlaylists: Something went wrong!', err);
        reject(err);
      }
    );
  });
};

export const checkSpotifyToken = async (appSettings) => {
  const { spotifySession } = appSettings.currentEvent;
  console.log(
    'spotify.expiresAt',
    moment().utc().format(),
    spotifySession.expiresAt
  );
  const isExpired = spotifySession.expiresAt
    ? moment().utc().isAfter(spotifySession.expiresAt)
    : true;
  console.log(
    'checkSpotifyToken isExpired',
    appSettings.currentEvent,
    isExpired
  );

  if (isExpired) {
    try {
      const newSpotifySession = await getSpotifyAccessToken(
        appSettings.currentEvent.id
      );
      console.log('checkSpotifyToken new spotifySession', newSpotifySession);
      console.log('spotifyApi.setAccessToken 1', newSpotifySession.token);
      // newSpotifySession.expiresAt = moment()
      //   .add(newSpotifySession.expiresIn, 'seconds')
      //   .utc()
      //   .format();
      spotifyApi.setAccessToken(newSpotifySession.token);
      return newSpotifySession;
    } catch (error) {
      console.log('getSpotifyAccessToken ERROR', error);
      return null;
    }
  } else {
    console.log('spotifyApi.setAccessToken 2', spotifySession.token);
    spotifyApi.setAccessToken(spotifySession.token);
    return spotifySession;
  }
};
export const getMyDevices = () => {
  console.log('getMyDevices');
  return new Promise((resolve, reject) => {
    // if (spotifyToken === null) return [];
    // Get a User's Available Devices
    // spotifyApi.setAccessToken(spotifyToken);
    spotifyApi.getMyDevices().then(
      function (data) {
        // console.log('spotifyApi.getMyDevices', data);
        let availableDevices = data.devices;
        resolve(availableDevices);
      },
      function (err) {
        console.log('getMyDevices: Something went wrong!', err);
        reject(err);
      }
    );
  });
};
//37i9dQZF1DXcZQSjptOQtk
//46WrQrbyATvebK8VC8sEvP
export const getCurrentSpotifyPlaybackState = () => {
  return new Promise((resolve, reject) => {
    // Get Information About The User's Current Playback State
    spotifyApi.getMyCurrentPlaybackState().then(
      function (data) {
        console.log('getMyCurrentPlaybackState', data);
        // Output items
        if (data && data?.is_playing) {
          console.log('User is currently playing something!', data.item);
        } else {
          console.log(
            'User is not playing anything, or doing so in private.',
            data
          );
        }
        resolve(data);
      },
      function (err) {
        console.log('getMyCurrentPlaybackState: Something went wrong!', err);
        reject(err);
      }
    );
  });
};

export const connectToSpeaker = async (deviceId) => {
  console.log('connectToSpeaker', deviceId);
  // Get a User's Available Devices
  const devices = await getMyDevices();
  console.log('devices', devices);
  const device =
    devices.find((d) => d.id === deviceId) ||
    devices.find((d) => d.type !== 'Speaker');

  return new Promise((resolve, reject) => {
    if (device && device.is_active) resolve(device);
    // spotifyApi.setAccessToken(spotifyToken);
    spotifyApi.transferMyPlayback([device.id]).then(
      async function () {
        console.log('Transfering playback to ' + device.id);
        // await getMyCurrentPlaybackState();
        resolve(device);
      },
      function (err) {
        //if the user making the request is non-premium, a 403 FORBIDDEN response code will be returned
        console.log('transferMyPlayback: Something went wrong!', err);
        reject(err);
      }
    );
  });
};

const getMyRecentlyPlayedTracks = () => {
  console.log('getMyRecentlyPlayedTracks');
  return new Promise((resolve, reject) => {
    spotifyApi
      .getMyRecentlyPlayedTracks({
        limit: 50,
      })
      .then(
        function (data) {
          // Output items
          console.log(
            'Your 20 most recently played tracks are:',
            data.body.items.length
          );
          resolve(
            Object.values(
              data.body.items
                .map((track) => {
                  return {
                    name: track.track.name,
                    album: track.track.album.name,
                    artist: track.track.album.artists[0].name,
                    image: track.track.album.images[0],
                  };
                })
                .reduce((a, v) => {
                  a[v.name] = v;
                  return a;
                }, {})
            ).slice(0, 4)
          );
        },
        function (err) {
          console.log('Something went wrong!', err);
          reject(err);
        }
      );
  });
};
const setVolume = async (volume) => {
  console.log('setVolume', volume);
  const deviceId = await secrets('DEVICE_ID');
  spotifyApi
    .setVolume(volume, {
      device_id: deviceId,
    })
    .then(
      function () {
        console.log('Volume', volume);
      },
      function (err) {
        //if the user making the request is non-premium, a 403 FORBIDDEN response code will be returned
        console.log('Something went wrong!', err);
      }
    );
};

const updateDevices = async (mainWindow) => {
  const devices = await getMyDevices();
  mainWindow.webContents.send('fromMain_Settings', devices);
  return devices.find((d, i) => i === 0);
};

const previous = () => {
  console.log('next');
  return new Promise(async (resolve, reject) => {
    const deviceId = await secrets('DEVICE_ID');
    spotifyApi.skipToPrevious({ device_id: deviceId }).then(
      async function () {
        console.log('Skip to next ');
        const playbackState1 = await getMyCurrentPlaybackState();
        console.log('Playback started');
        resolve(playbackState1);
      },
      function (err) {
        //if the user making the request is non-premium, a 403 FORBIDDEN response code will be returned
        console.log('Something went wrong!', err);
        reject(err);
      }
    );
  });
};

export const getSpotifyAccessToken = async (eventId) => {
  return new Promise(async (resolve, reject) => {
    const url =
      'https://xo615nbxgj.execute-api.us-east-1.amazonaws.com/v1/songrequest';
    const ApiParams = {
      msg: 'GET_SPOTIFY_ACCESS_TOKEN',
      eventId: eventId,
    };
    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(ApiParams),
      method: 'POST',
      // mode: 'no-cors',
    };

    console.log('getAccessToken-getAccessToken----2a', params);
    try {
      await fetch(url, params).then((apiResponse) => {
        if (apiResponse && apiResponse.ok) {
          apiResponse.json().then((json) => {
            console.log(
              'GET_SPOTIFY_ACCESS_TOKEN ok response ----> ',
              eventId,
              json
            );
            resolve(JSON.parse(json));
          });
        } else {
          apiResponse.json().then((json) => {
            console.log(
              'GET_SPOTIFY_ACCESS_TOKEN error response ----> ',
              eventId,
              json
            );
            // resolve(json.accessToken);
            console.log('response NOT OK', apiResponse);
            reject();
          });
        }
      });
    } catch (error) {
      console.log('response error', error);
      reject();
    }
  });
};
