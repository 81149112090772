import { LayoutAnimation } from 'react-native';
import moment, { now } from 'moment';
import { firstBy } from 'thenby';
import {
  CLEAR_SEARCH_RESULTS,
  SET_IS_IN_PROGRESS,
  SET_SEARCH_RESULTS,
  SET_QUEUE,
  SET_NOW_PLAYING,
  SET_NEXT_TO_PLAY,
  ADD_TO_QUEUE,
  ADD_USER_VOTED_SONG,
  ADD_TRACKS_TO_QUEUE,
  CLEAR_QUEUE,
  MARK_SONG_UNAVAILABLE,
  REMOVE_SONG_FROM_QUEUE,
  MARK_SONG_AS_PLAYED,
  UPDATE_SONG,
  SET_PLAYLISTS,
  ADD_PLAYLIST,
  SET_ISLOADING_QUEUES,
  SET_SNAPSHOT,
  SORT_FAV_LIST,
  SET_USER_FAV_SONGS,
  DECREMENT_USER_REQUEST_COUNT,
  DECREMENT_USER_VOTE_COUNT,
  SET_TOTAL_SONG_UPVOTES,
  SET_TOTAL_SONG_REQUESTS,

  // ADD_LAST_VOTED_SONG_ID,
  // REMOVE_LAST_VOTED_SONG_ID,
} from '../actions/songActions';
const clone = require('rfdc')();
import * as myUtils from '../../utils/myUtils';
const initialStates = {
  nowPlaying: null,
  nextToPlayUri: null,
  nextToPlayPostedAt: null,
  queue: [],
  queueSongUris: [],
  blockedSongs: [],
  playedSongs: [],
  userRequestedSongUris: [],
  userVotedSongUris: [],
  searchResults: [],
  prevSearchText: '',
  isInProgress: false,
  lastMessage: '',
  playlists: [],
  addedPlaylistIds: [],
  isLoadingQueues: true,
  snapshot: '',
  totalSongUpvotes: 0,
  totalSongRequests: 0,
  requestCount: 15,
  upvoteCount: 15,
  // lastVotedSongIds: [],
};

export default (state = initialStates, action) => {
  let queueSongIndex = -1;
  let queue_song_uris = [];
  // let user_voted_song_uris = clone(state.userVotedSongUris);
  // let user_requested_song_uris = clone(state.userRequestedSongUris);
  let queueCopy = clone(state.queue);
  let blockedCopy = clone(state.blockedSongs);
  let playedCopy = clone(state.playedSongs);
  // let lastVotedSongIdsCopy = clone(state.lastVotedSongIds);
  //   let playedSongsCopy = clone(state.playedSongs);
  //   let blockedSongsCopy = clone(state.blockedSongs);

  switch (action.type) {
    // case REMOVE_LAST_VOTED_SONG_ID:
    //   const songIndex = lastVotedSongIdsCopy?.indexOf(action?.votedSongId);
    //   if (songIndex > -1) {
    //     lastVotedSongIdsCopy.splice(songIndex, 1);
    //     lastVotedSongIdsCopy.push(action.lastVotedSongId);
    //     console.log(
    //       'REMOVE_LAST_VOTED_SONG_ID lastVotedSongIdsCopy',
    //       lastVotedSongIdsCopy
    //     );
    //     return {
    //       ...state,
    //       lastVotedSongIds: lastVotedSongIdsCopy,
    //     };
    //   }

    // case ADD_LAST_VOTED_SONG_ID:
    //   lastVotedSongIdsCopy.push(action.lastVotedSongId);
    //   console.log(
    //     'ADD_LAST_VOTED_SONG_ID lastVotedSongIdsCopy',
    //     lastVotedSongIdsCopy
    //   );
    //   return {
    //     ...state,
    //     lastVotedSongIds: lastVotedSongIdsCopy,
    //   };

    case SET_TOTAL_SONG_UPVOTES:
      console.log('SET_TOTAL_SONG_UPVOTES', action.totalSongUpvotes);
      return {
        ...state,
        totalSongUpvotes: action.totalSongUpvotes,
      };
    case SET_TOTAL_SONG_REQUESTS:
      return {
        ...state,
        totalSongRequests: action.totalSongRequests,
      };
    case DECREMENT_USER_REQUEST_COUNT:
      return {
        ...state,
        requestCount: state.requestCount - 1,
      };
    case DECREMENT_USER_VOTE_COUNT:
      return {
        ...state,
        upvoteCount: state.upvoteCount - 1,
      };
    case SORT_FAV_LIST:
      let queueCopy_ = clone(state.queue);

      queueCopy_.sort(
        firstBy('statusCode', 'asc')
          .thenBy('likeCount', 'desc')
          // .thenBy('popularity', 'desc')
          .thenBy('sortIndex', 'asc')
      );
      return {
        ...state,
        queue: queueCopy_,
      };

    case SET_SNAPSHOT:
      return {
        ...state,
        snapshot_id: action.snapshot_id,
      };

    case CLEAR_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: [],
        prevSearchText: '',
      };
    case SET_IS_IN_PROGRESS:
      console.log('SET_IS_IN_PROGRESS--->', action.isInProgress);
      return {
        ...state,
        isInProgress: action.isInProgress,
      };
    case SET_SEARCH_RESULTS:
      console.log('SET_SEARCH_RESULTS', action.songs, state);
      return {
        ...state,
        searchResults: action.songs,
        prevSearchText: action?.searchText
          ? action.searchText
          : state.searchText,
        isInProgress: action.isInProgress,
      };

    case ADD_TO_QUEUE:
      //Add only if song doesn't exists in queue
      queueSongIndex = queueCopy?.findIndex(
        (item) => item.uri === action.song.uri
      );
      console.log('ADD_TO_QUEUE queueSongIndex', queueSongIndex);
      if (queueSongIndex === -1) {
        const _sortIndex = queueCopy[queueCopy.length - 1]?.sortIndex
          ? queueCopy[queueCopy.length - 1].sortIndex + 1
          : 1;
        action.song.sortIndex = _sortIndex;
        console.log('ADD_TO_QUEUE SONG DOES NOT EXISTS!', action.song);
        queueCopy.push(action.song);
      }
      //Copy all song ids into queueSongUris
      queue_song_uris = queueCopy.map((song) => song.uri);

      //Find and remove song from blockedSongs
      const blockedSongIndex = blockedCopy?.findIndex(
        (item) => item.uri === action.song.uri
      );
      if (blockedSongIndex > -1) {
        blockedCopy.splice(blockedSongIndex, 1);
      }

      //Find and remove song from playedSongs
      const playedSongIndex = playedCopy.findIndex(
        (item) => item.uri === action.song.uri
      );
      if (playedSongIndex > -1) {
        playedCopy.splice(playedSongIndex, 1);
      }

      console.log('ADD_TO_QUEUE');
      return {
        ...state,
        queue: queueCopy,
        blockedSongs: blockedCopy,
        playedSongs: playedCopy,
        queueSongUris: queue_song_uris,
      };

    case ADD_TRACKS_TO_QUEUE:
      //Add only if song doesn't exists in queue
      console.log('ADD_TRACKS_TO_QUEUE action.track', queueCopy, action.tracks);
      action.tracks.forEach((song) => {
        queueSongIndex = queueCopy?.findIndex((item) => item.uri === song.uri);
        if (queueSongIndex === -1) {
          queueCopy.push(song);
        }

        //Find and remove song from blockedSongs
        const blockedSongIndex = blockedCopy?.findIndex(
          (item) => item.uri === song.uri
        );
        if (blockedSongIndex > -1) {
          blockedCopy.splice(blockedSongIndex, 1);
        }

        //Find and remove song from playedSongs
        const playedSongIndex = playedCopy.findIndex(
          (item) => item.uri === song.uri
        );
        if (playedSongIndex > -1) {
          playedCopy.splice(playedSongIndex, 1);
        }
      });

      //Copy all song ids into queueSongUris
      queue_song_uris = queueCopy.map((song) => song.uri);
      console.log('ADD_TRACKS_TO_QUEUE', queueCopy, queue_song_uris);
      return {
        ...state,
        queue: queueCopy,
        blockedSongs: blockedCopy,
        playedSongs: playedCopy,
        queueSongUris: queue_song_uris,
      };

    case SET_USER_FAV_SONGS:
      // const sortedQueue = ;

      myUtils.sortSongs(action.updatedQueue);
      console.log('SET_USER_FAV_SONGS', action.updatedQueue);
      return {
        ...state,
        queue: action.updatedQueue,
        userRequestedSongUris: action.requestedSongs,
        userVotedSongUris: action.likedSongs,
      };

    case SET_QUEUE:
      console.log('SET_QUEUE action', action);

      queueCopy = action.tracks.filter(
        // (song) => song.statusCode !== 0 && song.statusCode !== -1
        (song) => song.statusCode <= 1 || !song.hasOwnProperty('statusCode')
      );

      myUtils.sortSongs(queueCopy);
      playedCopy = action.tracks.filter((song) => song.statusCode === 2);
      blockedCopy = action.tracks.filter((song) => song.statusCode === 3);

      //Copy all song ids into queueSongUris
      queue_song_uris = queueCopy.map((song) => song.uri);

      // if (action.likedSongs?.length > 0)
      //   user_voted_song_uris = action.likedSongs.map((song) => song.uri);

      // const userRequestedSongCopy = action.tracks.filter(
      //   (song) => song.guestSongId === action.guestId
      // );

      // console.log('SET_QUEUE userRequestedSongCopy', userRequestedSongCopy);
      // if (userRequestedSongCopy) {
      //   user_requested_song_uris = userRequestedSongCopy.map(
      //     (song) => song.uri
      //   );
      // }

      // console.log('SET_QUEUE action', action);

      //=======
      // console.log('SET_QUEUE queueCopy', queueCopy);
      // console.log('SET_QUEUE queue_song_uris', queue_song_uris);
      // console.log('SET_QUEUE playedCopy', playedCopy);
      console.log('SET_QUEUE blockedCopy', blockedCopy);
      // console.log('SET_QUEUE');

      return {
        ...state,
        queue: queueCopy,
        playedSongs: playedCopy,
        blockedSongs: blockedCopy,
        queueSongUris: queue_song_uris,
        isLoadingQueues: false, //action?.isLoadingQueues,
        addedPlaylistIds: [],
        userRequestedSongUris: action.requestedSongs
          ? action.requestedSongs
          : state.userRequestedSongUris,
        userVotedSongUris: action.likedSongs
          ? action.likedSongs
          : state.userVotedSongUris,
      };

    // case ADD_USER_VOTED_SONG:
    //   console.log(
    //     'ADD_USER_VOTED_SONG action.likedSongs',
    //     action.likedSong,
    //     action.likedSong?.length
    //   );
    //   if (action.likedSong?.length > 0)
    //     user_voted_song_uris.push(action.likedSong);

    //   return {
    //     ...state,
    //     userVotedSongUris: user_voted_song_uris,
    //   };

    case CLEAR_QUEUE:
      console.log('CLEAR_QUEUE');
      return {
        ...state,
        queue: [],
        queueSongUris: [],
        addedPlaylistIds: [],
      };
    case ADD_PLAYLIST:
      let addedPlaylistIdsCopy = clone(state.addedPlaylistIds);
      //Add only if song doesn't exists in queue

      const playlistIndex = addedPlaylistIdsCopy?.findIndex((item) => {
        console.log('playlistIndex', item, action.playlist.id);
        return item === action.playlist.id;
      });

      if (playlistIndex === -1) {
        console.log('ADD_PLAYLIST PLAYLIST DOES NOT EXISTS!', action.playlist);
        addedPlaylistIdsCopy.push(action.playlist.id);
      }

      return {
        ...state,
        addedPlaylistIds: addedPlaylistIdsCopy,
      };

    case UPDATE_SONG:
      queueSongIndex = queueCopy?.findIndex(
        (item) => item.uri === action.song.uri
      );

      queueCopy[queueSongIndex] = action.song;

      console.log('UPDATE_SONG', queueCopy[queueSongIndex]);
      myUtils.sortSongs(queueCopy);

      return {
        ...state,
        queue: queueCopy,
      };

    case MARK_SONG_AS_PLAYED:
      //Find and remove song from queue
      queueSongIndex = queueCopy?.findIndex(
        (item) => item.uri === action.song.uri
      );
      console.log(
        'MARK_SONG_AS_PLAYED queueSongIndex',
        queueSongIndex,
        action.song.name
      );

      if (queueSongIndex > -1) {
        /*FILTER doesn't re-render the whole list*/
        queueCopy = state.queue.filter((item) => item.uri !== action.song.uri);
        /*SPLICE DOES re-render the whole list*/
        // queueCopy.splice(queueSongIndex, 1);
      }

      //Copy all song ids into queueSongUris
      queue_song_uris = queueCopy.map((song) => song.uri);

      //Add song to playedSongs if not exists
      const playedSongsCopy = clone(state.playedSongs);
      const plySongIndex = playedSongsCopy?.findIndex(
        (item) => item.uri === action.song.uri
      );

      if (plySongIndex === -1) {
        playedSongsCopy.push(action.song);
      }

      console.log('MARK_SONG_AS_PLAYED');
      LayoutAnimation.configureNext(LayoutAnimation.Presets.spring);
      action.song.isFromApp = true;
      return {
        ...state,
        queue: queueCopy,
        // nowPlaying: action.song,
        playedSongs: playedSongsCopy,
        queueSongUris: queue_song_uris,
      };

    case SET_NEXT_TO_PLAY:
      return {
        ...state,
        nextToPlayUri: action.nextToPlayUri,
        nextToPlayPostedAt: moment().utc().format(),
      };

    case SET_NOW_PLAYING:
      console.log('SET_NOW_PLAYING action.song', action.song);
      return {
        ...state,
        nowPlaying: action.song,
      };
    case MARK_SONG_UNAVAILABLE:
      //Find and remove song from queue
      queueSongIndex = queueCopy?.findIndex(
        (item) => item.uri === action.song.uri
      );
      console.log(
        'MARK_SONG_UNAVAILABLE queueSongIndex:::',
        queueCopy,
        queueSongIndex,
        action
      );

      if (queueSongIndex > -1) {
        /*FILTER doesn't re-render the whole list*/
        queueCopy = state.queue.filter((item) => item.uri !== action.song.uri);
        /*SPLICE DOES re-render the whole list*/
        // queueCopy.splice(queueSongIndex, 1);

        // state.queue.filter((item) => item.uri !== action.song.uri);
        console.log('MARK_SONG_UNAVAILABLE->', queueCopy);
      }
      //Copy all song ids into queueSongUris
      queue_song_uris = queueCopy.map((song) => song.uri);

      //Add song to blockedSongs if not exists
      const blockedSongsCopy = clone(state.blockedSongs);
      const unvSongIndex = blockedSongsCopy?.findIndex(
        (item) => item.uri === action.song.uri
      );
      if (unvSongIndex === -1) {
        blockedSongsCopy.push(action.song);
      }

      //   console.log(
      //     'MARK_SONG_UNAVAILABLE itemIndex',
      //     queueSongIndex,
      //     blockedSongs,
      //     blockedSongsCopy
      //   );
      // console.log('MARK_SONG_UNAVAILABLE');
      return {
        ...state,
        queue: queueCopy,
        blockedSongs: blockedSongsCopy,
        queueSongUris: queue_song_uris,
      };

    case REMOVE_SONG_FROM_QUEUE:
      //Find and remove song from queue
      queueSongIndex = queueCopy?.findIndex(
        (item) => item.uri === action.song.uri
      );
      console.log(
        'REMOVE_SONG_FROM_QUEUE queueSongIndex:::',
        queueCopy,
        queueSongIndex,
        action
      );

      if (queueSongIndex > -1) {
        /*FILTER doesn't re-render the whole list*/
        queueCopy = state.queue.filter((item) => item.uri !== action.song.uri);
        /*SPLICE DOES re-render the whole list*/
        // queueCopy.splice(queueSongIndex, 1);

        // state.queue.filter((item) => item.uri !== action.song.uri);
        console.log('REMOVE_SONG_FROM_QUEUE->', queueCopy);
      }
      //Copy all song ids into queueSongUris
      queue_song_uris = queueCopy.map((song) => song.uri);

      return {
        ...state,
        queue: queueCopy,
        queueSongUris: queue_song_uris,
      };

    case SET_PLAYLISTS:
      return {
        ...state,
        playlists: action.playlists,
        addedPlaylistIds: [],
      };

    case SET_ISLOADING_QUEUES:
      return {
        ...state,
        isLoadingQueues: action?.isLoadingQueues,
      };
  }

  return state;
};
