import Amplify, { syncExpression } from 'aws-amplify';
import { firstBy } from 'thenby';
import Storage from '@aws-amplify/storage';
import {
  Guest,
  User,
  Song,
  Event,
  History,
  GuestLikedSong,
} from '../src/models';
// import { reject } from 'core-js/fn/promise';
import { Image } from 'react-native';
import { DataStore, DISCARD } from '@aws-amplify/datastore';

import moment, { now } from 'moment';
// import {
//   scale,
//   verticalScale,
//   moderateScale,
// } from 'react-native-size-matters/extend';
import appConst from '../constants/appConst';
// import AsyncStorage from '@react-native-async-storage/async-storage';

// export const getUserData = async () => {
//   try {
//     const jsonValue = await AsyncStorage.getItem('userData');
//     console.log(
//       'getUserData',
//       jsonValue != null ? JSON.parse(jsonValue) : null
//     );
//     return jsonValue != null ? JSON.parse(jsonValue) : null;
//   } catch (e) {
//     console.log('getCurrentUserData error', e);
//   }
// };

// export const saveUserData = async (value) => {
//   console.log('saveUserData', value);
//   try {
//     const jsonValue = JSON.stringify(value);
//     await AsyncStorage.setItem('userData', jsonValue);
//   } catch (e) {
//     console.log('saveCurrentUserData error', e);
//   }
// };

// export const removeUserData = async () => {
//   console.log('removeUserData');
//   try {
//     await AsyncStorage.removeItem('userData');
//     return true;
//   } catch (exception) {
//     return false;
//   }
// };

const clamp = (min, max, value) => {
  const valueClampedMin = value < min ? min : value;
  return valueClampedMin > max ? max : valueClampedMin;
};

export const applyAlpha = (color, alpha) => {
  const alphaClamped = clamp(0, 1, alpha);
  const alpha256 = (alphaClamped * 255).toFixed();
  const alphaBase16 = Number(alpha256).toString(16); // we're ensuring this is a number then converting
  const paddedAlpha =
    alphaBase16?.length === 1 ? alphaBase16?.padStart(1, 0) : alphaBase16;
  return color.concat('', paddedAlpha);
};

const dataStoreConfig = {
  errorHandler: (error) => {
    console.warn('Unrecoverable error', { error });
  },
  conflictHandler: async (data) => {
    // Example conflict handler
    console.log('conflictHandler--->', data);
    const modelConstructor = data.modelConstructor;
    if (modelConstructor === User) {
      const remoteModel = data.remoteModel;
      const localModel = data.localModel;
      const newModel = modelConstructor.copyOf(remoteModel, (d) => {
        d = localModel;
      });
      return newModel;
    }

    return DISCARD;
  },
  maxRecordsToSync: 30000,
  fullSyncInterval: 60, // minutes
};

export const clearLocaleDataStore = async () => {
  console.log('clearLocaleDataStore');
  await DataStore.clear();
  console.log('configureDataStore 3');
  await DataStore.stop();
  console.log('configureDataStore 3b');
  await DataStore.start();
  console.log('configureDataStore 4');
};

// export const startDataStoreSync = async () => {
//   await DataStore.stop();
//   await DataStore.start();
// };
export const initializeDataStore = async (userEmail) => {
  console.log('configureDataStore 0', userEmail);
  DataStore.configure({
    ...dataStoreConfig,
    syncExpressions: [
      syncExpression(User, () => {
        return (user) => user.email('eq', userEmail);
      }),
    ],
  });
  await DataStore.stop();
  console.log('configureDataStore 0a', userEmail);
  await DataStore.start();
  console.log('configureDataStore 0b', userEmail);
};

export const configureDataStore = async (eventInfo) => {
  console.log('configureDataStore 1', eventInfo);
  DataStore.configure({
    ...dataStoreConfig,
    syncExpressions: [
      // syncExpression(Song, () => {
      //   return (song) => song.eventSongId('eq', eventInfo.id); //.isDeletedInt('ne', 1);
      // }),
      syncExpression(Event, () => {
        return (event) => event.id('eq', eventInfo.id);
      }),
      syncExpression(Guest, () => {
        return (guest) => guest.eventGuestId('eq', eventInfo.id);
      }),
      syncExpression(History, () => {
        return (history) => history.eventHistoryId('eq', eventInfo.id);
      }),
      syncExpression(GuestLikedSong, () => {
        return (guestLikedSong) =>
          guestLikedSong.eventGuestLikedSongId('eq', eventInfo.id);
      }),
    ],
  });
  console.log('configureDataStore 2');
  await DataStore.stop();
  console.log('configureDataStore 3');
  await DataStore.start();
  console.log('configureDataStore 4');
};

export const generateCode = (codeLength) => {
  let l = Math.ceil(codeLength / 2);
  var text = '';
  var char_list = 'ABCDEFGHIJKLMNPQRSTUVWXYZ';
  for (var i = 0; i < l; i++) {
    text += char_list.charAt(Math.floor(Math.random() * char_list.length));
  }

  var num_list = '0123456789';
  for (var i = 0; i < l; i++) {
    text += num_list.charAt(Math.floor(Math.random() * num_list.length));
  }
  console.log(text);
  return text;
};

export const compare = (a, b) => {
  const bandA = a.band.toUpperCase();
  const bandB = b.band.toUpperCase();

  let comparison = 0;
  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  return comparison;
};

// export const formatPhoneNumber = (str, withSpaceAndNumOnly = false) => {
//   if (str && str !== '') {
//     if (isNaN(str)) {
//       return str;
//     }

//     //Filter only `numbers from the input
//     let cleaned = ('' + str).replace(/\D/g, '');
//     console.log('formatPhoneNumber cleaned', cleaned);
//     //Check if the input is of correct length
//     let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
//     let match2 = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);

//     console.log('formatPhoneNumber match', match);
//     if (!match2) {
//       if (withSpaceAndNumOnly)
//         return match[1] + ' ' + match[2] + ' ' + match[3];
//       else return '(' + match[1] + ') ' + match[2] + '-' + match[3];
//     } else if (match2) {
//       if (withSpaceAndNumOnly)
//         return match2[2] + ' ' + match2[3] + ' ' + match2[4];
//       else return '(' + match2[2] + ') ' + match2[3] + '-' + match2[4];
//     } else {
//       return str;
//     }
//   }
//   return null;
// };

// export const parseAWSJSONfields = (objArray) => {
//   console.log('parseAWSJSONfields 0');
//   objArray.forEach((element) => {
//     Object.keys(element).forEach((k) => {
//       console.log('parseAWSJSONfields 1', k, element[k], typeof k);
//     });

//     // Object.entries(element).forEach(([key, val]) => {
//     //   console.log('parseAWSJSONfields 1', key, val, typeof key);
//     // });

//     // element[fieldName] = JSON.parse(element[fieldName]);
//   });
// };
export const validateSongObject = (
  element,
  requestedSongUris,
  votedSongUris,
  queueSongUris
) => {
  element['imageLarge'] = JSON.parse(element['imageLarge']);
  element['imageMedium'] = JSON.parse(element['imageMedium']);
  element['imageSmall'] = JSON.parse(element['imageSmall']);
  element.alreadyRequested = requestedSongUris?.includes(element.uri);
  element.alreadyVoted = votedSongUris?.includes(element.uri);
  element.alreadyInQueue = queueSongUris?.includes(element.uri);

  element.statusCode =
    element?.statusCode === undefined || element?.statusCode === null
      ? 1
      : element?.statusCode;
};
export const validateAWSJSONObjectArray = (
  objArray,
  requestedSongUris,
  votedSongUris
) => {
  console.log(
    'parseAWSJSONfields 0',
    objArray,
    requestedSongUris,
    votedSongUris
  );
  if (objArray.length > 0)
    objArray.forEach((element) => {
      validateSongObject(element, requestedSongUris, votedSongUris);
    });
};
export const flagRequestedFields = (
  objArray,
  requestedSongUris,
  votedSongUris,
  queueSongUris
) => {
  console.log(
    'parseAWSJSONfields 0',
    objArray,
    requestedSongUris,
    votedSongUris
  );
  if (objArray.length > 0)
    objArray.forEach((element) => {
      element.alreadyRequested = requestedSongUris.includes(element.uri);
      element.alreadyVoted = votedSongUris.includes(element.uri);
      element.alreadyInQueue = queueSongUris?.includes(element.uri);
    });
};

export const formatPhoneNumber = (
  str,
  withSpaceAndNumOnly = false,
  hide = true
) => {
  if (str && str !== '') {
    if (isNaN(str)) {
      return str;
    }

    let formattedPhone = '';
    //Filter only `numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');
    // console.log('cleaned', str, cleaned);
    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (!match) {
      cleaned = '1' + cleaned;
      match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    }
    // console.log('str', str, cleaned, match);

    if (match) {
      if (withSpaceAndNumOnly)
        formattedPhone = match[2] + ' ' + match[3] + ' ' + match[4];
      else {
        formattedPhone = '(' + match[2] + ') ' + match[3] + '-' + match[4];
        if (hide) {
          console.log('formattedPhone1', formattedPhone);
          formattedPhone =
            formattedPhone.substring(0, 5) +
            '***-' +
            formattedPhone.substring(9, formattedPhone.length);
        }
      }
    } else {
      return (formattedPhone = str);
    }

    return formattedPhone;
  }
  return null;
};

export const sortSongs = (arrayList) => {
  arrayList.sort(
    firstBy('statusCode', 'asc')
      .thenBy('likeCount', 'desc')
      .thenBy('sortIndex', 'asc')

    // firstBy('sortIndex', 'asc')
    //   .thenBy('likeCount', 'desc')
    //   .thenBy('popularity', 'desc')

    // firstBy('likeCount', 'desc')
    //   .thenBy('sortIndex', 'asc')
    //   .thenBy('popularity', 'desc')

    // firstBy('sortHash', 'asc').thenBy('popularity', 'asc')
  );
};

export const getAveFromArray = (objs, numProp) => {
  // console.log('objs', objs);
  // console.log('numProp', numProp);
  let items = objs.map((a) => a[numProp]);
  let result = 0;
  // console.log('items', items, numProp, objs[0][numProp]);
  if (items.length > 1) {
    // console.log('1111111');
    const mid = Math.floor(items.length / 2),
      nums = [...items].sort((a, b) => a - b);
    result =
      items.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
  } else if (items.length === 1) {
    // console.log('222222');
    result = items[0];
  }
  console.log('average', result);
  return result;
};

export const mScale = (val) => {
  // return moderateScale(val, appConst.SCALE_FACTOR);
  // return moderateScale(val);
  return val;
};

export const toISOLocal = (d) => {
  const z = (n) => ('0' + n).slice(-2);
  let off = d.getTimezoneOffset();
  const sign = off < 0 ? '+' : '-';
  off = Math.abs(off);
  return (
    new Date(d.getTime() - d.getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, -1) +
    sign +
    z((off / 60) | 0) +
    ':' +
    z(off % 60)
  );
};

export const delay = (sec) =>
  new Promise((resolve) => setTimeout(resolve, sec * 1000));

export const checkIfPropertiesAreEqual = (objNew, objOld) => {
  let isEqual = true;
  Object.entries(objNew).forEach(([key, val]) => {
    if ((!objOld.hasOwnProperty(key) && val !== null) || objOld[key] !== val) {
      isEqual = false;
      return;
    }
  });

  console.log('IS EQUAL', isEqual);
  return isEqual;
};
export const removeEmptyOrNull = (obj) => {
  Object.entries(obj).forEach(([key, val]) => {
    return (
      (val && typeof val === 'object' && removeEmptyOrNull(val)) ||
      ((val === null || val === '') && delete obj[key])
      //  ||
      // (delete obj['alreadyRequested'] &&
      //   delete obj['alreadyVoted'] &&
      //   delete obj['info'])
    );
  });
};

export const convertDateTimeToISOString = (obj) => {
  Object.entries(obj).forEach(([key, val]) => {
    // if (val && typeof val === 'object') convertDateTimeToISOString(val);
    // else
    if (val && typeof val === 'object' && moment(val).isValid()) {
      console.log('==================');
      obj[key] = moment(val).format();
    }
  });
  return obj;
};
export const convertISOstringToUTC = (val) => {
  console.log(
    'moment(val).utc()',
    val,
    moment(val).isValid() ? moment(val).utc()._d : null
  );
  return moment(val).isValid() ? moment(val).utc()._d : null;
};

export const getImageStyleBasedOnImageUri = (assetsUri, itemWidth) => {
  return [
    {
      flex: 1,
      borderWidth: 0,
      width: itemWidth,
      height: assetsUri.data ? itemWidth * assetsUri.data.ratio : itemWidth, //getImageHeight(assetsUri, screenWidth),
    },
  ];
};

export const mySortByValues = (key, order = 'asc') => {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
};

export function convertUTCDateToLocalDate(dateStr) {
  console.log(dateStr);
  let utcDate = new Date(dateStr);
  var newDate = new Date(
    utcDate.getTime() - utcDate.getTimezoneOffset() * 60 * 1000
  );
  console.log(newDate);
  return newDate.toLocaleDateString(); // + " " + newDate.toLocaleTimeString();
}

const getThumbnail = (assetUri) => {
  return new Promise((resolve, reject) => {
    let s3key = '';
    if (assetUri.data) s3key = assetUri.data.thumbnail.key;
    else s3key = assetUri.thumbnail.key;

    Storage.get(s3key, {
      level: 'public',
      expires: 604800, //One week
    })
      .then((result) => {
        // console.log(result);
        assetUri.uri = result;
        // asset.dimensions = {
        //   width: 120, //asset.data.thumbnail.width,
        //   height: 60, //asset.data.thumbnail.height,
        // };
        // asset.source.uri = result;
        // availableAssetsUri[item].uri = result;

        // console.log("0909090909", asset);
        resolve(assetUri);
      })
      .catch((err) => reject(assetUri.uri + ' - Error: ' + err));
  });
};

export const downloadThumbnails = (availableAssetsUri) => {
  return new Promise((resolve, reject) => {
    // delete availableAssetsUri.uri;
    // Promise.all(
    const getThumbnailPromises = [];
    availableAssetsUri.map(async (assetUri) => {
      getThumbnailPromises.push(getThumbnail(assetUri));
      // try {
      //   if (asset.data.thumbnail)
      //     await Storage.get(asset.data.thumbnail.key, {
      //       // level: "public",
      //       expires: 60,
      //     })
      //       .then((result) => {
      //         console.log(result);
      //         asset.uri = result;
      //         // asset.dimensions = {
      //         //   width: 120, //asset.data.thumbnail.width,
      //         //   height: 60, //asset.data.thumbnail.height,
      //         // };
      //         // asset.source.uri = result;
      //         // availableAssetsUri[item].uri = result;

      //         console.log("0909090909", asset);
      //         Promise.resolve;
      //       })
      //       .catch((err) => console.log(err));
      // } catch (error) {
      //   console.log(error);
      //   Promise.reject;
      // }
    });
    // ).then(() => {
    //   console.log("1xxxxxxxxx", availableAssetsUri);
    //   resolve();
    // });
    // console.log(getThumbnailPromises.length);
    // if (getThumbnailPromises.length == 0) Promise.resolve(1);

    // console.log("xxxxxxx");
    // Promise.all(getThumbnailPromises)
    Promise.allSettled(getThumbnailPromises)
      .then((results) => {
        // console.log(results);
        resolve(0);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
  //for (const item in availableAssetsUri) {

  //}
};

export const getImageDimensions = (uri) => {
  return new Promise(function (resolved, reject) {
    Image.getSize(
      uri,
      (width, height) => {
        resolved({ width: width, height: height });
      },
      (error) => {
        console.error(`Couldn't get the image size: ${error}`);
        reject("Couldn't get the image size.");
      }
    );
  });
};

export const getImageHeight = (assetUri, screenWidth) => {
  let w = 0;
  let h = 0;
  if (false) {
    //assetUri.data.thumbnail
    w = assetUri.data.thumbnail.width;
    h = assetUri.data.thumbnail.height;
    console.log(w, h);
  } else {
    Image.getSize(
      assetUri.uri,
      (width, height) => {
        w = width;
        h = height;
        console.log(w, h);
        const ratio = h / w;
        return screenWidth * ratio;
      },
      (error) => {
        console.error(`Couldn't get the image size: ${error.message}`);
        return 100;
      }
    );
  }
};

// export const getImageAspectRatio = (uri) => {
//   let w = 0;
//   let h = 0;

//   Image.getSize(
//     uri,
//     (width, height) => {
//       w = width;
//       h = height;
//       console.log(w, h);
//       const ratio = h / w;
//       console.log(ratio);
//       return ratio;
//     },
//     (error) => {
//       console.error(`Couldn't get the image size: ${error.message}`);
//       return 100;
//     }
//   ).then((ratio) => {
//     console.log(ratio);
//   });
// };

export const titleCase = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.replace(word[0], word[0].toUpperCase());
    })
    .join(' ');
};
