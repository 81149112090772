// @ts-check
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Screen from '../components/Screen';
import {
  Button,
  FormControl,
  Input,
  Center,
  Text,
  NativeBaseProvider,
  Box,
  Modal as NBModal,
  VStack,
  HStack,
  Heading,
  Container,
  Icon,
  ScrollView,
  Spinner,
  // Popover,
} from 'native-base';
import Popover, {
  PopoverMode,
  PopoverPlacement,
} from 'react-native-popover-view';
import {
  // SafeAreaView,
  // SafeAreaProvider,
  // SafeAreaInsetsContext,
  useSafeAreaInsets,
  // initialWindowMetrics,
} from 'react-native-safe-area-context';
import {
  View,
  TouchableOpacity,
  TextInput,
  Keyboard,
  RefreshControl,
  FlatList,
  TouchableHighlight,
  StyleSheet,
  ViewBase,
  InteractionManager,
  Image,
  Text as RNText,
  Easing,
  Animated,
} from 'react-native';
import {
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from '@expo/vector-icons';

import { useIsFocused } from '@react-navigation/native';

// import {
//   useAnimatedStyle,
//   useSharedValue,
//   useAnimatedProps,
//   interpolate,
//   Extrapolation,
//   useAnimatedScrollHandler,
// } from 'react-native-reanimated';
import { MotiView, MotiText, AnimatePresence } from 'moti';

import AppInputBox from '../components/AppInputBox';
import AppText from '../components/AppText';
import AppButton from '../components/AppButton';

import * as songActions from '../redux_store/actions/songActions';
import * as appActions from '../redux_store/actions/appActions';

// import colors from 'native-base/lib/typescript/theme/base/colors';
import * as myUtils from '../utils/myUtils';
import Colors from '../constants/colors';
import colors from '../config/colors';
import defaultStyles from '../config/styles';
import { globalStyles } from '../styles/global';

import {
  ROW_HEIGHT,
  ICON_WIDTH,
  IS_GAPLESS,
  BUTTON_HEIGHT,
} from '../constants/appConst';
import moment, { now } from 'moment';

import { Event, Song, History, Guest } from '../src/models';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';

let suspensionTimer;
let popoverTimer;

let subscriptionRS;
let subscriptionHistory;
let subsHistoryItem;
let subsHistoryLiked;
let subsHistoryRequest;
let subsCurrentEvent;
let subsCurrentGuest;

const genSuspEndedStr = (suspStartedAt, suspDurationMin) => {
  var futureDate = null;

  if (suspStartedAt !== null)
    futureDate = moment(suspStartedAt).add(suspDurationMin, 'minutes');
  else futureDate = moment().add(suspDurationMin, 'minutes');

  console.log(
    'genSuspEndedStr suspEndedStr',
    suspStartedAt,
    suspDurationMin,
    futureDate.local().format('LT')
  );

  return futureDate.local().format('LT');
};

// const AnimFlatList = Animated.createAnimatedComponent(FlatList);
// const AnimView = Animated.createAnimatedComponent(View);
const { Value, event, createAnimatedComponent } = Animated;
const HEADER_HEIGHT = 360;

let repaintCounter = 0;

const MainScreen = (props) => {
  repaintCounter++;
  console.log('MainScreen repaint', repaintCounter);
  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const [animatedScrollIndex] = useState(new Value(0));
  const range = [0, HEADER_HEIGHT];
  // const interpolate = (node, options) => {
  //   return node.interpolate(options);
  // };

  const headerOpacity = animatedScrollIndex.interpolate({
    inputRange: range,
    outputRange: [1, 0],
    extrapolate: 'clamp',
  });

  const HeaderPostionY = animatedScrollIndex.interpolate({
    inputRange: range,
    outputRange: [140, 18],
    extrapolate: 'clamp',
  });

  const lastActivityNotifState = useSelector((state) => {
    //@ts-ignore
    return state?.appReducer?.history?.length > 0
      ? //@ts-ignore
        state?.appReducer?.history[0]
      : {};
  });

  const localAppSettingsState = useSelector((state) => {
    console.log('localAppSettingsState', state);
    //@ts-ignore
    return state?.appReducer?.appSettings;
  });
  const requestedSongsState = useSelector(
    //@ts-ignore
    (state) => state.songsReducer?.queue
  );

  const totalVotesState = useSelector(
    //@ts-ignore
    (state) => state.songsReducer?.totalSongUpvotes
  );
  const totalRequestsState = useSelector(
    //@ts-ignore
    (state) => state.songsReducer?.totalSongRequests
  );
  // const requestCountState = useSelector(
  //   //@ts-ignore
  //   (state) => state?.songsReducer?.requestCount
  // );
  // const voteCountState = useSelector(
  //   //@ts-ignore
  //   (state) => state?.songsReducer?.upvoteCount
  // );
  // const lastVotedSongIdsState = useSelector(
  //   //@ts-ignore
  //   (state) => state?.songsReducer?.lastVotedSongIds
  // );

  const lastVotedSongIdsRef = useRef([]);
  const successVisibleTimer = useRef(null);
  const eventRef = useRef(localAppSettingsState?.currentEvent);
  const guestRef = useRef(localAppSettingsState?.user);

  // const [lastVotedSongIds, setLastVotedSongIds] = useState(
  //   lastVotedSongIdsState
  // );

  const [isGuestSuspended, setIsGuestSuspended] = useState(false);
  const [selectedSong, setSelectedSong] = useState(null);
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [userName, setUserName] = useState(localAppSettingsState.userName);
  const [isSuccessVisible, setIsSuccessVisible] = useState(false);
  const [requestedSongs, setRequestedSongs] = useState(requestedSongsState);

  const [suspEndedStr, setSuspEndedStr] = useState(null);

  const [requestCount, setRequestCount] = useState(
    localAppSettingsState?.currentEvent?.maxRequest - totalRequestsState
  );
  const [voteCount, setVoteCount] = useState(
    localAppSettingsState?.currentEvent?.maxLike - totalVotesState
  );

  const [localAppSettings, setLocalAppSettings] = useState(
    localAppSettingsState
  );

  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const touchablePopover = useRef();
  const lastActivityNotifRef = useRef(lastActivityNotifState);

  useEffect(() => {
    if (localAppSettingsState) {
      setTimeout(function () {
        console.log('lastActivityNotifState-->', lastActivityNotifState);
        if (isFocused) showPopoverNotif(lastActivityNotifState);
      }, 1000);
    }
  }, [lastActivityNotifState]);

  useEffect(() => {
    console.log(
      'useEffect localAppSettingsState ',
      localAppSettingsState?.user,
      totalVotesState,
      totalRequestsState
    );

    if (
      !localAppSettingsState?.user?.maxLike ||
      localAppSettingsState?.user?.maxLike === -1
    ) {
      // if (
      //   eventRef.current.maxLike != localAppSettingsState?.currentEvent?.maxLike
      // ) {
      console.log('-------> 1');
      setVoteCount(
        localAppSettingsState?.currentEvent?.maxLike - totalVotesState
      );
      // }
    } else {
      console.log('-------> 2');
      setVoteCount(localAppSettingsState?.user?.maxLike - totalVotesState);
    }

    if (
      !localAppSettingsState?.user?.maxRequest ||
      localAppSettingsState?.user?.maxRequest === -1
    ) {
      // if (
      //   eventRef.current.maxRequest !=
      //   localAppSettingsState?.currentEvent.maxRequest
      // ) {
      console.log('-------> 3');
      setRequestCount(
        localAppSettingsState?.currentEvent?.maxRequest - totalRequestsState
      );
      // }
    } else {
      console.log('-------> 4', localAppSettingsState?.user?.maxRequest);
      setRequestCount(
        localAppSettingsState?.user?.maxRequest - totalRequestsState
      );
    }

    const userState = localAppSettingsState?.user;

    console.log(
      'suspension info has been modified',
      userState,
      guestRef.current
    );

    //check if suspension info has been modified
    // if (
    //   userState &&
    //   guestRef.current &&
    //   !myUtils.checkIfPropertiesAreEqual(userState, guestRef.current)
    // ) {

    if (userState?.isSuspended && userState?.suspDurationMin > 0) {
      // const start = moment().utc();
      // const end = moment(userState?.suspStartedAt).add(
      //   userState?.suspDurationMin,
      //   'minutes'
      // );

      // const diffInMins = end.diff(start, 'minutes');

      // console.log('diffInMins', diffInMins, start, end);

      // const diffInSecs = getRemainingSuspTime(localAppSettingsState?.user).then();
      dispatch(appActions.getRemainingSuspTime(localAppSettingsState?.user))
        .then((ret) => {
          clearTimeout(suspensionTimer);
          console.log('ret.suspRamainingInSec', ret.suspRamainingInSec);
          suspensionTimer = setTimeout(() => {
            console.log('suspensionTimer TRIGGERED!');
            dispatch(
              appActions.updateCurrentGuest({
                isSuspended: false,
                suspDurationMin: 0,
                suspReasonMsg: null,
                suspEndedStr: null,
                suspStartedAt: null,
              })
            );
          }, ret.suspRamainingInSec * 1000);
        })
        .catch(() => {});
    }
    // }

    const suspEndedStrVal = genSuspEndedStr(
      localAppSettingsState?.user?.suspStartedAt,
      localAppSettingsState?.user?.suspDurationMin
    );
    console.log('suspEndedStrVal', suspEndedStrVal);
    setSuspEndedStr(suspEndedStrVal);

    setLocalAppSettings(localAppSettingsState);

    eventRef.current = localAppSettingsState?.currentEvent;
    guestRef.current = localAppSettingsState?.user;
    setIsGuestSuspended(localAppSettingsState?.user?.isSuspended);

    return () => {
      clearTimeout(suspensionTimer);
    };
  }, [localAppSettingsState, totalVotesState, totalRequestsState]);

  // useEffect(() => {
  //   console.log(
  //     'useEffect totalVotesState, totalRequestsState ',
  //     totalVotesState,
  //     totalRequestsState
  //   );

  //   if (
  //     !localAppSettingsState?.user?.maxLike ||
  //     localAppSettingsState?.user?.maxLike === -1
  //   ) {
  //     setVoteCount(
  //       localAppSettingsState?.currentEvent?.maxLike - totalVotesState
  //     );
  //   } else {
  //     setVoteCount(localAppSettingsState?.user?.maxLike - totalVotesState);
  //   }

  //   if (
  //     !localAppSettingsState?.user?.maxRequest ||
  //     localAppSettingsState?.user?.maxRequest === -1
  //   ) {
  //     setRequestCount(
  //       localAppSettingsState?.currentEvent?.maxRequest - totalRequestsState
  //     );
  //   } else {
  //     setRequestCount(
  //       localAppSettingsState?.user?.maxRequest - totalRequestsState
  //     );
  //   }
  // }, [totalVotesState, totalRequestsState]);

  useEffect(() => {
    console.log('requestedSongsState', requestedSongsState);
    // myUtils.flagRequestedFields(requestedSongsState, userRequestedSongIds);
    setRequestedSongs(requestedSongsState);
    // }, [requestedSongsState]);
  }, [requestedSongsState]);
  useEffect(() => {
    if (props.route.params?.userName) {
      localAppSettingsState.userName = props.route.params?.userName;
      setUserName(props.route.params?.userName);
    }
  }, [props]);

  const flatListKeyExtractor = useCallback((item) => {
    return item?.uri;
  }, []);

  const RowItem = useCallback(
    ({ item, index }) => {
      // console.log(
      //   'item.uri === selectedSong?.uri',
      //   item.uri,
      //   selectedSong?.uri
      // );

      return (
        // <View>
        <TouchableHighlight
          //   disabled={isActive}
          onPress={() => {
            console.log('setSelectedSong item', item.uri);
            setSelectedSong(item);
            // runOnUI(setQueue)((prevState) =>
            //   prevState.filter((e) => e.uri !== item.uri)
            // );
            // onDeleteRowItem(item, 'left');
          }}
          style={{
            overflow: 'hidden',
            // height: ROW_HEIGHT + 40,
          }}
        >
          <View
            style={{
              backgroundColor:
                selectedSong?.uri === item.uri
                  ? myUtils.applyAlpha(Colors.defBackgroundColor, 0.5)
                  : Colors.defBackgroundColor,
              //   borderBottomWidth: 0.5,
              borderTopWidth: 1,
              borderColor: myUtils.applyAlpha(Colors.OxfordBlue, 0.5),
              // paddingVertical: 10,
              justifyContent: 'flex-start',
              flexDirection: 'row',
              overflow: 'hidden',
              height: ROW_HEIGHT,
            }}
          >
            <View
              style={{
                width: 4,
                height: '100%',
                position: 'absolute',
                left: 0,
                zIndex: 9,
                backgroundColor:
                  selectedSong?.uri === item.uri
                    ? Colors.primary
                    : localAppSettings?.isRemoteControlMode && index === 0
                    ? colors.OxfordBlue
                    : Colors.defBackgroundColor,
              }}
            />
            {localAppSettings?.isRemoteControlMode && index === 0 ? (
              <View
                style={{
                  width: 70,
                  marginRight: 5,
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: colors.OxfordBlue,
                }}
              >
                <Text
                  selectable={false}
                  style={[{ color: colors.main, fontSize: 14 }]}
                >
                  NEXT
                </Text>
              </View>
            ) : (
              <View
                style={{
                  width: 70,
                  marginRight: 5,
                  alignItems: 'center',
                  justifyContent: 'center',
                  // marginVertical: 20,
                  // backgroundColor: 'green',
                }}
              >
                {localAppSettings?.isRemoteControlMode && index !== 0 && (
                  <AppText
                    // containerStyle={styles.rowIndexContainer}
                    customStyle={styles.rowIndexText}
                    controlStyle='boldText'
                  >
                    {/* {queueState.length} */}
                    {index}
                  </AppText>
                )}
                {!localAppSettings?.isRemoteControlMode && (
                  <AppText
                    // containerStyle={styles.rowIndexContainer}
                    customStyle={styles.rowIndexText}
                    controlStyle='boldText'
                  >
                    {/* {queueState.length} */}
                    {index + 1}
                  </AppText>
                )}
                {item.likeCount > 0 && (
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: -8,
                    }}
                  >
                    <MotiView
                      // from={{ scale: 0.5 }}
                      animate={{
                        scale: lastVotedSongIdsRef.current?.includes(item.id)
                          ? [{ value: 2, delay: 250 }, 1]
                          : 1, // scale to 0, 1.1, then 1 (with delay 200 ms)
                      }}
                      transition={{
                        // type: 'spring',
                        // duration: 100,
                        // repeat: 4,
                        scale: {
                          type: 'spring',
                          delay: 100,
                          stiffness: 400,
                        },
                      }}
                      onDidAnimate={async (styleProp, didAnimationFinish) => {
                        if (styleProp === 'scale' && didAnimationFinish) {
                          lastVotedSongIdsRef.current =
                            lastVotedSongIdsRef.current.filter((id) => {
                              // console.log('didAnimationFinish', id, item.id);
                              return id !== item.id;
                            });
                          // console.log(
                          //   'didAnimationFinish',
                          //   item.id,
                          //   lastVotedSongIdsRef.current
                          // );
                          // setLastVotedSongIds(
                          //   lastVotedSongIds.filter((i) => i.id !== item.id)
                          // );
                          // dispatch(songActions.removeLastVotedSongId(item.id));
                        }
                      }}
                    >
                      <Icon
                        mr={1}
                        as={MaterialCommunityIcons}
                        name='heart'
                        color={Colors.rose}
                        size={4}
                        // style={{}}
                      />
                    </MotiView>
                    <Text fontSize='sm'>{item.likeCount}</Text>
                  </View>
                )}
              </View>
            )}
            <View
              style={{
                flexDirection: 'row',
                flex: 1,
                marginRight: 10,
              }}
            >
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {/* {item.explicit && (
                    <View style={styles.explicitIconContainer}>
                      <MaterialIcons
                        name='explicit'
                        size={32}
                        color={colors.error}
                        style={styles.explicitIcon}
                      />
                    </View>
                  )} */}

                <Image
                  style={{
                    // flex: 1,
                    borderWidth: 1,
                    borderRadius: 4,
                    borderColor: '#C4C4C4',
                    width: ICON_WIDTH,
                    height: ICON_WIDTH,
                    marginRight: 12,
                  }}
                  resizeMode={'contain'}
                  source={{ uri: item?.imageSmall?.url }}
                  // alt={item.name}
                />
              </View>
              <View
                style={{
                  flexShrink: 1,
                  justifyContent: 'center',
                }}
              >
                <View>
                  <AppText
                    // customStyle={{
                    //   color: Colors.primary,
                    // }}
                    customStyle={[
                      globalStyles.text1,
                      {
                        color:
                          item.uri === selectedSong?.uri
                            ? Colors.primary
                            : 'white',
                      },
                    ]}
                    controlStyle='boldText'
                  >
                    {item.name}
                  </AppText>
                </View>
                <View>
                  <AppText
                    customStyle={{
                      color: 'white',
                    }}
                    controlStyle='smallText'
                  >
                    {item.artist}
                  </AppText>
                </View>
                <View>
                  <AppText
                    customStyle={{
                      color: 'white',
                    }}
                    controlStyle='smallText'
                  >
                    {item.requestedBy?.length > 0
                      ? 'Requested by ' + item.requestedBy
                      : 'Added by host'}
                  </AppText>
                </View>

                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: '100%',
                    marginTop: 4,
                  }}
                >
                  {item.alreadyVoted ? (
                    <View
                      style={globalStyles.tagContainer({
                        backgroundColor: colors.amber[300],
                      })}
                    >
                      <RNText style={globalStyles.tagText}>UPVOTED</RNText>
                    </View>
                  ) : (
                    item.alreadyRequested && (
                      <View
                        style={globalStyles.tagContainer({
                          backgroundColor: colors.amber[300],
                        })}
                      >
                        <RNText style={globalStyles.tagText}>REQUESTED</RNText>
                      </View>
                    )
                  )}
                  {item.explicit && (
                    <View
                      style={globalStyles.tagContainer({
                        backgroundColor: colors.error,
                      })}
                    >
                      <RNText style={globalStyles.tagText}>EXPLICIT</RNText>
                    </View>
                  )}
                </View>
              </View>
            </View>
          </View>
          {/* <View
              style={{
                width: '100%',
                height: 20,
                flex: 1,
                backgroundColor: colors.MaxBlueGreen,
              }}
            ></View> */}
        </TouchableHighlight>
        // </View>
      );
    },
    [requestedSongs, selectedSong]
  );

  const onSubmitRequest = async () => {
    setIsSendingRequest(true);
    setIsInProgress(true);
    setIsRequestSent(true);

    try {
      // const params = {
      //   ...props.route.params,
      //   userName: props.route.params?.userName,
      // };

      dispatch(
        appActions.addActivityHistory(
          'upvote',
          localAppSettings?.userName,
          ' upvoted ',
          selectedSong?.name,
          '.',
          selectedSong?.info,
          selectedSong?.id
        )
      );

      dispatch(songActions.incrementLikeCount(selectedSong))
        //@ts-ignore
        .then(() => {
          selectedSong.alreadyVoted = true;
          // setVoteCount(voteCount - 1);
          setIsSuccessVisible(true);
        })
        .finally(() => {
          setIsInProgress(false);
        });
    } catch (err) {
      console.log('handleSearch error', err);
      setIsInProgress(false);
    } finally {
      setIsSendingRequest(false);
    }
  };

  const hidePopoverNotif = () => {
    setIsPopoverVisible(false);
    clearTimeout(popoverTimer);
  };

  const showPopoverNotif = (lastActivityNotif) => {
    console.log(
      'showPopoverNotif lastActivityNotif',
      lastActivityNotif,
      lastActivityNotif?.installationId === localAppSettings?.installationId,
      lastActivityNotif?.installationId,
      localAppSettings?.installationId
    );
    if (
      // !isFocused ||
      !localAppSettings?.installationId ||
      !lastActivityNotif?.installationId ||
      lastActivityNotif?.verb === '' ||
      lastActivityNotif?.installationId === localAppSettings?.installationId ||
      lastActivityNotif.id === lastActivityNotifRef.current.id
    ) {
      console.log('DO NOT SHOW POPOVER');
      // setIsPopoverVisible(true);
      return;
    }
    console.log('SHOW POPOVER-->', isFocused);
    lastActivityNotifRef.current = lastActivityNotif;
    //       installationId: activity.installationId,
    //       message:
    //         activity.subject +
    //         activity.verb +
    //         activity.object +
    //         activity.punctuation,

    // clearTimeout(popoverTimer);
    if (isFocused) {
      setIsPopoverVisible(true);
      popoverTimer = setTimeout(() => {
        console.log('showPopoverNotif setTimeout');

        setIsPopoverVisible(false);
      }, 5000);
    }
  };

  // useEffect(() => {
  //   // setTimeout(() => setIsPopoverVisible(true), 5000);
  // }, []);

  useEffect(() => {
    if (eventRef.current?.eventCode?.length > 0) {
      subscriptionRS?.unsubscribe();
      subscriptionHistory?.unsubscribe();
      subsHistoryItem?.unsubscribe();
      subsHistoryLiked?.unsubscribe();
      subsHistoryRequest?.unsubscribe();
      // popoverTimer?.unsubscribe();
      subsCurrentEvent?.unsubscribe();
      subsCurrentGuest?.unsubscribe();

      // subsCurrentGuest.unsubscribe();
      // subsCurrentEvent.unsubscribe();

      subscriptionRS = DataStore.observeQuery(Song, (song) =>
        song.eventSongId('eq', eventRef.current?.id)
      ).subscribe({
        next: ({ items, isSynced }) => {
          console.log('next subscriptionRS snapshot', items);
          console.log(
            `[Snapshot]subscriptionRS item count: ${items.length}, isSynced: ${isSynced}`
          );
          // dispatch(songActions.setSongsForEvent());
          console.time('updateQueueAndSetUserFavSongs Execution Time');
          if (isSynced)
            dispatch(songActions.updateQueueAndSetUserFavSongs(items));
          console.timeEnd('updateQueueAndSetUserFavSongs Execution Time');
          // isSynced && setTodos(items);
        },
      });

      // .subscribe((snapshot) => {
      //   const { items, isSynced } = snapshot;
      //   console.log('snapshot', snapshot);
      //   console.log(
      //     `[Snapshot] item count: ${items.length}, isSynced: ${isSynced}`
      //   );
      // });

      subsHistoryItem = DataStore.observe(History).subscribe((msg) => {
        if (
          msg.opType === 'INSERT' &&
          msg.element?.type === 'upvote' &&
          //@ts-ignore
          msg.element?.songId
        ) {
          console.log('History subscription item', msg);
          //@ts-ignore
          // dispatch(songActions.addLastVotedSongId(msg.element?.songId));
          setIsRequestSent(true);
          //@ts-ignore
          lastVotedSongIdsRef.current.push(msg.element?.songId);
          setIsRequestSent(false);
        }
      });

      subscriptionHistory = DataStore.observeQuery(
        History,
        (history) => history.eventHistoryId('eq', eventRef.current?.id),
        {
          // sort: (s) => s.updatedAt(SortDirection.DESCENDING),
          sort: (s) => s.postedAt(SortDirection.DESCENDING),
        }
      ).subscribe({
        next: ({ items, isSynced }) => {
          console.log('next subscriptionHistory snapshot', items, isSynced);
          console.log(
            `[Snapshot]subscriptionHistory count: ${items.length}, isSynced: ${isSynced}`
          );

          if (isSynced && items.length > 0 && items[0]?.updatedAt)
            dispatch(appActions.setHistory(items));
        },
      });

      console.log(
        'subsCurrentGuest guestRef.current?.id',
        guestRef.current?.id,
        eventRef.current?.id
      );

      subsHistoryLiked = DataStore.observeQuery(History, (history) =>
        history
          .eventHistoryId('eq', eventRef.current?.id)
          .guestHistoryId('eq', guestRef.current?.id)
          .type('eq', 'upvote')
      ).subscribe((snapshot) => {
        const { items, isSynced } = snapshot;
        console.log('next subsHistoryLiked snapshot', snapshot);
        if (isSynced) {
          dispatch(songActions.setTotalSongUpVotes(items.length));
        }
      });

      subsHistoryRequest = DataStore.observeQuery(History, (history) =>
        history
          .eventHistoryId('eq', eventRef.current?.id)
          .guestHistoryId('eq', guestRef.current?.id)
          .type('eq', 'request')
      ).subscribe((snapshot) => {
        const { items, isSynced } = snapshot;
        if (isSynced) {
          console.log('next subsHistoryRequest snapshot', snapshot);
          dispatch(songActions.setTotalSongRequests(items.length));
        }
      });

      subsCurrentGuest = DataStore.observe(
        Guest,
        // guestRef.current?.id
        (g) => g.id('eq', guestRef.current?.id)
      ).subscribe((msg) => {
        console.log('subsCurrentGuest msg', guestRef.current?.id, msg);
        // if (guestRef.current?.id === msg.element.id)
        dispatch(appActions.saveUser(msg.element));
      });

      subsCurrentEvent = DataStore.observe(
        Event,
        eventRef.current?.id
      ).subscribe((msg) => {
        console.log('subsCurrentEvent msg', msg);
        dispatch(appActions.saveSettings({ currentEvent: msg.element }));
      });
    }

    return () => {
      subscriptionRS.unsubscribe();
      subscriptionHistory.unsubscribe();
      subsHistoryItem.unsubscribe();
      subsCurrentGuest.unsubscribe();
      subsCurrentEvent.unsubscribe();
      subsHistoryLiked.unsubscribe();
      subsHistoryRequest.unsubscribe();
    };
  }, [guestRef.current, guestRef.current]);

  const getWelcomeString = (guest) => {
    let welcomeStr =
      ' You have ' +
      (requestCount < 0 ? 0 : requestCount) +
      (requestCount > 1 ? ' requests and ' : ' request and ') +
      (voteCount < 0 ? 0 : voteCount) +
      (voteCount > 1 ? ' upvotes remaining.' : ' upvote remaining.');

    console.log('getWelcomeString suspEndedStr', suspEndedStr);
    if (guest?.isSuspended)
      welcomeStr =
        (guest?.suspDurationMin > 0 && suspEndedStr?.length > 0
          ? ' You have been suspended until ' + suspEndedStr + '. '
          : ' You have been suspended. ') +
        (guest?.suspReasonMsg?.length > 0
          ? guest.suspReasonMsg
          : 'You are not permitted to interact with this app.');

    console.log('getWelcomeString welcomeStr', welcomeStr);
    return welcomeStr;
  };

  // const onScrollEvent = Animated.event(
  //   [{ nativeEvent: { contentOffset: { y: this.state.animatedValue } } }],
  //   { useNativeDriver: true } // <-- Add this
  // );useAnimatedScrollHandler

  // const flatlistOnScrollHandler = useAnimatedScrollHandler((event) => {
  //   console.log('event.contentOffset.y', event.contentOffset.y);
  //   scrollY.value = event.contentOffset.y;
  // });
  // const flatlistOnScrollHandler = (event) => {
  //   console.log('flatlistOnScrollHandler', event.nativeEvent.contentOffset.y);
  //   scrollY.value = event.nativeEvent.contentOffset.y;
  // };

  // const flatlistOnScrollHandler = useAnimatedScrollHandler({
  //   onScroll: (event) => {
  //     if (lastContentOffset.value > event.nativeEvent.contentOffset.y) {
  //       if (isScrolling.value) {
  //         console.log('UP');
  //       }
  //     } else if (lastContentOffset.value < event.nativeEvent.contentOffset.y) {
  //       if (isScrolling.value) {
  //         console.log('DOWN');
  //       }
  //     }
  //     lastContentOffset.value = event.nativeEvent.contentOffset.y;
  //   },
  //   onBeginDrag: (e) => {
  //     isScrolling.value = true;
  //   },
  //   onEndDrag: (e) => {
  //     isScrolling.value = false;
  //   },
  // });

  return (
    <Screen
      customStyle={[
        {
          position: 'absolute',
          height: '100%',
          width: '100%',
        },
      ]}
    >
      <View style={{ marginHorizontal: 15, marginTop: 15 }}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Text
            fontSize='md'
            fontWeight='light'
            selectable={false}
            color={Colors.lightText}
          >
            <TouchableHighlight
              disabled={isGuestSuspended}
              onPress={() => {
                props.navigation.navigate({
                  name: 'Username',
                  params: {
                    currentEvent: eventRef.current,
                    eventCode: eventRef.current?.eventCode,
                    companyCode: eventRef.current?.companyCode,
                    eventName: eventRef.current?.eventName,
                    apiVersion: eventRef.current?.apiVersion,
                    isNameAlreadyTaken: false,
                  },
                  merge: true,
                });
              }}
            >
              <RNText style={{ fontFamily: 'poppins-semibold' }}>
                {localAppSettings?.userName &&
                localAppSettings?.userName.length > 0
                  ? 'Hi ' + localAppSettings?.userName + '!'
                  : 'Hi!'}
              </RNText>
            </TouchableHighlight>
            {getWelcomeString(guestRef.current)}
          </Text>
          <TouchableOpacity
            style={{ alignSelf: 'flex-end' }}
            disabled={isGuestSuspended}
            ref={touchablePopover}
            onPress={() => {
              console.log('setShowPopover(true)');
              // showPopoverNotif();
              InteractionManager.runAfterInteractions(() => {
                props.navigation.push('History', {
                  ...props.route.params,
                });
              });
            }}
          >
            <Icon
              mr={1}
              ml={2}
              as={Ionicons}
              name='md-people-circle-sharp'
              color={!isGuestSuspended ? Colors.primary : Colors.lightText}
              size={10}
            />
          </TouchableOpacity>
        </View>
        <Animated.View
          style={[
            {
              // position: 'absolute',
              // opacity: 0.5,
              width: '100%',
              // bottom: 0,
              // height: ARTWORK_IMAGE_HEIGHT + safeArea.top + 10 + ROW_HEIGHT,
            },
            { opacity: headerOpacity, height: HeaderPostionY },
          ]}
        >
          <Heading
            size={'lg'}
            fontWeight={700}
            mt={5}
            mb={2}
            selectable={false}
          >
            Request a song
          </Heading>

          <View
            style={{
              top: 0,
              width: '100%',
              marginBottom: 25,
            }}
          >
            <TouchableOpacity
              disabled={isGuestSuspended}
              onPress={() => {
                InteractionManager.runAfterInteractions(() => {
                  // setTimeout(function () {
                  props.navigation.push('Search', {
                    ...props.route.params,
                    // isGuestSuspended: isGuestSuspended,
                  });
                  // }, 1000);
                });
              }}
            >
              <AppInputBox
                disabled={isGuestSuspended}
                pointerEvents='none'
                // containerStyle={{ left: 100, marginLeft: 100 }}
                autoFocus={false}
                onSubmitEditing={Keyboard.dismiss}
                selectTextOnFocus={false}
                editable={false}
                caretHidden={true}
                //   name='userName'
                placeholder='Search a song or an artist.'
                controlType='inputText'
                errMessage='Required'
                iconRight={'magnify'}
                // iconLeft={'balloon'}
                // onSubmitEditing={() => onSearchSubmit()}
                // onRightIconClicked={() => {
                //   console.log('onRightIconClicked');
                //   //   reset();
                // }}
              />
            </TouchableOpacity>
          </View>
        </Animated.View>
        {requestedSongs?.length > 0 && (
          <View
            style={{
              marginTop: 0,
              // marginHorizontal: 15,
              width: '100%',
            }}
          >
            <Heading
              size={'lg'}
              fontWeight={700}
              //   mt={5}
              mb={1}
              selectable={false}
            >
              Most requested songs
            </Heading>
            <Text fontSize={'md'} mb={3} color={Colors.lightText}>
              Select a song to upvote.
            </Text>
          </View>
        )}
      </View>

      {requestedSongs?.length > 0 && (
        <View style={{ flex: 1, width: '100%' }}>
          {(isInProgress || isSuccessVisible) && (
            <TouchableOpacity
              onPress={() => {
                setIsSuccessVisible(false);
                clearTimeout(successVisibleTimer.current);
              }}
              style={globalStyles.modalContainer}
            >
              {isSuccessVisible ? (
                <AnimatePresence>
                  <MotiView
                    style={{ justifyContent: 'center', alignItems: 'center' }}
                    // delay={200}
                    from={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{
                      opacity: 0,
                    }}
                    // delay={2000}
                    transition={{
                      type: 'timing',
                      duration: 0,
                      scale: {
                        type: 'spring',
                        delay: 100,
                        stiffness: 400,
                      },
                    }}
                    onDidAnimate={async (
                      styleProp,
                      didAnimationFinish,
                      maybeValue,
                      { attemptedValue }
                    ) => {
                      // console.log('[moti]', styleProp, didAnimationFinish); // [moti], opacity, true

                      // if (styleProp === 'opacity' && didAnimationFinish) {
                      //   console.log('did animate opacity to: ' + attemptedValue);
                      // }
                      if (styleProp === 'scale' && didAnimationFinish) {
                        // console.log('did animate scale to: ' + attemptedValue);
                        // await myUtils.delay(5);
                        successVisibleTimer.current = setTimeout(function () {
                          setIsInProgress(false);
                          setIsSuccessVisible(false);
                        }, 5000);
                      }
                    }}
                  >
                    <Icon
                      mr={1}
                      as={MaterialCommunityIcons}
                      name='check-bold'
                      color={Colors.primary}
                      size={40}
                    />
                    <Text
                      selectable={false}
                      color={Colors.primary}
                      fontSize='lg'
                    >
                      Your vote has been counted!
                    </Text>

                    <Text
                      mt={4}
                      selectable={false}
                      // style={[globalStyles.titleText, { textAlign: 'center' }]}
                      color={Colors.primary}
                      fontSize='md'
                    >
                      {'You have ' +
                        requestCount +
                        (requestCount > 1
                          ? ' requests and '
                          : ' request and ') +
                        voteCount +
                        (voteCount > 1
                          ? ' upvotes remaining.'
                          : ' upvote remaining.')}
                    </Text>
                  </MotiView>
                </AnimatePresence>
              ) : (
                <Spinner size='lg' color={Colors.primary} />
              )}
            </TouchableOpacity>
          )}

          <View style={{ flex: 1, width: '100%' }}>
            <Animated.FlatList
              // onScroll={flatlistOnScrollHandler}
              // onScroll={(event) => {
              //   console.log(
              //     'flatlistOnScrollHandler',
              //     event.nativeEvent.contentOffset.y
              //   );
              // }}
              onScroll={event([
                {
                  nativeEvent: {
                    contentOffset: {
                      y: animatedScrollIndex,
                    },
                  },
                },
              ])}
              scrollEventThrottle={16}
              // removeClippedSubviews={true} // Unmount components when outside of window
              // initialNumToRender={1} // Reduce initial render amount
              // maxToRenderPerBatch={1} // Reduce number in each render batch
              // updateCellsBatchingPeriod={10} // Increase time between renders
              // windowSize={7} // Reduce the window size
              data={requestedSongs}
              /* Comment out extraData prevent re-rendering of thw whole list*/
              //   extraData={queue}
              //   onDragEnd={onDragEndHandle}
              keyExtractor={flatListKeyExtractor}
              renderItem={RowItem}
              ListHeaderComponentStyle={{
                // flexGrow: 1,
                justifyContent: 'flex-start',
              }}
              ListHeaderComponent={() => {
                return (
                  <View>
                    {/* {requestedSongs?.length > 0 && (
                      <View
                        style={{
                          marginTop: 5,
                          marginHorizontal: 15,
                          width: '100%',
                        }}
                      >
                        <Heading
                          size={'lg'}
                          fontWeight={700}
                          //   mt={5}
                          mb={1}
                          selectable={false}
                        >
                          Most requested songs
                        </Heading>
                        <Text fontSize={'md'} mb={3} color={Colors.lightText}>
                          Select a song to upvote.
                        </Text>
                      </View>
                    )} */}
                  </View>
                );
              }}
              // getItemLayout={getItemLayout}
              //   activationDistance={20}
              //   style={{ flex: 1, width: '100%' }}
              contentContainerStyle={{
                flexGrow: 1,
                paddingBottom: BUTTON_HEIGHT,
              }}
            />
          </View>

          <View
            style={{
              position: 'absolute',
              bottom: 0,
              zIndex: 8,
              width: '100%',
              height: BUTTON_HEIGHT,
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
              backgroundColor: myUtils.applyAlpha(
                colors.defBackgroundColor,
                0.8
              ),
              padding: 0,
              // borderRadius: 35,
            }}
          >
            <Button
              w='80%'
              borderRadius='full'
              isDisabled={
                isGuestSuspended ||
                requestedSongs.length < 1 ||
                !selectedSong ||
                selectedSong?.alreadyVoted ||
                selectedSong?.alreadyRequested ||
                voteCount < 1 ||
                (localAppSettings.isRemoteControlMode &&
                  requestedSongs[0].uri === selectedSong.uri)
              }
              style={{ flex: 1, margin: 6, backgroundColor: colors.main }}
              isLoading={isSendingRequest}
              _text={{
                fontWeight: 700,
                selectable: false,
                color: colors.OxfordBlue,
                fontSize: 20,

                // size: 'lg',
              }}
              isLoadingText='Submitting'
              _loading={{
                bg: 'grey',
                _text: {
                  fontSize: 20,
                  color: colors.OxfordBlue,
                },
              }}
              onPress={onSubmitRequest}
            >
              UPVOTE
            </Button>
          </View>
        </View>
      )}

      {/* <AppButton
        customStyle={styles.addSongButton}
        // disabled={isInProgress}
        contentStyle={
          {
            // height: 50,
            // paddingVertical: 40,
          }
        }
        //icon='pencil'
        mode='contained'
        // onPress={onRefresh}
        //   onPress={onSearchClicked}
      >
        <MaterialCommunityIcons
          name='plus'
          size={36}
          color={colors.backgroundColor}
        />
      </AppButton> */}

      {/* {lastActivityNotifState?.installationId !==
        localAppSettings?.installationId && ( */}
      <Popover
        // arrowSize={{ width: 16, height: 8 }}
        animationConfig={{
          duration: 600,
          easing: Easing.inOut(Easing.quad),
        }}
        popoverStyle={{
          backgroundColor: colors.notifColor,
          borderRadius: 4,
          width: '100%',
          // padding: 10,
        }}
        isVisible={isPopoverVisible}
        mode={PopoverMode.JS_MODAL}
        offset={-8}
        // popoverShift={{ x: -1 }}
        placement={PopoverPlacement.BOTTOM}
        onRequestClose={() => {
          console.log('onRequestClose');
          // hidePopoverNotif();
        }}
        from={touchablePopover}
      >
        {/* {console.log('lastActivityNotifRef::', lastActivityNotifRef.current)} */}
        <TouchableOpacity onPress={() => hidePopoverNotif()}>
          <View style={{ width: '100%', padding: 10 }}>
            <Text style={{ fontSize: 16, fontFamily: 'poppins-light' }}>
              {lastActivityNotifRef.current?.subject +
                lastActivityNotifRef.current?.verb +
                lastActivityNotifRef.current?.object +
                lastActivityNotifRef.current?.punctuation +
                ''}
            </Text>
          </View>
        </TouchableOpacity>
      </Popover>
      {/* )} */}
    </Screen>
  );
};

const styles = StyleSheet.create({
  rowIndexContainer: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 99,
    // width: ICON_WIDTH,
    // height: ICON_WIDTH,
    // right: 12,
  },
  rowIndexText: {
    color: 'rgba(255, 255, 255, 0.8)',
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 2,
    textShadowColor: 'rgba(0, 0, 0, 0.5)',
    fontSize: 34,
    textAlign: 'center',
  },
  explicitIcon: {
    margin: -3,
    padding: -3,
    // borderRadius: 5,
    borderRadius: 20,
  },
  explicitIconContainer: {
    position: 'absolute',
    zIndex: 99,
    bottom: 11,
    right: 10,
    borderRadius: 5,
    overflow: 'hidden',
    backgroundColor: Colors.defBackgroundColor,
  },
  //   addSongButton: {
  //     height: 60,
  //     width: 60,
  //     borderRadius: 30,
  //     alignItems: 'center',
  //     alignSelf: 'center',
  //     justifyContent: 'center',
  //     marginTop: 0,
  //     right: 20,
  //     bottom: 120,
  //     position: 'absolute',
  //     zIndex: 9999,
  //   },
});
export const screenOptions = (navData) => {
  return {
    headerShown: false,
    headerTitle: 'Main',
  };
};

export default MainScreen;
