import Colors from './colors';
import { Platform } from 'react-native';

export default {
  MAX_WIDTH: 700,
  SCALE_FACTOR: Platform.OS === 'web' ? 1.1 : 1,
  DEF_BACKGROUND_COLOR: Colors.lavenderWeb,
  DARK_BACKGROUND_COLOR: Colors.dark_background,

  // OVERSWIPE_DIST: 100,
  // ROW_HEIGHT: 90,
  // ICON_WIDTH: 90,

  // SECONDS_BEFORE_NEXT_IN_QUEUE: 20,
  // HEADER_HEIGHT_NARROWED: 90,
  // HEADER_HEIGHT_EXPANDED: 35,
  // BANNER_H: 200,
  // BANNER_H_SMALL: 120, //Number to deduct from BANNER_H
  // TOPNAVI_H: 50,
  // SNAP_POINT: 100,
  // IS_GAPLESS: true,

  // STD_SCREEN_HEIGHT: 1920,
};

export const OVERSWIPE_DIST = 100;
export const ROW_HEIGHT = 90;
export const ICON_WIDTH = 70;
export const BUTTON_HEIGHT = 60;

export const SECONDS_BEFORE_NEXT_IN_QUEUE = 20;
export const HEADER_HEIGHT_NARROWED = 90;
export const HEADER_HEIGHT_EXPANDED = 35;
export const BANNER_H = 200;
export const BANNER_H_SMALL = 280; //Number to deduct from BANNER_H
export const TOPNAVI_H = 50;
export const SNAP_POINT = 100;
export const IS_GAPLESS = true;
export const ARTWORK_IMAGE_HEIGHT = 200;
