export default {
  // defBackgroundColor: '#688ed6',
  defBackgroundColor: '#3A506B', //'#424242',
  dark_background: '#011627',
  primary: '#6FFFE9', // "#3d7886"
  beauBlue: '#CEE5F2',
  beauBlue2: '#ACCBE1',
  airSupeiorityBlue: '#7C98B3',
  darkElectricBlue: '#637081',
  lightText: 'rgba(255, 255, 255, 0.7)',
  //======
  main: '#6FFFE9', //"#3d7886",
  lavenderWeb: '#D9DBF1',

  lavenderGray: '#D0CDD7',
  cadetBlueCrayola: '#ACB0BD',
  midnightGreen: '#0B3948',
  //======
  deepSquidInk: '#152939',
  linkUnderlayColor: '#FFF',
  errorIconColor: '#DD3F5B',
  rose: '#FF007F',
  error: '#FF007F',
  textInputColor: '#000000',
  textInputBorderColor: '#C4C4C4',
  placeholderColor: '#C7C7CD',
  buttonColor: '#6FFFE9', //"#3d7886",
  disabledButtonColor: '#d1dfe3',
  //=============
  hanPurple: '#6200EE',
  lavenderWeb: '#D8DCFF',
  bluePurple: '#AEADF0',
  puce: '#C38D94',
  roseDust: '#A76571',
  richBlack: '#0E1116',
  battenPink: '#9E7B9B',
  brightLilac: '#CB9CF2',
  mistyRose: '#F9E0D9',
  accent: '#b3b3b3',
  backdrop: '#212121',
  //=======
  OxfordBlue: '#0B132B',
  SpaceCadet: '#1C2541',
  Independence: '#3A506B',
  MaxBlueGreen: '#5BC0BE',
  TurquiseBlue: '#6FFFE9',

  //=====
  flickrPink: '#F72585',
  byzantine: '#B5179E',
  purpleLight: '#7209B7',
  purpleDark: '#560BAD',
  trypanBlueLight: '#480CA8',
  trypanBlueDark: '#3A0CA3',
  persianBlue: '#3F37C9',
  marineBlue: '#4361EE',
  dodgeBlue: '#4895EF',
  vividSkyBlue: '#4CC9F0',
};
