//@ts-check
import React from 'react';
import {
  StyleSheet,
  View,
  // TextInput,
  Text,
  TouchableOpacity,
  Platform,
} from 'react-native';
import { TextInput } from 'react-native-web';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import defaultStyles from '../config/styles';
import colors from '../constants/colors';

const AppTextInput = ({
  style2 = null,
  iconLeft = null,
  iconRight = null,
  width = '100%',
  containerStyle = {},
  ...otherProps
}) => {
  // const inputRef = React.useRef();
  console.log('AppTextInput::::', otherProps);
  return (
    <View
      style={[
        styles.container,
        style2,
        {
          width: width,

          // position: 'absolute',
          // zIndex: -9999,
          backgroundColor: otherProps.disabled
            ? defaultStyles.colors.medium
            : defaultStyles.colors.light,
        },
        containerStyle,
      ]}
    >
      {iconLeft && (
        <TouchableOpacity
          disabled={!otherProps.editable}
          onPress={otherProps.onLeftIconClicked}
        >
          <MaterialCommunityIcons
            name={iconLeft}
            size={30}
            color={colors.defBackgroundColor}
            style={{ marginLeft: 10 }}
          />
        </TouchableOpacity>
      )}

      <TextInput
        autoCorrect={false}
        returnKeyType='done'
        style={[
          defaultStyles.text,
          styles.textInput,
          {
            width: width,
            color: defaultStyles.colors.background,
            zIndex: -9999,
            fontSize: 18,
          },
          //@ts-ignore
          Platform.select({
            web: {
              outlineStyle: 'none',
            },
          }),
        ]}
        {...otherProps}
        // autoFocus={false}
        // controlType='inputText'
        // editable={true}
        // errMessage='Required'
        ref={otherProps.inputRef}
        // ref={(el) => el?.focus()}
      />
      {iconRight && (
        <TouchableOpacity
          disabled={!otherProps.editable}
          // style={{
          //   alignSelf: 'flex-start',
          //   paddingTop: 5,
          // }}
          // mode='contained'
          onPress={otherProps.onRightIconClicked}
        >
          <MaterialCommunityIcons
            name={iconRight}
            size={30}
            color={colors.defBackgroundColor}
            style={{ marginRight: 10 }}
          />
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: defaultStyles.colors.light,
    borderRadius: 5,
    flexDirection: 'row',
    // padding: 15,
    alignItems: 'center',
    justifyContent: 'center',
    // marginVertical: 8,
    // marginHorizontal: 16,
  },
  textInput: {
    flex: 1,
    // backgroundColor: 'red',
    padding: 15,
  },
  icon: {
    marginLeft: 10,
  },
});

export default AppTextInput;
