import React from 'react';
import { View, Text, StyleSheet, Platform } from 'react-native';
import defaultStyles from '../config/styles';

let AppText;
export default AppText = ({
  children,
  controlStyle = null,
  customStyle = null,
  containerStyle = null,
  ...otherProps
}) => {
  const defaultStyle =
    controlStyle === 'title'
      ? defaultStyles.title
      : controlStyle === 'subTitle'
      ? defaultStyles.subTitleText
      : controlStyle === 'boldText'
      ? defaultStyles.boldText
      : controlStyle === 'smallText'
      ? defaultStyles.smallText
      : defaultStyles.text;
  return (
    <View style={[styles.container, containerStyle]}>
      <Text
        numberOfLines={1}
        ellipsizeMode={'tail'}
        style={[defaultStyles.text, defaultStyle, customStyle]}
        {...otherProps}
      >
        {children}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // flexShrink: 1,
    // width: '100%',
    // flex: 1,
    // marginHorizontal: 16,
    // marginVertical: 16,
    // backgroundColor: 'red',
  },
});
