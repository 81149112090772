import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  SafeAreaView,
  View,
  Image,
  StyleSheet,
  InteractionManager,
  ActivityIndicator,
  FlatList,
  TouchableOpacity,
  TouchableHighlight,
  Dimensions,
  Platform,
  ScrollView,
  Keyboard,
  Alert,
  Text as RNText,
} from 'react-native';
import Screen from '../components/Screen';
import {
  Button,
  FormControl,
  Input,
  Center,
  Text,
  NativeBaseProvider,
  Box,
  Modal as NBModal,
  VStack,
  HStack,
  Heading,
  Container,
  Icon,
  //   Image,
  Spinner,
} from 'native-base';
import {
  Ionicons,
  Foundation,
  MaterialIcons,
  MaterialCommunityIcons,
} from '@expo/vector-icons';
import { MotiView, MotiText, AnimatePresence } from 'moti';
import { useForm, Controller } from 'react-hook-form';

import Constants from 'expo-constants';
// import * as Device from 'expo-device';
import AsyncStorage from '@react-native-async-storage/async-storage';

import AppInputBox from '../components/AppInputBox';
import AppText from '../components/AppText';

// import {
//   CodeField,
//   Cursor,
//   useBlurOnFulfill,
//   useClearByFocusCell,
// } from 'react-native-confirmation-code-field';

// import styles from '../styles/inputCode';
import { globalStyles } from '../styles/global';
import * as songActions from '../redux_store/actions/songActions';
import * as appActions from '../redux_store/actions/appActions';

import Colors from '../constants/colors';
import colors from '../config/colors';
import * as myUtils from '../utils/myUtils';
import { useFocusEffect } from '@react-navigation/native';
import AppTextInput from '../components/AppTextInput';

import { ROW_HEIGHT, ICON_WIDTH, BUTTON_HEIGHT } from '../constants/appConst';
// import { background } from 'native-base/lib/typescript/theme/styled-system';
//Role ARN is arn:aws:iam::342143957009:role/SaniSignGetRegCode-Role

const CELL_COUNT = 6;

const SearchScreen = (props) => {
  console.log('SearchScreen props', props);
  // const requestedSongsState = useSelector((state) => {
  //   return state.songsReducer?.requestedSongs;
  // });
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      searchValue: '',
    },
  });
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    control,
    formState,
    // formState: { isDirty, isValid, isSubmitting, errors, submitCount },
  } = form;

  const searchResultState = useSelector((state) => {
    //@ts-ignore
    return state.songsReducer?.searchResults
      ? //@ts-ignore
        state.songsReducer?.searchResults
      : [];
  });
  //   const setIsInProgressState = useSelector((state) => {
  //     //@ts-ignore
  //     return state.songsReducer?.isInProgress;
  //   });
  const appSettingsState = useSelector(
    //@ts-ignore
    (state) => state.appReducer?.appSettings
  );
  const prevSearchTextState = useSelector(
    //@ts-ignore
    (state) => state.songsReducer?.prevSearchText
  );

  const totalVotesState = useSelector(
    //@ts-ignore
    (state) => state.songsReducer?.totalSongUpvotes
  );
  const totalRequestsState = useSelector(
    //@ts-ignore
    (state) => state.songsReducer?.totalSongRequests
  );

  const blockedSongsState = useSelector(
    //@ts-ignore
    (state) => state.songsReducer?.blockedSongs
  );

  const [requestCount, setRequestCount] = useState(
    appSettingsState?.currentEvent?.maxRequest - totalRequestsState
  );
  const [voteCount, setVoteCount] = useState(
    appSettingsState?.currentEvent?.maxLike - totalVotesState
  );

  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  // const [requestCount, setRequestCount] = useState(
  //   appSettingsState?.currentEvent?.maxLike
  // );
  // const [voteCount, setVoteCount] = useState(
  //   appSettingsState?.currentEvent?.maxRequest
  // );
  const [isGuestSuspended, setIsGuestSuspended] = useState(false);
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);
  const [isSuccessVisible, setIsSuccessVisible] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState(
    'Request sent successfully!'
  );
  // const [isSnackbarShowing, setIsSnackbarShowing] = useState(false);
  const [isAlreadyInQueue, setIsAlreadyInQueue] = useState(false);
  const [selectedSong, setSelectedSong] = useState(null);
  //   const [searchText, setSearchText] = useState('');

  const searchText = useRef('');
  const [prevSearchText, setPrevSearchText] = useState(prevSearchTextState);
  // const [requestedSongs, setRequestedSongs] = useState(requestedSongsState);
  const [searchResults, setSearchResults] = useState(searchResultState);
  const [isRequestSent, setIsRequestSent] = useState(false);
  // const [isRequestSentSuccessful, setIsRequestSentSuccessful] = useState(false);
  // const [searchResultList, setQueryState] = React.useState({
  //   data: searchResultState,
  //   // error: null,
  //   // loading: false,
  //   query: '',
  //   fullData: searchResultState,
  // });

  const dispatch = useDispatch();

  // const inputUserNameRef = useRef();
  const inputRef = useRef(null);

  const flatListRef = useRef(null);
  // useEffect(() => {
  //   // setTimeout(function () {
  //   //   inputUserNameRef.current.focus();
  //   // }, 1000);
  // }, []);

  useEffect(() => {
    // console.log('useEffect Keyboard.addListener');

    const showSubscription = Keyboard.addListener('keyboardDidShow', () => {
      // console.log('keyboardDidShow');
      setIsKeyboardVisible(true);
    });
    const hideSubscription = Keyboard.addListener('keyboardDidHide', () => {
      // console.log('keyboardDidHide');
      setIsKeyboardVisible(false);
    });

    // setTimeout(function () {
    //   inputRef.current.focus();
    //   console.log('inputRef.current.focus', inputRef.current);
    // }, 500);

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  useEffect(() => {
    // if (voteCount != appSettingsState?.currentEvent?.maxLike) {
    //   setVoteCount(appSettingsState?.currentEvent?.maxLike - totalVotesState);
    // }
    // if (requestCount != appSettingsState?.currentEvent.maxRequest) {
    //   setRequestCount(
    //     appSettingsState?.currentEvent?.maxRequest - totalRequestsState
    //   );
    // }

    if (
      !appSettingsState?.user?.maxLike ||
      appSettingsState?.user?.maxLike === -1
    ) {
      setVoteCount(appSettingsState?.currentEvent?.maxLike - totalVotesState);
    } else {
      setVoteCount(appSettingsState?.user?.maxLike - totalVotesState);
    }

    if (
      !appSettingsState?.user?.maxRequest ||
      appSettingsState?.user?.maxRequest === -1
    ) {
      setRequestCount(
        appSettingsState?.currentEvent?.maxRequest - totalRequestsState
      );
    } else {
      setRequestCount(appSettingsState?.user?.maxRequest - totalRequestsState);
    }

    console.log('SearchScreen useEffect', appSettingsState?.user);
    setIsGuestSuspended(appSettingsState?.user?.isSuspended);
  }, [appSettingsState, totalVotesState, totalRequestsState]);

  // useEffect(() => {
  //   // setVoteCount(appSettingsState?.currentEvent?.maxLike - totalVotesState);
  //   // setRequestCount(
  //   //   appSettingsState?.currentEvent?.maxRequest - totalRequestsState
  //   // );

  //   if (
  //     !appSettingsState?.user?.maxLike ||
  //     appSettingsState?.user?.maxLike === -1
  //   ) {
  //     setVoteCount(appSettingsState?.currentEvent?.maxLike - totalVotesState);
  //   } else {
  //     setVoteCount(appSettingsState?.user?.maxLike - totalVotesState);
  //   }

  //   if (
  //     !appSettingsState?.user?.maxRequest ||
  //     appSettingsState?.user?.maxRequest === -1
  //   ) {
  //     setRequestCount(
  //       appSettingsState?.currentEvent?.maxRequest - totalRequestsState
  //     );
  //   } else {
  //     setRequestCount(appSettingsState?.user?.maxRequest - totalRequestsState);
  //   }

  // }, [totalVotesState, totalRequestsState]);

  // useEffect(() => {
  //   if (requestCountState < 0) setRequestCount(0);
  //   else setRequestCount(requestCountState);
  // }, [requestCountState]);
  // useEffect(() => {
  //   if (voteCountState < 0) setVoteCount(0);
  //   else setVoteCount(voteCountState);
  // }, [voteCountState]);

  useEffect(() => {
    console.log(
      'blockedSongsState',
      blockedSongsState,
      blockedSongsState.length,
      searchResultState
    );

    dispatch(songActions.updateSearchResult(searchResultState));
  }, [blockedSongsState]);

  useEffect(() => {
    console.log('searchResultState-->', searchResultState);

    if (searchResultState.length > 0) {
      setSearchResults(searchResultState);
      setSelectedSong(searchResultState[0]);
      setIsAlreadyInQueue(searchResultState[0].alreadyInQueue);
    }
    // setTimeout(function () {
    setIsInProgress(false);
    // }, 2000);
  }, [searchResultState]);

  //   useEffect(() => {
  //     setIsInProgress(setIsInProgressState);
  //   }, [setIsInProgressState]);

  const onGoBack = () => {
    InteractionManager.runAfterInteractions(() => {
      props.navigation.pop();
      dispatch(songActions.sortFavList());
    });
  };
  const onSubmitRequest = async () => {
    setIsSendingRequest(true);
    setIsInProgress(true);
    setIsRequestSent(true);

    // await delay(1);
    // setVoteCount(voteCount - 1);
    // setIsInProgress(false);
    // // setIsSnackbarShowing(true);
    // setIsSendingRequest(false);
    // setIsSuccessVisible(true);

    // return;

    // dispatch(songActions.clearSearchResults());
    // setPrevSearchText('');
    // setSearchText('');
    // console.log('selectedSong::', selectedSong, isAlreadyInQueue);
    try {
      // const params = {
      //   ...props.route.params,
      //   userName: props.route.params?.userName,
      // };

      console.log(
        'IS IN QUEUE?',
        isAlreadyInQueue,
        selectedSong?.alreadyInQueue
      );

      if (selectedSong?.alreadyInQueue) {
        dispatch(
          appActions.addActivityHistory(
            'upvote',
            appSettingsState.user.userName,
            ' upvoted ',
            selectedSong?.name,
            '.',
            selectedSong?.info,
            selectedSong?.id
          )
        );

        dispatch(songActions.incrementLikeCount(selectedSong))
          .then(() => {
            selectedSong.alreadyVoted = true;
            // setVoteCount(voteCount - 1);
            setIsSuccessVisible(true);
            // dispatch(
            //   appActions.addHistory(
            //     null,
            //     'upvote',
            //     appSettingsState.user.userName +
            //       ' upvoted ' +
            //       selectedSong?.name +
            //       '.',
            //     selectedSong.info
            //   )
            // );

            // dispatch(
            //   appActions.addActivityHistory(
            //     'upvote',
            //     appSettingsState.user.userName,
            //     ' upvoted ',
            //     selectedSong?.name,
            //     '.',
            //     selectedSong?.info,
            //     selectedSong?.id
            //   )
            // );
          })
          .finally(() => {
            // setIsSnackbarShowing(true);
            // setIsAlreadyInQueue(false);
            setIsInProgress(false);
          });
      } else {
        dispatch(songActions.sendRequest(selectedSong)).then(
          (requestedSong) => {
            console.log('sendRequest requestedSong', requestedSong);
            selectedSong.alreadyRequested = true;
            setRequestCount(requestCount - 1);
            setIsSuccessVisible(true);
            selectedSong.requestedBy = requestedSong.requestedBy;
            // setIsSnackbarShowing(true);
            // setIsAlreadyInQueue(false);
            setIsInProgress(false);
            // dispatch(
            //   appActions.addHistory(
            //     null,
            //     'request',
            //     appSettingsState.user.userName +
            //       ' requested ' +
            //       selectedSong?.name +
            //       '.',
            //     selectedSong.info
            //   )
            // );

            dispatch(
              appActions.addActivityHistory(
                'request',
                appSettingsState.user.userName,
                ' requested ',
                selectedSong?.name,
                '.',
                selectedSong?.info
              )
            );
          }
        );
      }
    } catch (err) {
      console.log('handleSearch error', err);
      // setIsAlreadyInQueue(false);
      setIsInProgress(false);
    } finally {
      setIsSendingRequest(false);
    }
  };

  const handleSearch = (data) => {
    setIsInProgress(true);
    reset();

    Keyboard.dismiss();
    console.log('handleSearch data', data);
    const formattedQuery = data.searchValue; //searchText.current.toLowerCase();
    if (formattedQuery.length === 0 || isGuestSuspended) {
      setIsInProgress(false);
      return;
    }

    // setIsSendingRequest(true);
    setIsRequestSent(false);
    setIsAlreadyInQueue(false);
    setPrevSearchText(formattedQuery);
    flatListRef.current?.scrollToOffset({ animated: false, offset: 0 });
    // setSearchText('');
    // dispatch(songActions.clearSearchResults());

    console.log('search requestCount', requestCount, voteCount, formattedQuery);
    // if (requestCount > 0 || voteCount > 0) {
    try {
      // setIsInProgress(true);
      dispatch(songActions.searchQuery(formattedQuery));
    } catch (err) {
      console.log('handleSearch error', err);
    }
    // } else {
    //   setIsRequestSent(true);
    //   setIsInProgress(false);
    // }
    // const data = requestedSongs.filter((quest) => {
    //   return contains(quest, formattedQuery);
    // });
    console.log('handleSearch', formattedQuery);
    // // setQuery(text);
    // setQueryState({
    //   ...queryState,
    //   // data: data,
    //   query: '',
    // });
  };

  useFocusEffect(
    React.useCallback(() => {
      if (inputRef.current !== null) {
        setTimeout(function () {
          inputRef.current.focus();
          console.log('inputRef.current.focus', inputRef.current);
        }, 500);
      }
    }, [])
  );

  const SearchHeader = () => {
    return (
      <View
        style={{
          width: '100%',
          paddingHorizontal: 15,
        }}
      >
        <AppInputBox
          name='searchValue'
          errors={formState.errors}
          placeholder='Search a song or an artist.'
          form={form}
          controlType='inputText'
          inputRef={inputRef}
          // required={true}
          errMessage='Required'
          // mode='outlined'
          editable={true}
          selectTextOnFocus={true}
          // autoFocus={true}
          // autofocus
          iconLeft={'keyboard-backspace'}
          iconRight={'close'}
          onSubmitEditing={handleSubmit(handleSearch)}
          onLeftIconClicked={onGoBack}
          onRightIconClicked={() => {
            console.log('onRightIconClicked');
            reset();
          }}
          returnKeyType='search'
        />
        {prevSearchText?.length > 0 &&
          // !isInProgress &&
          (requestCount > 0 || voteCount > 0) &&
          !isRequestSent && (
            <Text
              style={{
                margin: 15,
                fontSize: 16,
                fontFamily: 'poppins-light',
                color: 'white',
              }}
            >
              {isInProgress
                ? 'Searching...'
                : searchResults.length > 0
                ? "Search results for '" + prevSearchText + "'"
                : "0 search result for '" + prevSearchText + "'"}

              {/* {isKeyboardVisible ? 'KEYBOARD IS VISIBLE' : 'KEYBOARD IS HIDDEN'} */}
            </Text>
          )}
      </View>
    );
  };
  const RowItem = useCallback(
    ({ item, index }) => {
      // console.log('RowItem-RowItem-RowItem', index);
      return (
        <TouchableOpacity
          // disabled={item.explicit}
          key={item.key}
          onPress={() => {
            console.log('setSelectedSong item', item);
            setSelectedSong(item);
            setIsAlreadyInQueue(item.alreadyInQueue);
          }}
          style={{
            // backgroundColor:
            //   item.uri === selectedSong?.uri ? colors.defBackgroundColor : null,
            backgroundColor:
              selectedSong?.uri === item.uri
                ? myUtils.applyAlpha(colors.defBackgroundColor, 0.5)
                : null,

            // borderBottomWidth: 1,
            // borderColor: colors.defBackgroundColor, //'#424242',
            flex: 1,
            // position: 'absolute',
            width: '100%',
            // justifyContent: 'flex-start',
            // overflow: 'hidden',
            // paddingHorizontal: 10,
          }}
          // onShowUnderlay={separators.highlight}
          // onHideUnderlay={separators.unhighlight}
        >
          <View
            style={{
              //   width: '100%',
              //   height: '100%',
              flex: 1,
              flexDirection: 'row',
              paddingVertical: 10,
              // backgroundColor: 'red',
              borderTopWidth: 1,
              borderColor: myUtils.applyAlpha(Colors.OxfordBlue, 0.5),
            }}
          >
            <View
              style={{
                width: 4,
                height: '100%',
                position: 'absolute',

                left: 0,
                top: 0,
                zIndex: 999,
                backgroundColor:
                  selectedSong?.uri === item.uri ? Colors.primary : null,
              }}
            />

            <View style={{ paddingLeft: 5 }}>
              <Image
                style={{
                  //   flex: 1,
                  borderWidth: 1,
                  borderRadius: 6,
                  borderColor: '#C4C4C4',
                  width: ICON_WIDTH,
                  height: ICON_WIDTH,
                  marginLeft: 10,
                  // marginRight: 40,
                }}
                resizeMode={'contain'}
                source={{ uri: item.imageSmall.url }}
                // alt={item.name}
              />
            </View>
            <View
              style={{
                paddingLeft: 15,
                paddingRight: 5,
                flex: 1,
                // width: '100%',
                // height: '100%',
                // position: 'absolute',
                // backgroundColor: 'red',
              }}
            >
              <View
                style={{
                  width: '100%',
                }}
              >
                <Text
                  isTruncated
                  style={[
                    globalStyles.text1,
                    {
                      color:
                        item.uri === selectedSong?.uri
                          ? Colors.primary
                          : 'white',
                    },
                  ]}
                >
                  {item.name}
                </Text>
              </View>

              <View
                style={{
                  width: '100%',
                }}
              >
                <Text isTruncated selectable={false}>
                  {item.artist}
                </Text>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Text isTruncated color='white'>
                  {item.requestedBy?.length > 0 &&
                  item.requestedBy !== 'Added by host'
                    ? 'Requested by ' + item.requestedBy
                    : item.requestedBy?.length > 0 &&
                      item.requestedBy === 'Added by host'
                    ? item.requestedBy
                    : ''}
                </Text>

                {/* {item.alreadyVoted ? (
                  <View
                    style={{
                      width: 'auto',
                      height: 'auto',
                      paddingHorizontal: 8,
                      borderRadius: 25,
                      marginTop: 4,
                      backgroundColor: colors.amber[300],
                    }}
                  >
                    <RNText
                      style={{ color: 'black', fontFamily: 'poppins-semibold' }}
                    >
                      VOTED
                    </RNText>
                  </View>
                ) : (
                  item.alreadyRequested && (
                    <View
                      style={{
                        width: 'auto',
                        height: 'auto',
                        paddingHorizontal: 8,
                        borderRadius: 25,
                        marginTop: 4,
                        backgroundColor: colors.amber[300],
                      }}
                    >
                      <RNText
                        style={{
                          color: 'black',
                          fontFamily: 'poppins-semibold',
                        }}
                      >
                        REQUESTED
                      </RNText>
                    </View>
                  )
                )} */}
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '100%',
                  marginTop: 4,
                }}
              >
                {item?.isBlocked ? (
                  <View
                    style={globalStyles.tagContainer({
                      backgroundColor: colors.error,
                    })}
                  >
                    <RNText style={globalStyles.tagText}>BLOCKED</RNText>
                  </View>
                ) : item.alreadyVoted ? (
                  <View
                    style={globalStyles.tagContainer({
                      backgroundColor: colors.amber[300],
                    })}
                  >
                    <RNText style={globalStyles.tagText}>UPVOTED</RNText>
                  </View>
                ) : (
                  item.alreadyRequested && (
                    <View
                      style={globalStyles.tagContainer({
                        backgroundColor: colors.amber[300],
                      })}
                    >
                      <RNText style={globalStyles.tagText}>REQUESTED</RNText>
                    </View>
                  )
                )}
                {item.explicit && (
                  <View
                    style={globalStyles.tagContainer({
                      backgroundColor: colors.error,
                    })}
                  >
                    <RNText style={globalStyles.tagText}>EXPLICIT</RNText>
                  </View>
                )}
              </View>
            </View>
          </View>
        </TouchableOpacity>
      );
    },
    [searchResultState, selectedSong]
  );

  const flatListKeyExtractor = useCallback((item) => {
    return item?.uri;
  }, []);

  return (
    <Screen
      customStyle={[
        {
          // height: Platform.OS === 'web' ? '100vh' : '100%',
          backgroundColor: Colors.defBackgroundColor,
          position: 'absolute',
          height: '100%',
          width: '100%',

          // height: global.windowSize,
          // height: Platform.OS === 'web' ? '100vh' : '100%',
          // minHeight: Platform.OS === 'web' ? '100vh' : '100%',
        },
      ]}
    >
      <SearchHeader />

      <TouchableOpacity style={{ flex: 1, width: '100%' }}>
        {(isInProgress || isSuccessVisible) && (
          <TouchableOpacity
            style={globalStyles.modalContainer}
            onPress={() => {
              setIsSuccessVisible(false);
              setIsInProgress(false);
            }}
          >
            {isSuccessVisible ? (
              <AnimatePresence>
                <MotiView
                  style={{ justifyContent: 'center', alignItems: 'center' }}
                  // delay={200}
                  from={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{
                    opacity: 0,
                  }}
                  // delay={2000}
                  transition={{
                    type: 'timing',
                    duration: 0,
                    scale: {
                      type: 'spring',
                      delay: 100,
                      stiffness: 400,
                    },
                  }}
                  onDidAnimate={async (
                    styleProp,
                    didAnimationFinish,
                    maybeValue,
                    { attemptedValue }
                  ) => {
                    // console.log('[moti]', styleProp, didAnimationFinish); // [moti], opacity, true

                    // if (styleProp === 'opacity' && didAnimationFinish) {
                    //   console.log('did animate opacity to: ' + attemptedValue);
                    // }
                    if (styleProp === 'scale' && didAnimationFinish) {
                      console.log('did animate scale to: ' + attemptedValue);
                      await myUtils.delay(5);
                      setIsInProgress(false);
                      setIsSuccessVisible(false);
                    }
                  }}
                >
                  <Icon
                    mr={1}
                    as={MaterialCommunityIcons}
                    name='check-bold'
                    color={Colors.primary}
                    size={40}
                    // style={{}}
                  />
                  <Text
                    selectable={false}
                    // style={[globalStyles.titleText, { textAlign: 'center' }]}
                    color={Colors.primary}
                    fontSize='lg'
                  >
                    {isAlreadyInQueue
                      ? 'Your vote has been counted!'
                      : 'Your song request has been sent!'}
                  </Text>
                  <Text
                    mt={4}
                    selectable={false}
                    // style={[globalStyles.titleText, { textAlign: 'center' }]}
                    color={Colors.primary}
                    fontSize='md'
                  >
                    {'You have ' +
                      requestCount +
                      (requestCount > 1 ? ' requests and ' : ' request and ') +
                      voteCount +
                      (voteCount > 1
                        ? ' upvotes remaining.'
                        : ' upvote remaining.')}
                  </Text>
                </MotiView>
              </AnimatePresence>
            ) : (
              <Spinner size='lg' color={Colors.primary} />
            )}
          </TouchableOpacity>
        )}

        <View style={{ flex: 1, width: '100%' }}>
          <FlatList
            ref={flatListRef}
            data={searchResults}
            keyExtractor={flatListKeyExtractor}
            contentContainerStyle={{
              paddingBottom: BUTTON_HEIGHT,
            }}
            renderItem={RowItem}
          />
        </View>
        {!isKeyboardVisible && (
          <View
            style={{
              position: 'absolute',
              bottom: 0,
              zIndex: 8,
              width: '100%',
              height: BUTTON_HEIGHT,
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
              backgroundColor: myUtils.applyAlpha(
                Colors.defBackgroundColor,
                0.8
              ),
              padding: 0,
              // borderRadius: 35,
            }}
          >
            <Button
              w='80%'
              borderRadius='full'
              isDisabled={
                isGuestSuspended ||
                !selectedSong ||
                selectedSong?.isBlocked ||
                selectedSong?.alreadyVoted ||
                selectedSong?.alreadyRequested ||
                // (selectedSong?.alreadyInQueue &&
                //   !selectedSong?.alreadyRequested &&
                //   requestCount < 1) ||
                // (selectedSong?.alreadyInQueue &&
                //   !selectedSong?.alreadyVoted &&
                //   voteCount < 1) ||
                (voteCount < 1 && requestCount < 1) ||
                (!selectedSong?.alreadyInQueue && requestCount < 1) ||
                (selectedSong?.alreadyInQueue && voteCount < 1)
                // (!selectedSong?.alreadyVoted && voteCount < 1)
              }
              style={{ flex: 1, margin: 6, backgroundColor: colors.main }}
              isLoading={isSendingRequest}
              _text={{
                fontWeight: 700,
                selectable: false,
                color: colors.OxfordBlue,
                fontSize: 22,
              }}
              isLoadingText='Submitting'
              _loading={{
                bg: 'grey',
                _text: {
                  fontSize: 22,
                  color: 'white',
                },
              }}
              onPress={onSubmitRequest}
            >
              {isAlreadyInQueue ? 'UPVOTE' : 'REQUEST'}
            </Button>
          </View>
        )}
      </TouchableOpacity>
      {/* {!isKeyboardVisible && (
        <View
          style={{
            width: '100%',
            justifyContent: 'center',
            backgroundColor: colors.accent,
          }}
        >
          {(isRequestSent || (requestCount < 1 && voteCount < 1)) && (
            <Button
              disabled={isInProgress}
              style={globalStyles.buttonFullWidth}
              //   contentStyle={{
              //     height: 50,
              //     paddingVertical: 40,
              //   }}
              //   mode='contained'
              onPress={onGoBack}
            >
              {!isInProgress && <Text selectable={false}>GO BACK</Text>}
            </Button>
          )}
          {!isRequestSent && (requestCount > 0 || voteCount > 0) && (
            <Button
              disabled={
                !selectedSong ||
                selectedSong?.explicit ||
                searchResults.length === 0 ||
                isInProgress ||
                (requestCount < 1 && voteCount < 1) ||
                (isAlreadyInQueue && voteCount < 1) ||
                (!isAlreadyInQueue && requestCount < 1) ||
                selectedSong?.alreadyRequested ||
                selectedSong?.alreadyVoted
              }
              style={globalStyles.buttonFullWidth}
              //   contentStyle={{
              //     height: 50,
              //     // flex: 1,
              //     // marginHorizontal: 10,
              //     // width: '100%',
              //     // backgroundColor: 'red',
              //     paddingVertical: 40,
              //   }}
              //   //icon='pencil'
              //   mode='contained'
              onPress={onSubmitRequest}
            >
              {!isInProgress && (
                <Text selectable={false}>
                  {isAlreadyInQueue ? 'Upvote' : 'Request'}
                </Text>
              )}
            </Button>
          )}
        </View>
      )} */}
    </Screen>
  );
};

// const styles = StyleSheet.create({
//   screen: {
//     // backgroundColor: "red",
//     flex: 1,
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   Container: {
//     alignItems: "center",
//     backgroundColor: "#F5FCFF",
//     flex: 1,
//     justifyContent: "center",
//   },
//   root: { flex: 1, padding: 20 },
//   title: { textAlign: "center", fontSize: 30 },
//   codeFieldRoot: { marginTop: 20 },
//   cell: {
//     width: 80,
//     height: 80,
//     lineHeight: 38,
//     fontSize: 48,
//     borderWidth: 2,
//     borderColor: "#00000030",
//     textAlign: "center",
//   },
//   focusCell: {
//     borderColor: "#000",
//   },
// });

export const screenOptions = (navData) => {
  return {
    headerShown: false,
    headerTitle: 'Search',
  };
};

// const style = StyleSheet.create({
//   row1: {
//     // flex: 1,
//     // borderRightWidth: 1,
//     // borderBottomWidth: 1,
//     // borderColor: '#C4C4C4',
//     // padding: 5,
//     // alignItems: 'center',
//     // justifyContent: 'center',
//   },
//   row2: {
//     // flex: 1,
//     // borderRightWidth: 1,
//     // borderBottomWidth: 1,
//     // borderColor: '#C4C4C4',
//     // padding: 5,
//     marginRight: 10,
//     // alignItems: 'center',
//     // justifyContent: 'center',
//   },
//   text1: {
//     fontSize: 16,
//     // textAlign: 'center',
//     color: 'white',
//     fontFamily: 'poppins-regular',
//   },
//   text2: {
//     fontSize: 14,
//     color: 'white',
//     // textAlign: 'center',
//     fontFamily: 'poppins-light',
//   },
//   largeText: {
//     fontSize: 22,
//     color: 'rgba(255, 255, 255, 0.6)',
//     textAlign: 'right',
//     fontFamily: 'poppins-regular',
//   },
//   container: {
//     flex: 1,
//   },
//   slideContainer: {
//     flex: 1,
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
// });

export default SearchScreen;
