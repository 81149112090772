import React from 'react';
import { StyleSheet, SafeAreaView, View } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import Constants from 'expo-constants';
import colors from '../config/colors';

const Screen = ({ children, customStyle = null }) => {
  return (
    <SafeAreaView style={[styles.screen, customStyle]}>
      {/* <StatusBar
      // translucent
      // backgroundColor='transparent'
      // animated={true}
      // backgroundColor={colors.main}
      // animatedProps={animStatusBarProp}
      // barStyle={'light-content'}
      /> */}
      <View style={[styles.view]}>{children}</View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: colors.defBackgroundColor,
    paddingVertical: 8,
  },
  view: {
    flex: 1,

    // marginHorizontal: 16,
  },
});
export default Screen;
