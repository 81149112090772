import moment, { now } from 'moment';
import { syncRS } from '../../src/graphql/queries';
import { Song, History, GuestLikedSong } from '../../src/models';
import { DataStore } from '@aws-amplify/datastore';
import * as myUtils from '../../utils/myUtils';
import { firstBy } from 'thenby';
import { API, graphqlOperation, AUTH_MODE } from 'aws-amplify';
import * as mutations from '../../src/graphql/mutations';

var Spotify = require('spotify-web-api-js');
var spotifyApi = new Spotify();
const clone = require('rfdc')();

import * as spotifyUtils from '../../utils/spotifyUtils';

import * as appActions from '../actions/appActions';
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';
export const SET_IS_IN_PROGRESS = 'SET_IS_IN_PROGRESS';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const ADD_TO_QUEUE = 'ADD_TO_QUEUE';
export const CLEAR_QUEUE = 'CLEAR_QUEUE';
export const ADD_TRACKS_TO_QUEUE = 'ADD_TRACKS_TO_QUEUE';
export const SET_QUEUE = 'SET_QUEUE';
export const ADD_PLAYLIST = 'ADD_PLAYLIST';
export const MARK_SONG_UNAVAILABLE = 'MARK_SONG_UNAVAILABLE';
export const REMOVE_SONG_FROM_QUEUE = 'REMOVE_SONG_FROM_QUEUE';
export const MARK_SONG_AS_PLAYED = 'MARK_SONG_AS_PLAYED';
export const SET_PLAYLISTS = 'SET_PLAYLISTS';
export const SET_ISLOADING_QUEUES = 'SET_ISLOADING_QUEUES';
export const UPDATE_SONG = 'UPDATE_SONG';
export const SET_NOW_PLAYING = 'SET_NOW_PLAYING';
export const SET_SNAPSHOT = 'SET_SNAPSHOT';
export const SET_NEXT_TO_PLAY = 'SET_NEXT_TO_PLAY';
export const SORT_FAV_LIST = 'SORT_FAV_LIST';
export const SET_USER_FAV_SONGS = 'SET_USER_FAV_SONGS';
export const DECREMENT_USER_REQUEST_COUNT = 'DECREMENT_USER_REQUEST_COUNT';
export const DECREMENT_USER_VOTE_COUNT = 'DECREMENT_USER_VOTE_COUNT';
export const REMOVE_LAST_VOTED_SONG_ID = 'REMOVE_LAST_VOTED_SONG_ID';
export const SET_TOTAL_SONG_UPVOTES = 'SET_TOTAL_SONG_UPVOTES';
export const SET_TOTAL_SONG_REQUESTS = 'SET_TOTAL_SONG_REQUESTS';

// export const ADD_LAST_VOTED_SONG_ID = 'ADD_LAST_VOTED_SONG_ID';

function padZero(num, lead = 3, trail = 3) {
  // (A1) CONVERT NUMBER TO STRING
  var cString = num.toString();

  // (A2) GET NUMBER OF DIGITS
  var cWhole,
    cDec,
    cCheck = cString.indexOf('.');

  // NO DECIMAL PLACES, A WHOLE NUMBER
  if (cCheck == -1) {
    cWhole = cString.length;
    cDec = 0;
    if (trail > 0) cString += '.';
  }
  // IS A DECIMAL NUMBER
  else {
    cWhole = cCheck;
    cDec = cString.substr(cCheck + 1).length;
  }

  // (A3) PAD WITH LEADING ZEROES
  if (cWhole < lead) {
    for (let i = cWhole; i < lead; i++) {
      cString = '0' + cString;
    }
  }

  // (A4) PAD WITH TRAILING ZEROES
  if (cDec < trail) {
    for (let i = cDec; i < trail; i++) {
      cString += '0';
    }
  }

  return cString;
}

var prevSearch = null;

// export const addLastVotedSongId = (songId) => {
//   return async (dispatch, getState) => {
//     //Needed to add if statement coz DataStore.observe(History).subscribe is triggered on initial app run
//     if (getState().appReducer?.appSettings?.userName)
//       dispatch({
//         type: ADD_LAST_VOTED_SONG_ID,
//         lastVotedSongId: songId,
//       });
//   };
// };
export const removeLastVotedSongId = (songId) => {
  return async (dispatch, getState) => {
    dispatch({
      type: REMOVE_LAST_VOTED_SONG_ID,
      votedSongId: songId,
    });
  };
};
export const sortFavList = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: SORT_FAV_LIST,
    });
  };
};

export const incrementLikeCount = (song) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log('incrementLikeCount--->', song, appSettings);
        const appSettings = getState().appReducer?.appSettings;
        const currentEvent = appSettings?.currentEvent;
        const currentUser = appSettings?.user;

        const installationId = appSettings?.installationId;

        //Check if already liked/voted
        const guestLikedSong = await DataStore.query(
          GuestLikedSong,
          (item) =>
            item.uri('eq', song.uri).guestGuestLikedSongId('eq', currentUser.id)

          // .guestGuestLikedSongId('eq', currentUser.id)
          // .eventGuestLikedSongId('eq', currentEvent.id)
          //             eventId: currentEvent.id,
          // guestId: currentUser.id,
          // installationId: installationId,
          // songId: updatedSong.id,
          // uri: updatedSong.uri,
        );
        console.log(
          'incrementLikeCount query(GuestLikedSong):',
          guestLikedSong
        );
        if (guestLikedSong.length > 0) {
          console.log(
            'incrementLikeCount song already liked',
            guestLikedSong[0]
          );
          resolve();
          return;
        }

        //Get existing song to update
        let existingSong = await DataStore.query(Song, (item) =>
          item.uri('eq', song.uri).eventCode('eq', currentEvent.eventCode)
        );
        if (existingSong.length > 0) {
          console.log('existingSong-->', existingSong, {
            ...song,
            ...existingSong[0],
          });
          song = { ...song, ...existingSong[0] };
        }

        console.log('updated song--->', song);

        const likeCount = song.likeCount + 1;
        // const sortHash =
        //   song.statusCode + '-' + likeCount + '-' + song.sortIndex;

        // const songUpdateObj = {
        //   id: song.id,
        //   statusCode: song.statusCode,
        //   likeCount: likeCount,
        //   sortIndex: song.sortIndex + likeCount,
        //   // sortHash: sortHash,
        //   // _version: song._version,
        // };

        // console.log(
        //   'IncrimentLikeCount_GraphQl songUpdateObj-->',
        //   songUpdateObj
        // );
        // const updatedSong = await API.graphql({
        //   query: mutations.updateSong,
        //   variables: { input: songUpdateObj },
        //   // authMode: 'AWS_IAM',
        // });

        // const updatedSong = await API.graphql(
        //   graphqlOperation(mutations.updateSong, {
        //     input: songUpdateObj,
        //     // authMode: 'AWS_IAM',
        //   })
        // );

        const updatedSong = await DataStore.save(
          Song.copyOf(existingSong[0], (song) => {
            // console.log('updateSongStatusCodeAWS song', song);
            song.likeCount = likeCount;
          })
        );
        // .then((updatedSong) => {
        //   console.log('incrementLikeCount SUCCESS!', updatedSong);
        // })
        // .catch((error) => {
        //   console.log('incrementLikeCount ERROR!', error);
        // });

        console.log('incrementLikeCount updatedSong::', updatedSong);

        try {
          DataStore.save(
            new GuestLikedSong({
              eventId: currentEvent.id,
              guestId: currentUser.id,
              songId: updatedSong.id,
              eventGuestLikedSongId: currentEvent.id,
              guestGuestLikedSongId: currentUser.id,
              songGuestLikedSongId: updatedSong.id,
              installationId: installationId,
              uri: updatedSong.uri,
            })
          ).then((guestLikedSong) => {
            console.log(
              'guestLikedSong!',
              guestLikedSong,
              installationId,
              updatedSong.id
            );
          });
        } catch (error) {
          console.log('addToQueue DataStore.save ERROR', error);
          reject(error);
          return;
        }

        if (
          updatedSong?.data?.updateSong &&
          existingSong.likeCount === updatedSong?.likeCount &&
          existingSong._version !== updatedSong?._version
        ) {
          console.log(
            'IncrimentLikeCount_GraphQl version is different, trying again...',
            existingSong._version,
            updatedSong?._version
          );
          incrementLikeCount(updatedSong?.updateSong);
        }

        dispatch({
          type: DECREMENT_USER_VOTE_COUNT,
        });

        console.log('incrementLikeCount updatedSong', updatedSong);

        resolve();
      } catch (err) {
        console.log(err);
        console.log('Error increasing like count!');
        reject(err);
      }
    });
  };
};

export const sendVote = async (_params, data) => {
  console.log('sendVote params1', _params, data);
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      const url =
        'https://xo615nbxgj.execute-api.us-east-1.amazonaws.com/v1/songrequest';
      console.log('sendVote params2', _params);
      const dataObj = {
        id: data.id,
        companyCode: _params.companyCode,
        eventCode: _params.eventCode,
        votedBy: _params.userName,
        installationId: _params.installationId,
        name: data.name,
        artist: data.artist,
      };
      console.log('sendVote dataObj', dataObj);

      const ApiParams = {
        msg: 'SEND_VOTE',
        data: dataObj,
        params: _params,
      };

      console.log('sendVote ApiParams', ApiParams);
      const params = {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(ApiParams),
        method: 'POST',
        // mode: 'no-cors',
      };
      console.log('sendVote params', params);
      try {
        fetch(url, params).then((apiResponse) => {
          if (apiResponse && apiResponse.ok) {
            apiResponse.json().then((json) => {
              console.log('sr response ----> ', json, data);

              dispatch({
                type: DECREMENT_VOTE_COUNT,
              });
              dispatch({
                type: ADD_USER_VOTED_SONG_ID,
                votedSong: data,
              });
              resolve();
            });
          } else {
            console.log('response NOT OK', apiResponse);
            reject();
          }
        });
      } catch (error) {
        console.log('response error', error);
      }
    });
  };
};

// SET_TOTAL_SONG_REQUESTS;
export const setTotalSongRequests = (totalSongRequests) => {
  return (dispatch, getState) => {
    // return new Promise(async (resolve, reject) => {
    dispatch({
      type: SET_TOTAL_SONG_REQUESTS,
      totalSongRequests: totalSongRequests,
    });
    // });
  };
};

export const setTotalSongUpVotes = (totalSongUpvotes) => {
  return (dispatch, getState) => {
    // return new Promise(async (resolve, reject) => {
    console.log('setTotalSongUpVotes', totalSongUpvotes);
    dispatch({
      type: SET_TOTAL_SONG_UPVOTES,
      totalSongUpvotes: totalSongUpvotes,
    });
    // });
  };
};

export const sendRequest = (song) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log('sendRequest--->', song);
        const appSettings = getState().appReducer?.appSettings;
        const currentEvent = appSettings?.currentEvent;
        const currentUser = appSettings?.user;

        const existingSongs = DataStore.query(Song, (s) =>
          s.uri('eq', song.uri).eventSongId('eq', currentEvent.id)
        );

        if (existingSongs.length > 0) {
          console.log('Song ' + song.name + ' already requested!');
          resolve();
          return;
        }

        const queueCopy = getState().songsReducer?.queue;
        const _sortIndex = queueCopy[queueCopy.length - 1]?.sortIndex
          ? queueCopy[queueCopy.length - 1].sortIndex + 1
          : 1;

        delete song.id;
        const songToSave = {
          ...song,
          sortIndex: _sortIndex,
          companyCode: currentEvent.companyCode,
          eventCode: currentEvent.eventCode,

          eventId: currentEvent.id,
          userId: currentEvent.userId,
          eventSongId: currentEvent.id,
          userSongId: currentEvent.userId,

          guestSongId: currentUser.id,
          requestedBy: currentUser.userName,
          // requestedById: currentUser.id,
        };
        myUtils.removeEmptyOrNull(songToSave);

        console.log("Song doesn't exists!", songToSave);

        try {
          const createdSong = await DataStore.save(new Song(songToSave));

          console.log('DataStore.save! createdSong', createdSong);
          dispatch({
            type: ADD_TO_QUEUE,
            song: clone(createdSong),
          });

          dispatch({
            type: DECREMENT_USER_REQUEST_COUNT,
          });

          resolve(createdSong);
        } catch (error) {
          console.log('addToQueue DataStore.save ERROR', error);
          reject(error);
        }
      } catch (err) {
        console.log(err);
        console.log('Error increasing like count!');
        reject(err);
      }
    });
  };
};

export const sendRequest_old = async (_params, data) => {
  console.log('sendRequest params1 data', _params, data);
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      const url =
        'https://xo615nbxgj.execute-api.us-east-1.amazonaws.com/v1/songrequest';
      console.log('sendRequest params2', _params);
      const dataObjToSave = {
        ...data,
        eventCode: _params.eventCode,
        companyCode: _params.companyCode,
        requestedBy: _params.userName,
        installationId: _params.installationId,
        isPlayingInt: 0,
        likeCount: 1,
      };
      const dataObjToSend = {
        ...dataObjToSave,
        imageLarge: JSON.stringify(data.imageLarge),
        imageMedium: JSON.stringify(data.imageMedium),
        imageSmall: JSON.stringify(data.imageSmall),
      };
      delete dataObjToSend.alreadyRequested;
      delete dataObjToSend.alreadyVoted;
      delete dataObjToSend.alreadyInQueue;
      delete dataObjToSend.info;
      console.log('dataObjToSend', dataObjToSend);
      // delete dataObj.id;
      // imageLarge: AWSJSON;
      // imageMedium: AWSJSON;
      // imageSmall: AWSJSON;
      const ApiParams = {
        msg: 'SEND_REQUEST',
        data: dataObjToSend,
        params: _params,
      };
      const params = {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(ApiParams),
        method: 'POST',
        // mode: 'no-cors',
      };

      dispatch({
        type: ADD_USER_REQUESTED_SONG_ID,
        requestedSong: dataObjToSave,
      });

      try {
        fetch(url, params).then((apiResponse) => {
          if (apiResponse && apiResponse.ok) {
            apiResponse.json().then((json) => {
              console.log('sr response ----> ', json, data);

              // data.id =
              //   _params.companyCode + '-' + _params.eventCode + '-' + data.id;
              data.likeCount = 1;
              dispatch({
                type: DECREMENT_REQUEST_COUNT,
              });

              resolve();
            });
          } else {
            console.log('response NOT OK', apiResponse);
            dispatch({
              type: REMOVE_USER_REQUESTED_SONG_ID,
              requestedSong: data,
            });
            reject();
          }
        });
      } catch (error) {
        console.log('response error', error);
      }
    });
  };
};

const updateSongStatusCodeAWS = (statusCode, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      //Get song data from DataStore
      const existingSong = await DataStore.query(Song, (song) =>
        song.uri('eq', data.uri).eventCode('eq', data.eventCode)
      );

      console.log('updateSongStatusCodeAWS existingSong', existingSong);
      if (existingSong.length > 0) {
        myUtils.removeEmptyOrNull(existingSong[0]);
        DataStore.save(
          Song.copyOf(existingSong[0], (song) => {
            // console.log('updateSongStatusCodeAWS song', song);
            song.statusCode = statusCode;
            // song.likeCount = existingSong[0].likeCount;
            // // song.sortIndex = -1;
            // song.requestedBy = '';
            // song.votedBy = '';
            // song.isAccepted = true;
            // song.isRejected = false;
            // song.isPlayingInt = 0;
            // song.isPlaying = false;
            song.isPlayed = statusCode === 2 ? 2 : 0;
            song.isNotAvailable = statusCode === 3 ? 1 : 0;
            // song.popularity = existingSong[0].popularity;
            // song.sortHash = song.statusCode + '-' + padZero(song.sortIndex);
            // song.sortHash =
            //   song.statusCode +
            //   padZero(existingSong[0].likeCount, 3, 0) +
            //   '-' +
            //   padZero(song.sortIndex);
          })
        )
          .then((updatedSong) => {
            console.log('updateSongStatusCodeAWS SUCCESS!', updatedSong);
          })
          .catch((error) => {
            console.log('updateSongStatusCodeAWS ERROR!', error);
          });
      }
      resolve();
    } catch (error) {
      console.log('updateSongStatusCodeAWS ERROR->', error);
      reject();
    }
  });
};

export const updateSongInAWS = (song) => {
  console.log('updateSong-->', song);

  return new Promise(async (resolve, reject) => {
    try {
      //Get song data from DataStore
      const existingSong = await DataStore.query(Song, (_song) =>
        _song.uri('eq', song.uri).eventCode('eq', song.eventCode)
      );

      console.log('updateSongStatusCodeAWS data 2', song, existingSong);

      if (existingSong.length > 0) {
        // const songCopy = clone(existingSong[0]);
        // myUtils.removeEmptyOrNull(songCopy);
        // console.log('removeEmptyOrNull', songCopy);
        DataStore.save(
          Song.copyOf(existingSong[0], (_song) => {
            _song.likeCount = song?.likeCount;
            _song.sortIndex = song?.sortIndex;
            _song.statusCode = song?.statusCode ? song?.statusCode : 1;
            //statusCode", "likeCount", "sortIndex"
            // _song.sortHash =
            //   _song.statusCode +
            //   '-' +
            //   // padZero(_song.likeCount, 3, 0) +
            //   padZero(_song.sortIndex);
            // _song.sortHash =
            //   _song.statusCode +
            //   padZero(existingSong[0].likeCount, 3, 0) +
            //   '-' +
            //   padZero(_song.sortIndex);
          })
        ).then((updatedSong) => {
          console.log('updateSongInAWS SUCCESS!', updatedSong);
        });
        resolve();
      }
    } catch (error) {
      console.log('updateSongInAWS ERROR', error);
      reject();
    }
  });
  // dispatch({
  //   type: UPDATE_SONG,
  //   song: song,
  // });
  // // updateSongInAWS(-1, data);
};

export const updateSong = (song) => {
  return async (dispatch, getState) => {
    updateSongInAWS(song);
  };
};

export const removeSongFromQueue = (song) => {
  // console.log('makeSongUnavailable selectedSong', event, song);
  return async (dispatch, getState) => {
    console.log('removeSongFromQueue songId', song.id, song);

    // DataStore.delete(Song, (item) => item.id('eq', song.id)).then(
    //   (deletedSong) => {
    //     console.log('removeSongFromQueue deletedSong::', deletedSong);
    //   }
    // );

    // return;
    const songToDelete = await DataStore.query(Song, song.id);
    console.log('removeSongFromQueue songToDelete', songToDelete);
    // if (!songToDelete) return;

    try {
      const deletedSong = await DataStore.save(
        Song.copyOf(songToDelete, (_song) => {
          _song.isDeletedInt = 1;
          // _song._deleted = true;
          _song.statusCode = 9;
          // _song.sortHash =
          //   _song.statusCode +
          //   '-' +
          //   // padZero(_song.likeCount, 3, 0) +
          //   padZero(songToDelete.sortIndex);
          // _song.sortHash =
          //   _song.statusCode +
          //   padZero(songToDelete.likeCount, 3, 0) +
          //   '-' +
          //   padZero(songToDelete.sortIndex);
        })
      );

      // const deletedSongs = await DataStore.delete(Song, (item) =>
      //   item.id('eq', song.id)
      // ).then((deletedSongs) => {
      //   console.log('removeSongFromQueue deletedSong -->>', deletedSongs);
      //   if (deletedSongs && deletedSongs.length > 0) {
      //     dispatch({
      //       type: REMOVE_SONG_FROM_QUEUE,
      //       song: deletedSongs[0],
      //     });
      //     console.log('removeSongFromQueue songToDelete -->>', deletedSongs[0]);
      //   }
      // });

      if (deletedSong) {
        dispatch({
          type: REMOVE_SONG_FROM_QUEUE,
          song: deletedSong,
        });
        console.log('removeSongFromQueue songToDelete -->>', deletedSong);
      }

      // })
      // .catch((err) => {
      //   console.log('removeSongFromQueue ERROR', err);
      // });
    } catch (error) {
      console.log('DataStore.delete(Song) ERROR', error);
    }
  };
};

export const makeSongUnavailable = (song) => {
  // console.log('makeSongUnavailable selectedSong', event, song);
  return async (dispatch, getState) => {
    dispatch({
      type: MARK_SONG_UNAVAILABLE,
      song: song,
    });
    updateSongStatusCodeAWS(3, song);
    // await appActions.checkSpotifyTokenSaveAppSettings(dispatch, getState);
    // const appSettings = getState().appReducer?.appSettings;
    // const snapshot_id = getState().songsReducer?.snapshot_id;
    // const uris = [song.uri];
    //Add tracks to spotify playlist
    // spotifyUtils.removeTracksFromSpotifyPlaylist(
    //   appSettings?.currentEvent.playlistId,
    //   uris,
    //   snapshot_id
    // );
  };
};

export const setNextToPlayUri = (nextToPlayUri) => {
  return async (dispatch, getState) => {
    console.log('setNextToPlayUri::', nextToPlayUri);
    if (nextToPlayUri)
      dispatch({
        type: SET_NEXT_TO_PLAY,
        nextToPlayUri: nextToPlayUri?.uri ? nextToPlayUri?.uri : nextToPlayUri,
      });
  };
};
export const setNowPlayingSong = (song) => {
  console.log('setNowPlayingSong 0', song);
  return async (dispatch, getState) => {
    if (!song) return null;
    if (!song?.imageLarge && (!song || !song?.item)) return null;

    // const songItems = processSongItems(dispatch, getState, [song]);
    const songToSet = {
      ...song,
      ...song?.item,
    };

    console.log('setNowPlayingSong 1', songToSet);
    songToSet.isPlaying = true; //--> Will be replaced if song is from playback status.
    const processedSong = songToSet?.imageLarge
      ? songToSet
      : processSongItem(songToSet);
    console.log('setNowPlayingSong 2', processedSong);

    // const firstInQueue = getState().songsReducer?.queue[0];
    // console.log(
    //   'song.uri === nowPlaying.uri',
    //   songToSet?.uri,
    //   firstInQueue?.uri,
    //   songToSet.uri === firstInQueue?.uri
    // );
    // if (firstInQueue && songToSet.uri === firstInQueue.uri) {
    //   console.log('songToSet.uri === firstInQueue.uri markSongAsPlayedOnly');
    //   dispatch(markSongAsPlayedOnly(firstInQueue));
    // } else
    //
    dispatch({
      type: SET_NOW_PLAYING,
      song: processedSong,
    });
  };
};

export const setNowPlayingToNull = () => {
  console.log('setNowPlayingToNull');
  return async (dispatch, getState) => {
    dispatch({
      type: SET_NOW_PLAYING,
      song: null,
    });
  };
};

export const markSongAsPlayedOnly2 = (song) => {
  // console.log('makeSongUnavailable selectedSong', event, song);
  return async (dispatch, getState) => {
    dispatch({
      type: MARK_SONG_AS_PLAYED,
      song: song,
    });
    updateSongStatusCodeAWS(2, song);
    // await appActions.checkSpotifyTokenSaveAppSettings(dispatch, getState);
    // const appSettings = getState().appReducer?.appSettings;
    // const snapshot_id = getState().songsReducer?.snapshot_id;
    // const uris = [song.uri];
    //Add tracks to spotify playlist
    // spotifyUtils.removeTracksFromSpotifyPlaylist(
    //   appSettings?.currentEvent.playlistId,
    //   uris,
    //   snapshot_id
    // );
  };
};

export const markSongAsPlayedOnly = (song) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log('markSongAsPlayedOnly song', song);
        //Remove tracks to spotify playlist

        // const appSettings = getState().appReducer?.appSettings;
        // const snapshot_id = getState().songsReducer?.snapshot_id;
        // const uris = [song.uri];
        // spotifyUtils.removeTracksFromSpotifyPlaylist(
        //   appSettings?.currentEvent.playlistId,
        //   uris,
        //   snapshot_id
        // );

        dispatch({
          type: MARK_SONG_AS_PLAYED,
          song: song,
        });
        updateSongStatusCodeAWS(2, song);
        resolve();
      } catch (error) {
        console.log('markSongAsPlayed ERROR', error);
        // reject(error);
        reject(getState().songsReducer.queue);
      }
    });
  };
};
export const markSongAsPlayed = (song) => {
  console.log('markSongAsPlayed selectedSong', song);
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      // await appActions.checkSpotifyTokenSaveAppSettings(dispatch, getState);
      console.log(
        'SET_IS_IN_PROGRESS:',
        getState().songsReducer?.isInProgress,
        song.name
      );

      if (getState().songsReducer?.isInProgress) {
        resolve(false);
        return;
      }

      dispatch({
        type: SET_IS_IN_PROGRESS,
        isInProgress: true,
      });

      // dispatch(appActions.playSpotifyPlayList(song))
      dispatch(appActions.playSong(song))
        .then(() => {
          console.log('markSongAsPlayed retVal');
          //Remove tracks to spotify playlist
          // setTimeout(function () {
          //   const appSettings = getState().appReducer?.appSettings;
          //   const snapshot_id = getState().songsReducer?.snapshot_id;
          //   const uris = [song.uri];
          //   spotifyUtils.removeTracksFromSpotifyPlaylist(
          //     appSettings?.currentEvent.playlistId,
          //     uris,
          //     snapshot_id
          //   );
          // }, 1000);
          dispatch({
            type: MARK_SONG_AS_PLAYED,
            song: song,
          });
          updateSongStatusCodeAWS(2, song);
          dispatch({
            type: SET_IS_IN_PROGRESS,
            isInProgress: false,
          });
          resolve(true);
        })
        .catch((err) => {
          console.log('markSongAsPlayed retVal Error', err);
          dispatch({
            type: SET_IS_IN_PROGRESS,
            isInProgress: false,
          });
          setNowPlayingToNull();
          // reject(err);
          reject(getState().songsReducer?.queue);
        });
    });
  };
};

export const skipToNextTrack = () => {
  console.log('skipToNextTrack');
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await appActions.checkSpotifyTokenSaveAppSettings(dispatch, getState);

      spotifyUtils
        .skipToNext()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          // dispatch(setToastAlert(error, 'error'));
          console.log('skipToNextTrack ERROR', error);
          dispatch(appActions.setToastAlert(error, 'error'));
          // reject(error);
          reject(getState().songsReducer?.queue);
        });
    });
  };
};

// export const updateQueueOnDataStore = (queue) => {
//   return async (dispatch, getState) => {
//     console.log('updateQueueOnDataStore', queue);
//     dispatch({
//       type: SET_QUEUE,
//       tracks: queue,
//     });
//   };
// };

export const setSongsForEvent = (
  updateReduxStore = true,
  eventId,
  showProgress = true
) => {
  return async (dispatch, getState) => {
    console.log('setSongsForEvent-->', eventId);
    if (showProgress) {
      dispatch({
        type: SET_ISLOADING_QUEUES,
        isLoadingQueues: true,
      });
    }
    const appSettings = getState().appReducer?.appSettings;
    const currentEvent = appSettings?.currentEvent;
    if (!eventId) {
      console.log('setSongsForEvent appSettings', appSettings);
      eventId = currentEvent?.id;
    }

    if (!eventId) {
      console.log('eventId is EMPTY!');
      return;
    }
    console.log('eventId is ', eventId);

    //Get all song for current event
    const eventSongs = await DataStore.query(Song, (song) =>
      song.eventSongId('eq', eventId).isDeletedInt('ne', 1)
    );

    dispatch({
      type: SET_QUEUE,
      tracks: eventSongs,
      // likedSongs: results.length > 1 ? results[1] : [],
      // guestId: currentUser?.id ? currentUser.id : null,
    });
  };
};

export const updateQueueAndSetUserFavSongs = (songs) => {
  return async (dispatch, getState) => {
    // if (showProgress) {
    //   dispatch({
    //     type: SET_ISLOADING_QUEUES,
    //     isLoadingQueues: true,
    //   });
    // }

    let songsInQueue = clone(songs);
    const appSettings = getState().appReducer?.appSettings;
    if (!songs)
      songsInQueue = getState().songsReducer?.queue
        ? clone(getState().songsReducer?.queue)
        : [];
    else {
    }
    const currentEvent = appSettings?.currentEvent;
    const currentGuest = appSettings?.user;

    console.log('setUserFavSongs appSettings', appSettings);

    if (!currentEvent?.id) {
      console.log('currentUser or currentEvent IS EMPTY!', currentEvent?.id);
      return;
    }

    let user_voted_song_uris = [];
    let user_requested_song_uris = [];
    if (currentGuest?.id) {
      //Get all guest's liked songs for current event
      const likedSongs = await DataStore.query(GuestLikedSong, (gls) =>
        gls
          .guestGuestLikedSongId('eq', currentGuest.id)
          .eventGuestLikedSongId('eq', currentEvent.id)
      );

      if (likedSongs.length > 0)
        user_voted_song_uris = likedSongs.map((song) => song.uri);

      console.log(
        'setUserFavSongs user_voted_song_uris',
        user_voted_song_uris,
        likedSongs
      );

      //Get requested songs from queue local Redux store
      const userRequestedSong = songsInQueue.filter(
        (song) => song.guestSongId === currentGuest.id
      );
      console.log('setUserFavSongs userRequestedSong', userRequestedSong);

      if (userRequestedSong) {
        user_requested_song_uris = userRequestedSong.map((song) => song.uri);
      }

      for (let i = 0; i < songsInQueue.length; i++) {
        songsInQueue[i].alreadyVoted = user_voted_song_uris.includes(
          songsInQueue[i].uri
        );
        songsInQueue[i].alreadyRequested = user_requested_song_uris.includes(
          songsInQueue[i].uri
        );
      }
    }

    console.log('processed songsInQueue -->', songsInQueue);

    dispatch({
      type: SET_QUEUE, //SET_USER_FAV_SONGS,
      // updatedQueue: songsInQueue,
      tracks: songsInQueue,
      likedSongs: user_voted_song_uris,
      requestedSongs: user_requested_song_uris,
    });
  };
};

export const reorderTracksInPlaylist = (song, _from, _to) => {
  console.log('reorderTracksInPlaylist', song, _from, _to);
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      const appSettings = getState().appReducer?.appSettings;

      await appActions.checkSpotifyTokenSaveAppSettings(dispatch, getState);

      try {
        //Reorder tracks to spotify playlist
        await spotifyUtils.reorderTracksInSpotifyPlaylist(
          song,
          appSettings?.currentEvent.playlistId,
          1, //rangeLength
          _from, //rangeStart
          _to
        ); //insertBefore

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };
};

export const addToQueue = (song) => {
  return async (dispatch, getState) => {
    const appSettings = getState().appReducer?.appSettings;

    DataStore.query(Song, (song) =>
      song.uri('eq', song.uri).eventSongId('eq', appSettings?.currentEvent.id)
    ).then(async (dsQueue) => {
      console.log('addToQueue', dsQueue, song);

      const queueCopy = getState().songsReducer?.queue;
      const _sortIndex = queueCopy[queueCopy.length - 1]?.sortIndex
        ? queueCopy[queueCopy.length - 1].sortIndex + 1
        : 1;
      console.log('_sortIndex', _sortIndex);
      let songToSave;

      //If song IS NEW or doesn't exists or haven't added yet
      const statusCode = queueCopy.length === 0 ? 0 : 1;
      if (dsQueue.length === 0) {
        delete song.id;
        songToSave = {
          ...song,
          statusCode: statusCode,
          sortIndex: _sortIndex,
          companyCode: appSettings?.currentEvent.companyCode,
          eventCode: appSettings?.currentEvent.eventCode,

          eventId: appSettings?.currentEvent.id,
          userId: appSettings?.currentEvent.User.id,
          guestId: appSettings?.currentEvent.User.id,

          eventSongId: appSettings?.currentEvent.id,
          userSongId: appSettings?.currentEvent.User.id,
          guestSongId: appSettings?.currentEvent.User.id,
          //statusCode", "likeCount", "sortIndex"
          // sortHash:
          //   statusCode + '-' + padZero(0, 3, 0) + '-' + padZero(_sortIndex),

          // spotifySongId: song.id,
        };
        myUtils.removeEmptyOrNull(songToSave);

        console.log("Song doesn't exists!", songToSave);
        try {
          DataStore.save(new Song(songToSave)).then((dsSong) => {
            console.log('DataStore.save! dsSong', dsSong);
            songToSave.id = dsSong.id;
          });
        } catch (error) {
          console.log('addToQueue DataStore.save ERROR', error);
        }
      } else {
        //Song already exists!
        myUtils.removeEmptyOrNull(dsQueue[0]);
        songToSave = {
          ...dsQueue[0],
          ...song,
          sortIndex: _sortIndex,
          companyCode: appSettings?.currentEvent.companyCode,
          eventCode: appSettings?.currentEvent.eventCode,
          // id: song.id,
          // spotifySongId: song.id,
          statusCode: dsQueue[0].statusCode === 0 ? 0 : 1,
          isDeletedInt: 0,
          // _deleted: false,
          // sortHash: '1-0' + _sortIndex,
        };
        // delete songToSave._deleted;
        console.log('Song already exists!', songToSave);

        // await appActions.checkSpotifyTokenSaveAppSettings(dispatch, getState);
        // const uris = [songToSave.uri];
        // //Add tracks to spotify playlist
        // spotifyUtils
        //   .addTracksToSpotifyPlaylist(appSettings?.currentEvent.playlistId, uris)
        //   .then((snapshot_id) => {
        updateSongInAWS(songToSave);
        //   dispatch({
        //     type: SET_SNAPSHOT,
        //     snapshot_id: snapshot_id,
        //   });
        // });
      }
      console.log('ADD_TO_QUEUE:: songToSave', songToSave);
      dispatch({
        type: ADD_TO_QUEUE,
        song: songToSave,
      });
      // dispatch(
      //   appActions.addHistory(
      //     null,
      //     'track',
      //     appSettings.userName + ' requested ' + songToSave.name + '.',
      //     ''
      //   )
      // );

      dispatch(
        appActions.addActivityHistory(
          'track',
          appSettings.userName,
          ' requested ',
          songToSave.name,
          '.',
          songToSave.info
        )
      );
    });
  };
};

export const clearQueue = (eventId) => {
  return async (dispatch, getState) => {
    try {
      console.log('clearQueue eventCode', eventId);
      const queuedSongs = await DataStore.query(Song, (song) =>
        song.eventSongId('eq', eventId)
      );
      console.log('queuedSongs', queuedSongs);

      for (let idx = 0; idx < queuedSongs.length; idx++) {
        DataStore.save(
          Song.copyOf(queuedSongs[idx], (_song) => {
            _song.isDeletedInt = 1;
            // _song.isDeleted = true;
            // _song._deleted = true;
            _song.statusCode = 9;
            // _song.sortHash =
            //   _song.statusCode +
            //   '-' +
            //   // padZero(_song.likeCount, 3, 0) +
            //   padZero(_song.sortIndex);

            // _song.sortHash =
            //   _song.statusCode +
            //   padZero(queuedSongs[idx].likeCount, 3, 0) +
            //   '-' +
            //   padZero(queuedSongs[idx].sortIndex);
          })
        );
      }

      dispatch({
        type: CLEAR_QUEUE,
      });

      // await DataStore.delete(Song, (item) =>
      //   // item.eventCode('eq', eventCode)
      //   item.eventSongId('eq', eventId)
      // ).then((deletedSong) => {
      //   console.log('DataStore.delete(Song) deletedSong', deletedSong);
      // });
    } catch (error) {
      console.log('DataStore.delete(Song) ERROR', error);
    }
  };
};

export const addPlaylistTracks = (playlist) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await appActions.checkSpotifyTokenSaveAppSettings(dispatch, getState);

      spotifyUtils
        .getSpotifyPlaylistTracks(playlist.id, {
          limit: 50,
          // fields:
          //   'items(album,alreadyInFavList,alreadyRequested,alreadyVoted,artist,explicit,id,imageLarge,imageMedium,imageSmall,info,likeCount,name,popularity,releaseDate,uri',
        })
        .then(async (tracks) => {
          // dispatch(appActions.isSyncPending(true));
          console.log(
            'getPlaylistTracks playlist tracks 111',
            playlist,
            tracks
          );

          // const songItems = processSongItems(dispatch, getState, tracks);
          // console.log('songItems', songItems);
          // console.log('queueSongUrisCopy', queueSongUrisCopy);

          const requestedSongUris =
            getState().songsReducer?.userRequestedSongUris;
          const votedSongUris = getState().songsReducer?.userVotedSongUris;
          const queueSongUris = getState().songsReducer?.queueSongUris;
          const blockedSongs = getState().songsReducer?.blockedSongs;

          const appSettings = getState().appReducer?.appSettings;
          // const queueSongUrisCopy = clone(
          //   getState().songsReducer?.queueSongUris
          // );
          const queueCopy = getState().songsReducer?.queue;
          const lastSortIndex =
            queueCopy.length === 0
              ? 1
              : queueCopy[queueCopy.length - 1]?.sortIndex;

          const localDS = await DataStore.query(
            Song,
            // (song) => song.eventCode('eq', appSettings?.currentEvent.eventCode)
            (song) => song.eventSongId('eq', appSettings?.currentEvent.id)

            // .statusCode('ne', -1)
            // .statusCode('ne', 1)
          );

          console.log('queueCopy', localDS, queueCopy, lastSortIndex);

          // dispatch(
          //   appActions.addHistory(
          //     null,
          //     'playlist',
          //     'Host added tracks from ' + playlist.name + ' playlist.',
          //     playlist.description
          //   )
          // );

          dispatch(
            appActions.addActivityHistory(
              'playlist',
              'Host',
              ' added tracks from ',
              playlist.name,
              ' playlist.',
              playlist.description
            )
          );

          let newSong;
          const songItems = [];
          let item = {};
          let localSongIdx = -1;
          tracks.sort(firstBy('popularity', 'desc'));
          // tracks.forEach((song) => {
          for (let i = 0; i < tracks.length; i++) {
            localSongIdx = localDS.findIndex(
              (item) => item.uri === tracks[i].uri
            );

            // console.log(
            //   localSongIdx !== -1
            //     ? 'Exists? YES! -> process'
            //     : 'Exists? NO! -> skipping:',
            //   localSongIdx,
            //   localSongIdx === -1 ? 'none' : localDS[localSongIdx]?.name,
            //   tracks[i].name,
            //   localDS[localSongIdx],
            //   tracks[i]
            // );

            //If already exists then skip!
            // if (localSongIdx !== -1) continue;

            try {
              item = processSongItem(tracks[i]);
              item.alreadyRequested = requestedSongUris.includes(item.uri);
              item.alreadyVoted = votedSongUris.includes(item.uri);
              item.alreadyInQueue = queueSongUris.includes(item.uri);
              item.isBlocked = blockedSongs?.find((i) => i.uri === item.uri);

              item.sortIndex = lastSortIndex + i;
              item.companyCode = appSettings?.currentEvent.companyCode;
              item.eventCode = appSettings?.currentEvent.eventCode;
              item.eventId = appSettings?.currentEvent.id;
              item.userId = appSettings?.currentEvent.User.id;
              item.guestId = appSettings?.currentEvent.User.id;
              item.userSongId = appSettings?.currentEvent.User.id;
              item.eventSongId = appSettings?.currentEvent.id;
              item.guestSongId = appSettings?.currentEvent.User.id;
              // song.playlistId = playlist.id;
              // console.log('track to add', item.uri);
              //statusCode", "likeCount", "sortIndex"
              // item.sortHash = '1-' + padZero(0, 3, 0) + padZero(item.sortIndex);
              // item.sortHash = '0-' + padZero(item.sortIndex);

              // item.sortHash =
              //   '0-' + padZero(0, 3, 0) + '-' + padZero(item.sortIndex);

              //DOES NOT exists locally
              if (localSongIdx === -1) {
                console.log('DataStore INSERTED', item.id);
                //remove the Spotify Id and use DataStore ID instead
                // delete item.id;
                myUtils.removeEmptyOrNull(item);
                newSong = await DataStore.save(new Song(item));
              }
              //DOES exists locally
              else {
                myUtils.removeEmptyOrNull(localDS[localSongIdx]);
                console.log('DataStore UPDATED', localDS[localSongIdx]);
                newSong = await DataStore.save(
                  Song.copyOf(localDS[localSongIdx], (_song) => {
                    _song.likeCount = item?.likeCount;
                    _song.sortIndex = item?.sortIndex;
                    _song.statusCode = item?.statusCode ? item?.statusCode : 1;
                    //statusCode", "likeCount", "sortIndex"
                    // _song.sortHash =
                    //   _song.statusCode +
                    //   '-' +
                    //   // padZero(_song.likeCount, 3, 0) +
                    //   padZero(_song.sortIndex);

                    // _song.sortHash =
                    //   _song.statusCode +
                    //   '-' +
                    //   padZero(0, 3, 0) +
                    //   '-' +
                    //   padZero(_song.sortIndex);
                  })
                );
              }
              console.log('newSong.id', newSong.id);
              item.id = newSong.id;
              songItems.push(item);
              // if (
              //   localSongIdx > -1 &&
              //   localDS[localSongIdx].statusCode !== -1 &&
              //   localDS[localSongIdx].statusCode !== 1
              // )

              // queueSongUrisCopy.push(item.uri);
            } catch (err) {
              console.error('addPlaylistTracks error', err);
            }
          }

          console.log('track to add', songItems);

          dispatch({
            type: ADD_TRACKS_TO_QUEUE,
            tracks: songItems,
          });
          dispatch({
            type: ADD_PLAYLIST,
            playlist: playlist,
          });

          // for (let index = 0; index < songItems.length; index++) {
          //   const song = songItems[index];

          //   try {
          //     song.companyCode = appSettings?.currentEvent.companyCode;
          //     song.eventCode = appSettings?.currentEvent.eventCode;
          //     // song.spotifySongId = song.id;
          //     song.playlistId = playlist.id;

          //     DataStore.save(new Song(song));
          //   } catch (err) {
          //     console.error(err);
          //   }
          // }

          // console.log(
          //   'addTracksToSpotifyPlaylist playlist.id, uris',
          //   appSettings?.currentEvent.playlistId,
          //   uris
          // );

          // await appActions.checkSpotifyTokenSaveAppSettings(dispatch, getState);

          // //Add tracks to spotify playlist
          // spotifyUtils.addTracksToSpotifyPlaylist(
          //   appSettings?.currentEvent.playlistId,
          //   uris
          // );

          resolve();
        })
        .catch((err) => {
          console.log('addPlaylistTracks retVal Error', err);
          reject(err);
        });
    });
  };
};

export const clearSearchResults = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: CLEAR_SEARCH_RESULTS,
    });
  };
};

export const setIsInProgress = (isInProgress) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      dispatch({
        type: SET_IS_IN_PROGRESS,
        isInProgress: isInProgress,
      });
      resolve();
    });
  };
};

export const searchQuery = (searchText) => {
  return async (dispatch, getState) => {
    // dispatch({
    //   type: SET_IS_IN_PROGRESS,
    //   isInProgress: true,
    // });
    const appSettings = getState().appReducer?.appSettings;
    console.log('spotifyParams appSettings', appSettings);
    await searchSpotify(dispatch, getState, appSettings, searchText);
  };
};

const delay = (retryCount) =>
  new Promise((resolve) => setTimeout(resolve, 10 ** retryCount));

export const searchSpotify = async (
  dispatch,
  getState,
  appSettings,
  searchText,
  retryCount = 0,
  lastError = null
) => {
  console.log('searchSpotify retryCount', retryCount);
  if (retryCount > 5) {
    dispatch({
      type: SET_IS_IN_PROGRESS,
      isInProgress: false,
    });
    throw new Error(lastError);
  }

  const { currentEvent } = appSettings;

  // if (!accessToken)
  //   accessToken = getState().appReducer?.appSettings?.accessToken;

  const isExpired = moment()
    .utc()
    .isAfter(currentEvent.spotifySession.expiresAt);

  console.log(
    'searchSpotify',
    currentEvent.spotifySession,
    searchText,
    currentEvent.spotifySession.expiresAt,
    isExpired,
    appSettings?.currentEvent
    // getState().appReducer?.appSettings?.accessToken
  );

  let spotifySession = currentEvent.spotifySession;
  if (isExpired) {
    spotifySession = await dispatch(
      appActions.getSpotifyAccessToken(appSettings?.currentEvent?.id)
    );
    console.log('isExpired spotifySession', spotifySession);
    currentEvent.spotifySession = spotifySession;
  }

  spotifyApi.setAccessToken(spotifySession.token);

  if (prevSearch !== null) {
    prevSearch.abort();
  }
  //   const trackName = 'track:' + searchText;
  //   const asrtistName = 'artist:' + searchText;
  // store the current promise in case we need to abort it
  prevSearch = spotifyApi.searchTracks(searchText, {
    limit: 10,
  });

  prevSearch.then(
    (data) => {
      console.log('searchTracks->', data, searchText);
      // clean the promise so it doesn't call abort
      prevSearch = null;

      // ...render list of search results...
      processSearchResult(dispatch, getState, data.tracks.items, searchText);
    },
    async (err) => {
      const responseObj = JSON.parse(err.response);
      console.log(
        'responseObj.error.message --->>>',
        appSettings,
        responseObj.error.message,
        retryCount
      );
      if (responseObj.error.message.includes('expired')) {
        ///BQCYAFOLcBXR6MMk-v_TIh5SapNIZY5RhWO3hMoFogwbF9oTh77V4ir0zwsoejRbQVA5WAfRmUVivzZ70yo
        await delay(retryCount);

        try {
          const newSpotifySession = await dispatch(
            appActions.getSpotifyAccessToken(appSettings?.currentEvent?.id)
          );

          console.log('newSpotifySession', newSpotifySession);

          appSettings.currentEvent.spotifySession = newSpotifySession;

          searchSpotify(
            dispatch,
            getState,
            appSettings,
            searchText,
            retryCount + 1,
            err.response
          );
        } catch (error) {
          dispatch({
            type: SET_IS_IN_PROGRESS,
            isInProgress: false,
          });
          throw new Error(error);
        }
      }
    }
  );
};

const processSongItem = (element) => {
  const item = {};
  //   let idx = -1;
  // idx = -1;
  // item.id = element.id;
  item.name = element.name;
  item.popularity = element.popularity;
  item.likeCount = 0;

  item.explicit = element.explicit;
  item.artist = Array.isArray(element.artists)
    ? element.artists[0].name
    : element.artist;
  item.album = element.album.name;
  item.releaseDate = element.album.release_date;
  item.imageLarge = element.album.images[0];
  item.imageMedium = element.album.images[1];
  item.imageSmall = element.album.images[2];
  // item.alreadyInFavList = idx > -1;
  item.duration_ms = element?.duration_ms;
  item.uri = element.uri;
  item.info =
    //   idx > -1 && getState().songsReducer?.queue[idx]?.requestedBy
    //     ? 'Requested by ' + getState().songsReducer?.queue[idx]?.requestedBy
    //     :
    '(' + element.album.release_date.slice(0, 4) + ') ' + element.album.name;

  // item.info =
  //   idx > -1
  //     ? 'Requested by ' + getState().songsReducer?.queue[idx]?.requestedBy
  //     : element.album.name +
  //       ' (' +
  //       element.album.release_date.slice(0, 4) +
  //       ')';

  item.progress_ms = element.progress_ms ? element.progress_ms : null;
  item.repeat_state = element.repeat_state ? element.repeat_state : null;
  item.shuffle_state = element.shuffle_state ? element.shuffle_state : null;
  item.isPlaying = element?.is_playing !== null ? element.is_playing : null;
  item.device = element.device ? element.device : null;
  item.isFromApp = !(element?.item !== null && element?.item !== undefined);
  //  progress_ms: song?.progress_ms,
  //     repeat_state: song?.repeat_state,
  //     shuffle_state: song?.shuffle_state,
  //     is_playing: song?.is_playing,
  //     device: song?.device,
  // isFromApp: song?.item !== null || song?.item !== undefined,

  return item;
};

// export const flagRequestedFields = (objArray, songReducerState) => {
//   console.log('parseAWSJSONfields 0', objArray, songReducerState);
//   if (objArray.length > 0) {
//     objArray.forEach((element) => {
//       element.alreadyRequested =
//         songReducerState.userRequestedSongUris.includes(element.uri);
//       element.alreadyVoted = songReducerState.userVotedSongUris.includes(
//         element.uri
//       );
//       element.alreadyInQueue = songReducerState.queueSongUris?.includes(
//         element.uri
//       );
//     });
//   }
//   return objArray;
// };

const processSongItems = (
  dispatch,
  getState,
  tracks,
  isProcessSongs = true
) => {
  const resultList = [];
  console.log('processSongItems tracks', tracks);
  const requestedSongUris = getState().songsReducer?.userRequestedSongUris;
  const votedSongUris = getState().songsReducer?.userVotedSongUris;
  const queueSongUris = getState().songsReducer?.queueSongUris;
  const blockedSongs = getState().songsReducer?.blockedSongs;
  const playedSongs = getState().songsReducer?.playedSongs;

  // const companyCode =
  //   getState().appReducer?.appSettings?.currentEvent?.companyCode;
  // const eventCode = getState().appReducer?.appSettings?.currentEvent?.eventCode;

  let item = {};
  tracks.forEach((element) => {
    if (isProcessSongs) item = processSongItem(element);
    else item = element;
    // item.id = companyCode + '-' + eventCode + '-' + element.uri;

    item.alreadyRequested =
      requestedSongUris.includes(item.uri) &&
      !playedSongs?.find((i) => i.uri === item.uri);
    item.alreadyVoted =
      votedSongUris.includes(item.uri) &&
      !playedSongs?.find((i) => i.uri === item.uri);
    item.alreadyInQueue = queueSongUris.includes(item.uri);
    item.isBlocked = blockedSongs?.find((i) => i.uri === item.uri);

    console.log('item.isBlocked', item.isBlocked, blockedSongs, item.uri);

    if (item.alreadyInQueue)
      item.requestedBy = item.uri.requestedBy ? item.uri.requestedBy : '';

    // idx = queueSongUris.indexOf(item.uri);

    resultList.push(item);
  });

  console.log('processSearchResult', resultList);
  return resultList;
};

export const updateSearchResult = (songs) => {
  return async (dispatch, getState) => {
    const resultList = processSongItems(dispatch, getState, songs, false);
    console.log('processSongItems resultList', resultList);
    dispatch({
      type: SET_SEARCH_RESULTS,
      songs: resultList,
      isInProgress: false,
    });
  };
};

const processSearchResult = (dispatch, getState, songs, searchText) => {
  const resultList = processSongItems(dispatch, getState, songs);
  console.log('processSongItems resultList', resultList);
  dispatch({
    type: SET_SEARCH_RESULTS,
    songs: resultList,
    searchText: searchText,
    isInProgress: false,
  });
  // dispatch({
  //   type: SET_IS_IN_PROGRESS,
  //   isInProgress: false,
  // });
};

export const getPlaylist = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await appActions.checkSpotifyTokenSaveAppSettings(dispatch, getState);

      spotifyUtils
        .getSpotifyPlaylists()
        .then((playlists) => {
          console.log('getPlaylist playlist', playlists);
          const filteredPlaylists = playlists.filter((item) => {
            return !item.name.includes('SongRequest:');
          });
          console.log('filteredPlaylists', filteredPlaylists);

          dispatch({
            type: SET_PLAYLISTS,
            playlists: filteredPlaylists,
          });
          resolve();
        })
        .catch((err) => {
          console.log('getPlaylist retVal Error', err);
          reject(err);
        });
    });
  };
};
