//https://dev.to/bhatvikrant/redux-persist-v6-in-detail-react-10nh

// Imports: Dependencies
import { combineReducers } from 'redux';
// Imports: Reducers
import songsReducer from './reducers/songReducers';
// import usersReducer from './reducers/users';
import appReducer from './reducers/appReducers';
// import guestsReducer from './reducers/guests';
// import deviceReducer from './reducers/devices';

// Redux: Root Reducer
const rootReducer = combineReducers({
  songsReducer: songsReducer,
  // guestsReducer: guestsReducer,
  // deviceReducer: deviceReducer,
  // usersReducer: usersReducer,
  appReducer: appReducer,
});

// Exports
export default rootReducer;
