if (!__DEV__) {
  console.log = () => null;
}
import { StatusBar } from 'expo-status-bar';
import { v4 as uuid } from 'uuid';
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useLayoutEffect,
} from 'react';
import Amplify, { Auth, Hub, Logger } from 'aws-amplify';
import * as Font from 'expo-font';
import { store, persistor } from './redux_store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { StyleSheet, Text, View, Dimensions } from 'react-native';
import { useWindowSize as useWindowSizeD } from '@react-hook/window-size';
import { NavigationContainer } from '@react-navigation/native';

import AnimatedAppLoader from './components/AnimatedAppLoader';
import AppToast from './components/AppToast';
import QRCodeModal from './components/QRCodeModal';
import SplashScreen from './screens/SplashScreen';

import config from './src/aws-exports';
import * as myUtils from './utils/myUtils';
import colors from './config/colors';

import * as songActions from './redux_store/actions/songActions';
import * as appActions from './redux_store/actions/appActions';

import {
  NativeBaseProvider,
  useTheme,
  extendTheme,
  useToast,
} from 'native-base';

import AppNavigator from './navigation/AppNavigator';

import { Event, Song, History } from './src/models';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';

// window.LOG_LEVEL = 'DEBUG';
// Amplify.Logger.LOG_LEVEL = 'INFO';

const backup = console.error;

//Ignore certain console errors / warnings in React?
console.error = function filterWarnings(msg) {
  const supressedWarnings = ['React does not recognize the'];
  // console.log('filterWarnings', msg);
  if (
    !supressedWarnings.some((entry) => {
      // console.log('xxxxx-->', msg, msg.includes(entry));
      return msg.includes(entry);
    })
  ) {
    backup.apply(console, arguments);
  }
};

const fontConfig = {
  regular: {
    fontFamily: 'poppins-regular',
    fontWeight: 'normal',
    fontSize: 24,
  },
  medium: {
    fontFamily: 'poppins-medium',
    fontWeight: 'normal',
    fontSize: 24,
  },
  light: {
    fontFamily: 'poppins-light',
    fontWeight: 'normal',
    fontSize: 24,
  },
  thin: {
    fontFamily: 'poppins-extralight',
    fontWeight: 'normal',
    fontSize: 24,
  },
};

config.aws_appsync_authenticationType = 'AWS_IAM';
delete config.oauth;
console.log('config', config);
Amplify.configure({
  ...config,
  Analytics: {
    disabled: true,
  },
});

// let subscriptionRS;
// let subscriptionHistory;
// let subsHistory;

global.windowSize = Dimensions.get('window');
const defWebsocketUrl =
  'wss://0595ssmpbl.execute-api.us-east-1.amazonaws.com/production';

export default function AppWrapper() {
  const myTheme = extendTheme({
    colors: {
      // ...NBColors,
      ...colors,
    },
    fontConfig: {
      Poppins: {
        100: {
          normal: 'poppins-light',
          italic: 'poppins-light',
        },
        200: {
          normal: 'poppins-light',
          italic: 'poppins-light',
        },
        300: {
          normal: 'poppins-light',
          italic: 'poppins-light',
        },
        400: {
          normal: 'poppins-light',
          italic: 'poppins-light',
        },
        500: {
          normal: 'poppins-medium',
        },
        600: {
          normal: 'poppins-medium',
          italic: 'poppins-medium',
        },
        700: {
          normal: 'poppins-semibold',
        },
        800: {
          normal: 'poppins-semibold',
          italic: 'poppins-semibold',
        },
        //   900: {
        //     normal: 'Roboto-Bold',
        //     italic: 'Roboto-BoldItalic',
        //   },
      },
    },
    fonts: {
      heading: 'Poppins',
      body: 'Poppins',
      mono: 'Poppins',
    },

    config: {
      // Changing initialColorMode to 'dark'
      initialColorMode: 'dark',
    },
  });

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/* <AnimatedAppLoader image={require('./assets/splash.png')}> */}
        <NativeBaseProvider config={config} theme={myTheme}>
          <App />
        </NativeBaseProvider>
        {/* </AnimatedAppLoader> */}
      </PersistGate>
    </Provider>
  );
}

function App() {
  const dispatch = useDispatch();

  const isQrCodeVisibleState = useSelector((state) => {
    return state?.appReducer?.isQrCodeVisible;
  });

  const currentEventState = useSelector((state) => {
    return state?.appReducer?.appSettings?.currentEvent;
  });

  const [isQRCodeVisible, setIsQRCodeVisible] = useState(isQrCodeVisibleState);

  // const [initialScreen, setInitialScreen] = useState(null);
  const [defaultParams, setDefaultParams] = useState(null);

  useEffect(() => {
    console.log('AppDataStoreListener useEffect');
    const AppDataStoreListener = async (hubData) => {
      const { event, data } = hubData.payload;

      console.log('App listener hubData-->', event, data, hubData.payload);

      //https://docs.amplify.aws/lib/datastore/datastore-events/q/platform/js/#syncqueriesready
      switch (event) {
        case 'outboxMutationEnqueued':
          // console.log('outboxMutationEnqueued', data.isEmpty);
          dispatch(appActions.isSyncPending(true));
          break;
        case 'outboxStatus':
          if (data.isEmpty) dispatch(appActions.isSyncPending(!data.isEmpty));
          break;
        case 'networkStatus':
          dispatch(appActions.setNetworkStatus(data.active));
          break;
        case 'subscriptionsEstablished':
          console.log('subscriptionsEstablished::', data);
          break;
        // case 'modelSynced':
        //   console.log('modelSynced::', data.model.name, data.isFullSync);
        //   if (data.model.name === 'Song') {
        //     console.log('setSongsForEvent+ 1');
        //     // dispatch(songActions.setSongsForEvent());
        //     console.log('modelSynced-Song');
        //   } else if (data.model.name === 'History') {
        //     // dispatch(appActions.setHistoryForEvent());
        //     console.log('modelSynced-History');
        //   } else if (data.model.name === 'GuestLikedSong') {
        //     console.log('modelSynced-GuestLikedSong');
        //     // dispatch(songActions.setUserFavSongs());
        //   }
        //   break;
        // case 'ready':
        //   console.log('App dataStoreListener');
        //   try {
        //     break;
        //   } catch (error) {
        //     console.log('error', error);
        //   }
      }
    };
    // dispatch(appActions.isSyncPending(true));
    Hub.listen('datastore', AppDataStoreListener);
    return () => {
      console.log('APP CLEAN UP!!!');
      Hub.remove('datastore', AppDataStoreListener);
    };
  }, []);

  // useEffect(() => {
  //   console.log(
  //     'currentEventState HAS CHANGED!!!',
  //     currentEventState?.eventCode
  //   );
  //   if (currentEventState?.eventCode?.length > 0) {
  //     subscriptionRS?.unsubscribe();
  //     subscriptionHistory?.unsubscribe();

  //     subscriptionRS = DataStore.observeQuery(Song, (song) =>
  //       song.eventSongId('eq', currentEventState.id)
  //     ).subscribe({
  //       next: ({ items, isSynced }) => {
  //         console.log('next subscriptionRS snapshot', items);
  //         console.log(
  //           `[Snapshot] item count: ${items.length}, isSynced: ${isSynced}`
  //         );
  //         // dispatch(songActions.setSongsForEvent());
  //         if (isSynced)
  //           dispatch(songActions.updateQueueAndSetUserFavSongs(items));

  //         // isSynced && setTodos(items);
  //       },
  //     });

  //     // .subscribe((snapshot) => {
  //     //   const { items, isSynced } = snapshot;
  //     //   console.log('snapshot', snapshot);
  //     //   console.log(
  //     //     `[Snapshot] item count: ${items.length}, isSynced: ${isSynced}`
  //     //   );
  //     // });

  //     subscriptionHistory = DataStore.observeQuery(
  //       History,
  //       (history) => history.eventHistoryId('eq', currentEventState.id),
  //       {
  //         // sort: (s) => s.updatedAt(SortDirection.DESCENDING),
  //         sort: (s) => s.postedAt(SortDirection.DESCENDING),
  //       }
  //     ).subscribe({
  //       next: ({ items, isSynced }) => {
  //         console.log('next subscriptionHistory snapshot', items, isSynced);
  //         console.log(
  //           `[Snapshot] item count: ${items.length}, isSynced: ${isSynced}`
  //         );

  //         if (isSynced && items.length > 0 && items[0]?.updatedAt)
  //           dispatch(appActions.setHistory(items));
  //       },
  //     });

  //     subsHistory = DataStore.observe(History).subscribe((msg) => {
  //       console.log('History subscription', msg);
  //       if (
  //         msg.opType === 'INSERT' &&
  //         msg.element?.type === 'upvote' &&
  //         msg.element?.songId
  //       )
  //         dispatch(songActions.addLastVotedSongId(msg.element?.songId));
  //     });

  //     return () => {
  //       subscriptionRS.unsubscribe();
  //       subscriptionHistory.unsubscribe();
  //       subsHistory.unsubscribe();
  //     };
  //   }
  // }, [currentEventState]);

  useEffect(() => {
    console.log('qrCodeDataState', isQrCodeVisibleState);

    setIsQRCodeVisible(isQrCodeVisibleState);
  }, [isQrCodeVisibleState]);

  const onSetDefaultParams = (defaultParams) => {
    console.log('onSetInitialScreen defaultParams', defaultParams);
    // setInitialScreen(defaultParams.initialScreen);
    setDefaultParams(defaultParams);
  };

  if (!defaultParams)
    return <SplashScreen onSetDefaultParams={onSetDefaultParams} />;

  return (
    <AnimatedAppLoader image={require('./assets/splash.png')}>
      {/* <View style={styles.container}>
        <Text>Open up App.js to start working on your app!</Text>
        <StatusBar style='auto' />
      </View> */}

      <NavigationContainer>
        <AppNavigator
          // initialScreen={initialScreen}
          defaultParams={defaultParams}
        />
        <QRCodeModal isVisible={isQRCodeVisible} />
      </NavigationContainer>
    </AnimatedAppLoader>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
