//@ts-check
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import {
  KeyboardAvoidingView,
  SafeAreaView,
  Text,
  Keyboard,
  View,
  Image,
  StyleSheet,
  InteractionManager,
  ActivityIndicator,
  FlatList,
  TouchableOpacity,
  TouchableHighlight,
  TouchableWithoutFeedback,
  Dimensions,
  Platform,
} from 'react-native';
import {
  Ionicons,
  Foundation,
  MaterialIcons,
  MaterialCommunityIcons,
} from '@expo/vector-icons';
import { useForm, Controller } from 'react-hook-form';

import Screen from '../components/Screen';
import AppInputBox from '../components/AppInputBox';
import AppText from '../components/AppText';
import AppButton from '../components/AppButton';

import Constants from 'expo-constants';
import * as myUtils from '../utils/myUtils';
import defaultStyles from '../config/styles';

// import * as Device from 'expo-device';
import AsyncStorage from '@react-native-async-storage/async-storage';

// import {
//   CodeField,
//   Cursor,
//   useBlurOnFulfill,
//   useClearByFocusCell,
// } from 'react-native-confirmation-code-field';
import { Asset as ExpoAsset } from 'expo-asset';
// import { readableRandomString } from '../components/utils/readableRandomString';
// import Swiper from '../components/UI/ViewSwiper';

// import styles from '../styles/inputCode';
import { globalStyles } from '../styles/global';
import colors from '../constants/colors';

import * as songActions from '../redux_store/actions/songActions';
import * as appActions from '../redux_store/actions/appActions';

// import MySnackbar from '../components/UI/Snackbar';
// import * as myModule from '../components/utils/myModule';

const EventCodeScreen = (props) => {
  console.log('EventCodeScreen props', props);

  const dispatch = useDispatch();

  const localAppSettingsState = useSelector((state) => {
    console.log('state', state);
    //@ts-ignore
    return state?.appReducer?.appSettings;
  });

  // const [eventCode, setEventCode] = useState('');
  const [isInProgress, setIsInProgress] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      eventCode: '',
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    control,
    formState,
    // formState: { isDirty, isValid, isSubmitting, errors, submitCount },
  } = form;
  const watchAllFields = watch();

  // useEffect(() => {
  //   console.log('watchAllFields watch', watchAllFields);
  //   // setNotFound(false);
  // }, [watchAllFields]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      console.log(value, name, type);
      console.log('useEffect watch', value, name, type);
      setNotFound(false);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = handleSubmit(async (data) => {
    Keyboard.dismiss();

    InteractionManager.runAfterInteractions(() => {
      setIsInProgress(true);
      //@ts-ignore
      dispatch(appActions.getEventInfo(data.eventCode.toUpperCase()))
        //@ts-ignore
        .then(async (eventInfo) => {
          console.log(
            'EventCodeScreen eventInfo',
            eventInfo,
            localAppSettingsState
          );
          setIsInProgress(false);
          if (eventInfo && eventInfo?.eventName?.length > 0) {
            setNotFound(false);

            await myUtils.configureDataStore(eventInfo);
            // console.log('setSongsForEvent+ 2');
            // dispatch(songActions.setSongsForEvent(true, eventInfo?.id));

            // dispatch(
            //   songActions.fetchRequestedSongs(
            //     eventInfoObj.companyCode,
            //     eventInfoObj.eventCode
            //   )
            // );
            // myModule.unsubscribeGraphQL();
            // myModule.setupSubscriptions(dispatch, eventInfoObj);

            if (
              !localAppSettingsState?.userName ||
              localAppSettingsState?.userName.length == 0
            ) {
              props.navigation.navigate({
                name: 'Username',
                params: {
                  eventCode: eventInfo.eventCode,
                  companyCode: eventInfo.companyCode,
                  eventName: eventInfo.eventName,
                  apiVersion: eventInfo.apiVersion,
                },
                merge: true,
              });
            } else {
              props.navigation.navigate({
                name: 'Main',
                params: {
                  eventCode: eventInfo.eventCode,
                  eventName: eventInfo.eventName,
                  apiVersion: eventInfo.apiVersion,
                },
                merge: true,
              });
            }
          } else {
            console.log('setNotFound TRUE');
            setNotFound(true);
          }
        })
        .catch(() => {
          setIsInProgress(false);
          setNotFound(false);
        });
    });
  });

  // console.log('formState.errors', formState.errors);
  return (
    <KeyboardAvoidingView
      keyboardVerticalOffset={120}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={styles.container}
    >
      <Screen>
        {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss}> */}
        <View style={styles.inner}>
          <View
            style={[
              defaultStyles.controlContainer,
              {
                flex: 1,
                justifyContent: 'flex-end',
                // backgroundColor: 'red',
              },
            ]}
          >
            <AppText controlStyle='title'>Event code:</AppText>

            <AppInputBox
              name='eventCode'
              errors={formState.errors}
              placeholder='Enter event code here.'
              form={form}
              controlType='inputText'
              required={true}
              errMessage='Required'
              editable={true}
              selectTextOnFocus={true}
              autoFocus={true}
              iconLeft={'balloon'}
              iconRight={
                formState.errors?.eventCode?.type === 'required'
                  ? null
                  : 'close'
              }
              onSubmitEditing={onSubmit}
              onRightIconClicked={() => {
                console.log('onRightIconClicked');
                reset();
              }}
              returnKeyType='done'
            />

            {/* <AppInputBox
              name='eventCode'
              errors={errors}
              placeholder='Enter event code here.'
              form={form}
              controlType='inputText'
              required={true}
              errMessage='Required'
              iconRight={'close-circle'}
              iconLeft={'balloon'}
              onSubmitEditing={() => onSubmit()}
              onRightIconClicked={() => {
                console.log('onRightIconClicked');
                reset();
              }}
            /> */}
            {/* <AppText>e.g. 'My Birthday', 'BBQ Party', 'Homecoming'</AppText> */}
            <View style={{ height: 60 }}>
              {notFound && (
                <Text selectable={false} style={globalStyles.errorText}>
                  Event code is incorrect or not found.
                </Text>
              )}
            </View>
          </View>

          <View
            style={[
              defaultStyles.controlContainer,
              { flex: 1, justifyContent: 'flex-end' },
            ]}
          >
            <AppButton
              disabled={isInProgress}
              title='Continue'
              onPress={onSubmit}
            />
          </View>
        </View>

        {/* </TouchableWithoutFeedback> */}
      </Screen>
    </KeyboardAvoidingView>
  );

  return (
    <SafeAreaView
      style={[
        {
          // height: Dimensions.get('window').height,
          // height: Platform.OS === 'web' ? '100vh' : '100%',
          // position: 'absolute',
          // height: '100%',
          // width: '100%',
          // height: Platform.OS === 'web' ? '100vh' : '100%',
          // minHeight: Platform.OS === 'web' ? '100vh' : '100%',
          position: 'absolute',
          height: '100%',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: colors.defBackgroundColor,
        },
      ]}
    >
      <View
        style={{
          // marginLeft: 40,
          flex: 1,
          width: '100%',
          padding: 15,
          justifyContent: 'center',
          // backgroundColor: 'red',
        }}
      >
        <View
          style={{
            // marginLeft: 40,
            width: '100%',
            // backgroundColor: 'red',
          }}
        >
          <Text selectable={false} style={globalStyles.titleText}>
            Event code:
          </Text>
        </View>
        <View
          style={{
            top: 0,
            width: '100%',
            paddingBottom: 10,
          }}
        >
          {/* <TextInput
            mode='outlined'
            // autoCapitalize={'characters'}
            // disabled={true}
            placeholder='Type event code here.'
            left={<TextInput.Icon name='balloon' size={30} />}
            // autoFocus={true}
            value={eventCode}
            onChangeText={(text) => {
              // setSearchBtnDisabled(false);
              setNotFound(false);
              setEventCode(text.toUpperCase());
            }}
            onSubmitEditing={onNextBtnClicked}
            right={
              <TextInput.Icon
                // forceTextInputFocus={false}
                onPress={() => {
                  setEventCode('');
                }}
                name='close-circle'
                size={30}
                color={'rgba(0, 0, 0, 0.6)'}
              />
            }
          /> */}
        </View>

        <View style={{ height: 60 }}>
          {notFound && (
            <Text selectable={false} style={globalStyles.text1}>
              Event code not found. Please check your code and try again.
            </Text>
          )}
        </View>
      </View>
      <View
        style={{
          width: '100%',
          justifyContent: 'center',
          // backgroundColor: 'red',
          backgroundColor: colors.accent,
        }}
      >
        {isInProgress && (
          <View
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <ActivityIndicator size={'large'} color={colors.primary} />
          </View>
        )}
      </View>
    </SafeAreaView>
  );
};

export const screenOptions = (navData) => {
  return {
    headerShown: false,
    headerTitle: 'Event Code',
  };
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    // backgroundColor: 'red',
    backgroundColor: colors.background,
  },
  inner: {
    // padding: 24,
    flex: 1,
    // justifyContent: 'space-around',
    justifyContent: 'flex-end',
  },
  header: {
    fontSize: 36,
    marginBottom: 48,
  },
  textInput: {
    height: 40,
    borderColor: '#000000',
    borderBottomWidth: 1,
    marginBottom: 36,
  },
  btnContainer: {
    backgroundColor: 'white',
    marginTop: 12,
  },
});

export default EventCodeScreen;
